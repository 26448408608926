import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Center, Group, Pagination, Table, Text, TextInput, Tooltip, createStyles } from '@mantine/core';
import axios from '../utils/axios';
import { useMedplum } from '@medplum/react';
import { useParams } from 'react-router-dom';
import { IconSearch, IconTrash, IconX } from '@tabler/icons-react';
import ConfirmBox from '../patients/ConfirmBox';
import { toast } from 'react-toastify';
import { deletePatientResponse } from '../utils/util';
import { useNavigate } from 'react-router-dom';
import { formatHumanName } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';

const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: '100%',
    overflow: 'auto',
    textAlign: 'left',
    marginBottom: '20px',
  },
  tr: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    },
  },
  th: {
    padding: '0 !important',
  },
  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },
  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

interface PatientResource {
  resource: {
    name?: { given?: string[] }[];
    birthDate?: string;
    sessionDescription?: string;
    meta?: { lastUpdated?: string };
  };
}

interface PatientTableProps {
  patientResponse: PatientResource[];
  classes: ReturnType<typeof useStyles>['classes'];
  handleDeleteIconClick: (resource: any) => void;
  handlePatientRowClick: (resource: any) => void;
}

const parseDate = (dateString: string) => {
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? new Date(dateString.replace(/-/g, '/')) : date;
};

const formatLastUpdated = (dateString: string) => {
  if (!dateString) return '';

  const date = parseDate(dateString);

  const dateOptions: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  const startTime = date.toLocaleTimeString('en-US', timeOptions);
  const endTime = new Date(date.getTime() + 60 * 1000).toLocaleTimeString('en-US', timeOptions);

  return (
    <>
      {formattedDate}
      <br />
      {`${startTime} - ${endTime}`}
    </>
  );
};

const PatientTable: React.FC<PatientTableProps> = ({ patientResponse, classes, handleDeleteIconClick, handlePatientRowClick }) => (
  <Table>
    <thead>
      <tr>
        <th className="table-heading">Name</th>
        <th className="table-heading">Date of Birth</th>
        <th className="table-heading">Session Description</th>
        <th className="table-heading">Last Session Date & Time</th>
        <th className="table-heading" style={{ textAlign: 'center' }}>Actions</th>
      </tr>
    </thead>
    <tbody>
      {patientResponse.length > 0 ? (
        patientResponse.map((data: PatientResource, index: number) => (
          <tr key={index} className={classes.tr} onClick={() => handlePatientRowClick(data.resource)}>
            <td>{formatHumanName(data?.resource?.name?.[0] as HumanName) || ''}</td>
            <td>{data?.resource?.birthDate || ''}</td>
            <td>{data?.resource?.sessionDescription || ''}</td>
            <td>{data?.resource?.meta?.lastUpdated ? formatLastUpdated(data.resource.meta.lastUpdated) : ''}</td>
            <td className="actions" style={{ textAlign: 'center' }}>
              <Tooltip label="Delete Session" withArrow position="top" sx={{ fontSize: '12px', marginTop: '1px' }}>
                <IconTrash
                  size={18}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDeleteIconClick(data.resource);
                  }}
                />
              </Tooltip>
            </td>
          </tr>
        ))
      ) : (
        <tr key={0} className={classes.tr}>
          <td colSpan={5} style={{ textAlign: 'center' }}>
            <Text>No Data</Text>
          </td>
        </tr>
      )}
    </tbody>
  </Table>
);

export function PatientList(): JSX.Element | null {
  const medplum = useMedplum();
  const { classes } = useStyles();
  const resourceId = useParams().id;
  const [patientResponse, setPatientResponse] = useState<PatientResource[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const perPage = 20;
  const resource = window.location.pathname.includes('Practitioner') ? 'Practitioner' : 'Patient';
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [isDeletingLoader, setIsDeletingLoader] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const patientTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();

  const getPatientsList = useCallback(async (patientName?: string, page = 1) => {
    const token = await medplum.getAccessToken();
    const offset = (page - 1) * perPage;
    const resourceType = resource === 'Practitioner' ? `general-practitioner=Practitioner/${resourceId}` : `patient=${resourceId}`;

    try {
      const response = await axios.get(
        `intg/get-patient-list?${resourceType}&_offset=${offset}&_count=${perPage}&_sort=-_lastUpdated&_total=accurate&active=true${patientName ? `&name:contains=${patientName}` : ''}`,
        {
          headers: {
            Accept: 'application/fhir+json',
            Authorization: `Bearer ${token}`,
            'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }
      );
      setPatientResponse(response.data.entry || []);
      setTotalRecords(response.data.total || 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [medplum, resourceId, resource]);

  useEffect(() => {
    getPatientsList();
  }, [getPatientsList]);

  const handleDeleteIconClick = useCallback((resource: any) => {
    setDeletingUserId(resource.id);
    setShowConfirmBox(true);
  }, []);

  const clearPatientSearch = () => {
    setSearchTerm('');
    getPatientsList(); 
  };

  const handleConfirmDelete = async () => {
    setIsDeletingLoader(true);
    try {
      const response = await deletePatientResponse(medplum, deletingUserId);
      if (response) {
        toast.success("Patient deleted successfully!", {
          position: toast.POSITION.TOP_RIGHT
        });
        setIsDeletingLoader(false);
        setShowConfirmBox(false);
        // Call getPatientsList to refresh the list
        getPatientsList(undefined, currentPage);
      }
    } catch (error) {
      console.error('Error deleting patient:', error);
      toast.error('Failed to delete session', {
        position: toast.POSITION.TOP_RIGHT
      });
    } finally {
      setIsDeletingLoader(false);
      setShowConfirmBox(false);
    }
  };

  //patient search
  const patientSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (patientTimeoutRef.current) {
      clearTimeout(patientTimeoutRef.current);
    }

    patientTimeoutRef.current = setTimeout(() => {
      if (value.length >= 3 || value.length === 0) {
        getPatientsList(value, 1);
        setCurrentPage(1);
      }
    }, 1000);
  };

  //handle patient row click
  const handlePatientRowClick = (row: any) => {
    if (row.id) {
      navigate(`/Patient/${row.id}/details`);
    }
  };

  return (
    <div className="resource-panel">
      <div className="resource-list">
        <Group spacing={2}>
          <TextInput
            placeholder="Search Patient"
            value={searchTerm}
            onChange={patientSearch}
            icon={<IconSearch size="1rem" />}
            mb={20}
            rightSection={
              searchTerm && (
                <IconX
                  stroke={2}
                  size={16}
                  onClick={clearPatientSearch}
                />
              )
            }
            styles={() => ({
              input: {
                borderColor: '#ced4da',
                '&:focus': {
                  borderColor: '#228be6',
                },
              },
            })}
          />
        </Group>

        {resource === 'Practitioner' && (
          <PatientTable
            patientResponse={patientResponse}
            classes={classes}
            handleDeleteIconClick={handleDeleteIconClick}
            handlePatientRowClick={handlePatientRowClick}
          />
        )}
        <Center m="md" p="md" style={{ justifyContent: 'flex-end' }}>
          <Pagination
            className="pagination"
            value={currentPage}
            total={Math.ceil(totalRecords / perPage)}
            onChange={(page) => {
              setCurrentPage(page);
              getPatientsList(searchTerm, page);
            }}
          />
        </Center>
      </div>
      <ConfirmBox
        isOpen={showConfirmBox}
        onClose={() => setShowConfirmBox(false)}
        onConfirm={() => {
          handleConfirmDelete();
        }}
        confirm="Confirm"
        cancel="Cancel"
        message="Are you sure you want to delete this patient?"
        isLoader={isDeletingLoader}
        additionalText=""
        patient={undefined}
      />
    </div>
  );
}