import React from 'react'
import tncStyles from './TermsAndConditions.module.css'
import TopHeader from '../header/TopHeader'
import Footer from './Footer'
import { Anchor, Box, Grid, Text} from '@mantine/core'


function TermsAndConditions() {
    return (
        <Box>
            <TopHeader></TopHeader>

            <Grid className={tncStyles.gridBox}>
                <Grid.Col span={1} md={2}> </Grid.Col>
                <Grid.Col span={10} md={8} lg={8}>
                    <h1 className={tncStyles.tncHeading} color='black'>TERMS & CONDITIONS</h1>
                    <Text className={tncStyles.tncPara}>PMHScribe Terms of Service</Text>
                    <Text className={tncStyles.tncPara}>Last revised on: March 3, 2024</Text>
                    <Text className={tncStyles.tncPara}>1.<span className={tncStyles.tncSubpara}> As used in these Terms, "Company" means PMHScribe by eRx.ai, with offices at: </span></Text>
                    <Text className={tncStyles.tncPara}>8The Green Ste. 300, Dover, DE 19901 and 412 S Second Street, St. Charles, IL 60174</Text>

                    <Text className={tncStyles.tncPara}><Anchor td="underline" href="http://www.PMHScribe.com">www.PMHScribe.com</Anchor></Text>
                    <Text className={tncStyles.tncPara}><Anchor td="underline" href="http://www.eRx.ai">www.eRx.ai</Anchor></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>2.<span className={tncStyles.tncSubpara}> These Terms of Service (“Terms”) are a legal agreement between You (or “you”) and PMHScribe by eRx.ai. (“PMHScribe”, "Company", " us", " our", and " we") and govern your use of all Services that we may make available to you, including our Services and any services, plug-ins, software or anything else that we may provide through any of our users are the people who have created PMHScribe accounts (collectively the “Services”). By visiting, accessing, using, and/or joining (collectively “Using” or “using”) the Services, you acknowledge and accept these Terms. Certain features of the Services may be subject to additional guidelines, terms, or rules, which will be posted on the Services in connection with such features. All such additional terms, guidelines, and rules are incorporated by reference into these Terms.</span></Text>
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>3.<span className={tncStyles.tncSubpara}> Description of Services:</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}> The Services provide AI-based transcription of audio recordings into text format. Users may submit recordings to the Services for transcription or utilize the Services' recording add-on to create recordings directly within the platform.</span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}> We may also collect data that is used to pre-populate the headers of the reports generated by the Services. Providing this data is optional; however please note that such data is typically publicly available (healthcare provider’s name, credentials, National Provider Identifier (NPI), group practice NPI, practice address, email, phone, fax, EIN, logo, and provider’s photo.</span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>4.<span className={tncStyles.tncSubpara}> Eligibility.</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}> You must be at least eighteen (18) years of age to use the Services unless the age of majority in your jurisdiction is greater than eighteen (18) years of age, in which case you must be at least the age of majority in your jurisdiction (e.g., twenty-one (21)). If you are under the applicable age of majority, you shall not use and are not permitted to use the Services and you must immediately leave the Services. By using the Services, you represent and warrant that you have reached at least the age of eighteen (18) and the age of majority in your jurisdiction. We maintain a zero-tolerance policy for anyone under the age of majority on our Services.</span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}> Use of the Services is not permitted where prohibited by law. If you are unsure of your eligibility or other legal requirements under these Terms, please contact your attorney prior to using the Services.</span></Text>
                    <Text className={tncStyles.tncPara}>(iii)<span className={tncStyles.tncSubpara}>  In order to use the Services, You must create an account with us (an “Account”) to use the Services to their fullest extent. You represent and warrant that all information that you provide to us in creating your Account is complete and accurate. You shall also update such information when it changes. You shall not use another person’s Account and shall not allow another person to use your Account. You are required to keep your passwords and access credentials confidential and not share them with anyone.</span></Text>
                    <Text className={tncStyles.tncPara}>(iv)<span className={tncStyles.tncSubpara}>  You are solely responsible for maintaining the confidentiality of and restricted access to your Account and are solely responsible for all acts and omissions under your Account. You shall notify us immediately of any breach of security or unauthorized use of your Account.</span></Text>
                    <Text className={tncStyles.tncPara}>(v)<span className={tncStyles.tncSubpara}> Pursuant to the Terms herein, we shall not be liable for any losses resulting from any unauthorized use of your Account.</span></Text>
                    <Text className={tncStyles.tncPara}>(vi)<span className={tncStyles.tncSubpara}> You are entirely responsible for any and all materials that you submit, upload, or otherwise make available via the Services, including any communications or information (collectively, “Submissions”). You agree that any disclosure of information in Submissions may make you personally identifiable and there is no guarantee such Submissions can be withdrawn.</span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>5.<span className={tncStyles.tncSubpara}> Intellectual Property.</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}>  “IP” means but is not limited to trademarks, service marks, patents, copyrights, privacy and publicity rights, words, graphics, logos, and any and all intellectual property Our services may contain our IP as well as IP of our affiliates or other companies, provided to You or anyone in connection with the Services. Your use of our Services does not constitute any right or license for you to use such IP. Our Services are also protected under applicable IP laws, including but not limited to those of the United States. The copying, redistribution, use, or publication by you of any portion of our Services is strictly prohibited. Your use of our Services does not grant you ownership rights of any kind in our Services. We reserve all rights not expressly granted to You in these Terms.</span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}> Except for the limited rights and licenses expressly granted under these Terms, nothing in these Terms grants, by implication, waiver, estoppel, or otherwise, to You or any third party any IP rights or other interest in or to the IP.</span></Text>
                    <Text className={tncStyles.tncPara}>(iii)<span className={tncStyles.tncSubpara}> You shall not copy, modify, publish, transmit, distribute, participate in the transfer or sale of, create derivative works of, or in any other way exploit, in whole or in part, any Content.</span></Text>
                    <Text className={tncStyles.tncPara}>(iv)<span className={tncStyles.tncSubpara}>  Copyright Claims.</span></Text>
                    <Text className={`${tncStyles.tncPara} ${tncStyles.leftSpace}`}>i.<span className={tncStyles.tncSubpara}>  You may not infringe the IP rights of any party. We may in our sole discretion remove any Content or Submissions we have reason to believe violates any of the IP rights of others and may terminate your use of the Services if you submit any such Content or Submissions.</span></Text>
                    <Text className={`${tncStyles.tncPara} ${tncStyles.leftSpace}`}>ii.<span className={tncStyles.tncSubpara}>  We comply with the Digital Millennium Copyright Act. Pursuant to Title 17, Section 512 of the US Code, if you believe your copyrighted material is being infringed by the Services, you should notify us at <Anchor td="underline" href="mailto:allison@erx.ai">allison@erx.ai. </Anchor></span></Text>
                    <Text className={`${tncStyles.tncPara} ${tncStyles.leftSpace}`}>iii.<span className={tncStyles.tncSubpara}>  Notification of claimed infringement must be a written communication that includes substantially the following:</span>
                        <p className={tncStyles.leftSpace}>i.<span className={tncStyles.tncSubpara}> Identification of the material that is believed to be infringing. Please describe the material and provide a URL or any other relevant information that will allow us to locate the material on the Services; Information that will allow us to contact you, including your email address, address, and telephone number; and</span></p>
                        <p className={tncStyles.leftSpace}>ii.<span className={tncStyles.tncSubpara}>A statement that you have a good faith belief that the use of the material complained of is not authorized by you, your agent or the law; A statement that the information in the notification is accurate and that under penalty of perjury that you are the owner or are authorized to act on behalf of the owner.</span></p>
                    </Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>6.<span className={tncStyles.tncSubpara}> License.</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}> We grant you a revocable, non-exclusive, non-transferable, and limited license to use the Services (“License”), subject to the restrictions of the Services and these Terms, on your computer, smartphone, tablet, or other device (“Device”) consistent with these Terms. You may only access and use the Services for your personal use and shall not use them for any commercial use. This License is terminable by us at will for any reason, within our sole discretion, with or without prior notice to you.</span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}>   Upon termination, we may, but shall not be obligated to:</span>
                        <p className={tncStyles.leftSpace}>i.<span className={tncStyles.tncSubpara}> delete or deactivate your Account;</span></p>
                        <p className={tncStyles.leftSpace}>ii.<span className={tncStyles.tncSubpara}>  block your Account or otherwise terminate your use or ability to use the Services, and/or</span></p>
                        <p className={tncStyles.leftSpace}>iii.<span className={tncStyles.tncSubpara}>   remove and/or delete any of your Submissions.</span></p>
                    </Text>
                    <Text className={tncStyles.tncPara}>(iii)<span className={tncStyles.tncSubpara}> Upon termination, your License to use the Services shall terminate and You agree not to use or attempt to use the Services after said termination.</span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>7.<span className={tncStyles.tncSubpara}>  Submissions.</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}> You shall be solely responsible for any and all of your own Submissions and any and all consequences resulting from them. For any of your Submissions, you represent and warrant that You:</span>
                        <p className={tncStyles.leftSpace}>i.<span className={tncStyles.tncSubpara}> have the necessary rights and permissions to use and authorize us to use all IP in and to Submissions for any and all uses contemplated by these Terms;</span></p>
                        <p className={tncStyles.leftSpace}>ii.<span className={tncStyles.tncSubpara}> have inspected to confirm that all individuals in your Submission are, in fact, over the age of eighteen (18) years other applicable age of majority; and You have written consent, release, and/or permission from each and every identifiable individual in the Submission to use the name and/or likeness and to enable use of the Submission for any and all uses contemplated by these Terms.</span></p>
                    </Text>
                    <Text className={`${tncStyles.tncPara} ${tncStyles.leftSpace}`}>1.<span className={tncStyles.tncSubpara}>  In the event your Submission contains individuals who are under eighteen (18) or the applicable age of majority, you confirm you have all necessary rights, permissions, and legal bases such as verifiable parental or guardian consent to have such individuals in your Submissions.</span></Text>
                    <Text className={tncStyles.tncPara}><p className={tncStyles.leftSpace}>iii.<span className={tncStyles.tncSubpara}> You are solely responsible for the accuracy and legality of the recordings submitted to the Services. You understand that the quality of transcription output may vary depending on factors such as audio quality, accents, background noise, and language complexity.</span></p></Text>
                    <Text className={tncStyles.tncPara}>
                        <p className={tncStyles.leftSpace}>iv.<span className={tncStyles.tncSubpara}>shall not submit material that:</span></p>
                        <p className={tncStyles.leftSpace}>1.<span className={tncStyles.tncSubpara}> Is subject to third-party IP rights, unless you have explicit permission to submit the material and to grant us all of the license rights granted herein;</span></p>
                        <p className={tncStyles.leftSpace}>2.<span className={tncStyles.tncSubpara}> Is illegal, unlawful, defamatory, fraudulent, libelous, harmful, harassing, abusive, threatening, hateful, racially or ethnically offensive, inflammatory, or otherwise inappropriate as decided by us in our sole discretion;</span></p>
                        <p className={tncStyles.leftSpace}>3.<span className={tncStyles.tncSubpara}> Depicts illegal activities, promotes or depicts physical harm or injury against any group or individual, or promotes or depicts any act of cruelty to animals;</span></p>
                        <p className={tncStyles.leftSpace}>4.<span className={tncStyles.tncSubpara}>Impersonates another person or entity or otherwise misrepresents you, including creating a false identity; </span></p>
                        <p className={tncStyles.leftSpace}>5.<span className={tncStyles.tncSubpara}> Would constitute, encourage or provide instructions for a criminal offense, a violation of the rights of any party, or that would otherwise create liability or violate any local, state, national or international law; or is unsolicited or unauthorized advertising, promotion, “spam” or any other form of solicitation.</span></p>
                    </Text>

                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}> We claim no ownership or control over Third-Party Content. You retain all IP rights to Submissions and you are responsible for protecting those rights. You irrevocably grant us a worldwide, non-exclusive, royalty-free, perpetual, non-cancelable, sub-licensable license to reproduce, publicly display, distribute, modify, publish, translate, create derivative works of, and otherwise leverage Submissions for any purpose, including without limitation any purpose contemplated by these Terms. The foregoing includes a right to grant other users of the Services a right and license to display, stream, and download Submissions in connection with their use of the Services and for other personal use. You also irrevocably waive and cause to be waived against us and any of our users any claims and assertions of moral rights or attribution with respect to Submissions.</span></Text>
                    <Text className={tncStyles.tncPara}>(iii)<span className={tncStyles.tncSubpara}> You represent and warrant that you have all the rights, power, and authority necessary to grant the rights granted herein to Submissions. Specifically, you represent and warrant that you own the title to the Submissions, that you have the right to submit the Submissions to the Services, and that submitting the Submissions will not infringe upon any other party’s rights or your contractual obligations to other parties.</span></Text>
                    <Text className={tncStyles.tncPara}>(iv)<span className={tncStyles.tncSubpara}> You acknowledge that we may at our sole discretion refuse to publish, remove, or block access to any Submission for any reason, solely within our discretion, with or without notice.</span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>8. <span className={tncStyles.tncSubpara}>  Content. </span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}>  You understand and acknowledge that, when using the Services, you may be exposed to content from a variety of sources outside of our control (collectively, “Third-Party Content”) and that we do not control and are not responsible for any Third-Party Content. You understand and acknowledge that you may be exposed to content that is adult-oriented, inaccurate, offensive, indecent, or otherwise objectionable or may cause harm to your Devices.</span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}> We claim no ownership or control over Third-Party Content. Third parties retain all rights to Third-Party Content, and they are responsible for protecting their rights. </span></Text>
                    <Text className={tncStyles.tncPara}>(iii)<span className={tncStyles.tncSubpara}> You understand and acknowledge that we may but are not obligated to monitor the Services for inappropriate content or conduct. If at any time we choose, in our sole discretion, to monitor such content, we assume no responsibility for such content, have no obligation to modify or remove any such content (including Submissions and Third-Party Content), and assume no responsibility for the conduct of others submitting any such content (including Submissions and Third-Party Content). </span></Text>
                    <Text className={tncStyles.tncPara}>(iv)<span className={tncStyles.tncSubpara}> Without limiting the provisions below on limitations of liability and disclaimers of warranties, all Content (including Third-Party Content) on the Services is provided to you “AS-IS” for your information and personal use only and you shall not use, copy, reproduce, distribute, sell, license or otherwise exploit for any other purpose whatsoever the Content without the prior written consent of the respective owners/licensors of the Content. </span></Text>
                    <Text className={tncStyles.tncPara}>(v)<span className={tncStyles.tncSubpara}>  No Support or Maintenance. You acknowledge and agree that the Company will have no obligation to provide you with any support or maintenance in connection with the Services.</span></Text>
                    <Text className={tncStyles.tncPara}>(vi)<span className={tncStyles.tncSubpara}>  You acknowledge that we may at our sole discretion refuse to publish, remove, or block access to any Content for any reason, solely within our discretion, with or without notice.</span></Text>
                    <Text className={tncStyles.tncPara}>(vii)<span className={tncStyles.tncSubpara}> What you enter into the Services may result in an output generated by the Services based on your inputs (“Content”).</span></Text>
                    <Text className={tncStyles.tncPara}>(viii)<span className={tncStyles.tncSubpara}> Use of Content to Improve Services. To help Us provide and maintain the Services, you agree that we may use Content to develop and improve the Services. </span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>9.<span className={tncStyles.tncSubpara}> Prohibited Uses. </span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}>  We authorize your use of these Services only for permitted purposes and solely for your own benefit and in accordance with the terms and conditions of these Terms. Any other use is prohibited and, therefore, violates of the Terms and may lead to the deletion of your Account.</span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}>  You will not (and will not permit any third party to):</span>
                        <p className={tncStyles.leftSpace}>i.<span className={tncStyles.tncSubpara}> rent, lease, provide access to or sublicense the Services to a third party;</span></p>
                        <p className={tncStyles.leftSpace}>ii.<span className={tncStyles.tncSubpara}>  use the Services to provide, or incorporate the Services into, any product or service provided to a third party;</span></p>
                        <p className={tncStyles.leftSpace}>iii.<span className={tncStyles.tncSubpara}> reverse engineer, decompile, disassemble, or otherwise seek to obtain the source code or non-public APIs to the Services, except to the extent expressly permitted by applicable law;</span></p>
                        <p className={tncStyles.leftSpace}>iv.<span className={tncStyles.tncSubpara}> copy or modify the Services, or create any derivative work from any of the foregoing;</span></p>
                        <p className={tncStyles.leftSpace}>v.<span className={tncStyles.tncSubpara}> remove or obscure any proprietary or other notices contained in the Services; or</span></p>
                        <p className={tncStyles.leftSpace}>vi.<span className={tncStyles.tncSubpara}> publicly disseminate information regarding the performance of the Services.</span></p>
                    </Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>10.<span className={tncStyles.tncSubpara}> Confidentiality. You (as "Receiving Party") agree that all code, inventions, know-how, business, technical and financial information it obtains from PMHScribe ("Disclosing Party") constitute the confidential property of the Disclosing Party ("Confidential Information"), provided that it is identified as confidential at the time of disclosure or should be reasonably known by the Receiving Party to be confidential or proprietary due to the nature of the information disclosed and the circumstances surrounding the disclosure. Any PMHScribe technology, performance information relating to any Service, and the terms and conditions of these Terms will be deemed Confidential Information of PMHScribe without any marking or further designation. Except as expressly authorized herein, the Receiving Party will (1) hold in confidence and not disclose any Confidential Information to third parties and (2) not use Confidential Information for any purpose other than fulfilling its obligations and exercising its rights under these Terms. The Receiving Party may disclose Confidential Information to its employees, agents, contractors, and other representatives having a legitimate need to know, provided that such representatives are bound to confidentiality obligations no less protective of the Disclosing Party than this Section 10 and that the Receiving Party remains responsible for compliance by any such representative with the terms of this Section 10. The Receiving Party's confidentiality obligations will not apply to information that the Receiving Party can document: </span>
                        <p className={tncStyles.leftSpace}>i.<span className={tncStyles.tncSubpara}> was rightfully in its possession or known to it prior to receipt of the Confidential Information;</span></p>
                        <p className={tncStyles.leftSpace}>ii.<span className={tncStyles.tncSubpara}> is or has become public knowledge through no fault of the Receiving Party; </span></p>
                        <p className={tncStyles.leftSpace}>iii.<span className={tncStyles.tncSubpara}> is rightfully obtained by the Receiving Party from a third party without breach of any confidentiality obligation; or</span></p>
                        <p className={tncStyles.leftSpace}>iv.<span className={tncStyles.tncSubpara}> is independently developed by employees of the Receiving Party who had no access to such information. The Receiving Party may make disclosures to the extent required by law or court order, provided the Receiving Party notifies the Disclosing Party in advance and cooperates in any effort to obtain confidential treatment. The Receiving Party acknowledges that disclosure of Confidential Information would cause substantial harm for which damages alone would not be a sufficient remedy, and therefore that upon any such disclosure by the Receiving Party, the Disclosing Party will be entitled to seek appropriate equitable relief in addition to whatever other remedies it might have at law. Further, You shall not use any means, automated or otherwise, to monitor, "scrape", download, use, or otherwise collect data and/or content from the Services, including but not limited to bots or data mining tools.</span></p>
                    </Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>11.<span className={tncStyles.tncSubpara}> Your obligations. </span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}> You are solely responsible for the accuracy, content, and legality of all data you provide. You represent and warrant to PMHScribe that you have all necessary rights, consents, and permissions to collect, share, and use all data as contemplated in these Terms and that no data will violate or infringe any PMHScribe or third-party IP rights or other laws. </span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}> Except as otherwise expressly agreed between the PMHScribe and You in writing, You specifically agree not to use the Services to collect, store, process, or transmit any sensitive data including but not limited to: payment card data, protected health data as defined under HIPAA, or any special category data as defined under GDPR Article 9. Further, you acknowledge that PMHScribe is not a payment card processor and that the Services are not PCI DSS compliant. You shall be solely responsible for any sensitive personal data you submit to the Service, and You acknowledge that PMHScribe is not subject to any additional obligations that may apply to any sensitive personal data submitted to the Services. </span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>12.<span className={tncStyles.tncSubpara}> Subscriptions, Fees, and Termination. </span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}> This is a subscription agreement and you acknowledge that you are obtaining only a limited right to the Services and that irrespective of any use of the words "purchase", "sale" or like terms in these Terms no ownership rights are being conveyed to You and Company retains all right, title and interest in and to the Services, integrations with the Services, and any and all related and underlying technology and documentation and any derivative works, modifications or improvements.</span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}>  “Free Tier”: a free-of-charge option and is available only for limited times, solely within PMHScribe’s sole discretion, and subject to change at any time. In the future, this may change to an option that is aimed at users who do not opt for fee-based tiers but may also give limited access to the Services. [RD7] [AA8] [RD9] </span></Text>
                    <Text className={tncStyles.tncPara}>(iii)<span className={tncStyles.tncSubpara}>  “Paid Tier”: We may offer such a subscription to the Services in the future.</span></Text>
                    <Text className={tncStyles.tncPara}>(iv)<span className={tncStyles.tncSubpara}> You acknowledge that we reserve the right to charge for any or all of our services and to change our fees from time to time at our sole discretion. If at any time we terminate your rights to use the Services because of a breach of these Terms, you shall not be entitled to a refund of any portion of your fees. In all other respects, such fees shall be governed by additional rules, terms, conditions, or agreements posted on the Services and/or imposed by any sales agent or payment processing company, as may be amended from time to time.</span></Text>
                    <Text className={tncStyles.tncPara}>(v)<span className={tncStyles.tncSubpara}> Disputes and Late Payments. If you want to dispute any Fees or Taxes, please contact allison@erx.ai within thirty (30) days of the date of the disputed invoice. Undisputed amounts past due may be subject to a service charge equal to 1.5% per month of the amount due or the maximum amount allowed by law, whichever is less. If any amount of your Fees are past due, we may suspend your access to the Services after we provide you written notice of late payment.</span></Text>
                    <Text className={tncStyles.tncPara}>(vi)<span className={tncStyles.tncSubpara}>  Price Change. We may change our prices by posting the notice to your Account and/or to our Services. Price increases will be effective 14 days after they are posted.</span></Text>
                    <Text className={tncStyles.tncPara}>(vii)<span className={tncStyles.tncSubpara}> Taxes. You are responsible for paying all taxes. If you are required by law to withhold any taxes, the fees payable by You will be increased as necessary so that after making any required withholdings</span></Text>
                    <Text className={tncStyles.tncPara}>(viii)<span className={tncStyles.tncSubpara}> Termination for cause. Either party may terminate these Terms if the other party (a) fails to cure any material breach of these Terms (including a failure to pay fees) within thirty (30) days after written notice; (b) ceases operation without a successor; or (c) seeks protection under any bankruptcy, receivership, trust deed, creditors' arrangement, composition, or comparable proceeding, or if any such proceeding is instituted against that party (and not dismissed within sixty (60) days thereafter).</span></Text>
                    <Text className={tncStyles.tncPara}>(ix)<span className={tncStyles.tncSubpara}> If Your Account is thirty (30) days or more overdue, in addition to any of its other rights or remedies, PMHScribe reserves the right to suspend Your access to the applicable Service without liability to You until such amounts are paid in full.</span></Text>
                    <Text className={tncStyles.tncPara}>(x)<span className={tncStyles.tncSubpara}>  Effect of termination. Upon any expiration or termination of these Terms, You will immediately cease any and all use of and access to all Services and delete any Confidential Information in your possession. Following termination, You will have no further access to any data input into the Service, and PMHScribe may delete any such data as may have been stored by PMHScribe.</span></Text>
                    <Text className={tncStyles.tncPara}>(xi)<span className={tncStyles.tncSubpara}>  You may terminate your subscription and close your Account by going to your account settings. You may cancel your membership and your Account at any time by emailing us at <Anchor href="mailto:allison@erx.ai" td="underline">allison@erx.ai.</Anchor> You agree to be personally liable for any and all charges incurred by your Account, username, and password until you terminate your membership as provided herein. Upon our processing of your request to cancel your membership, you will no longer have access to the non-public areas of the Service.</span></Text>
                    <Text className={tncStyles.tncPara}>(xii)<span className={tncStyles.tncSubpara}>  Payment. The Services are paid with a credit card, debit card or other payment card ("Credit Card") and/or Stripe Payment Gateway. If paying by Credit Card, the following terms apply:</span>
                        <p className={tncStyles.leftSpace}>i.<span className={tncStyles.tncSubpara}> Recurring Billing Authorization. By providing Credit Card information and agreeing to purchase any Services, You hereby authorize PMHScribe (or its designee) to automatically charge Your Credit Card in order to provide the Services. You acknowledge and agree that the amount billed and charged may vary depending on Your use of the Services.</span></p>
                        <p className={tncStyles.leftSpace}>ii.<span className={tncStyles.tncSubpara}>  Foreign Transaction Fees. You acknowledge that for certain Credit Cards, the issuer of Your Credit Card may charge a foreign transaction fee or other charges.</span></p>
                        <p className={tncStyles.leftSpace}>iii.<span className={tncStyles.tncSubpara}> Invalid Payment. If a payment is not successfully settled due to the expiration of a Credit Card, insufficient funds, or otherwise, You remain responsible for any amounts not remitted to PMHScribe and PMHScribe may, in its sole discretion, either (i) invoice You directly for the deficient amount, (ii) continue billing the Credit Card once it has been updated by You (if applicable) or (iii) terminate these Terms.</span></p>
                        <p className={tncStyles.leftSpace}>iv.<span className={tncStyles.tncSubpara}> Changing Credit Card Information. At any time, You may change its Credit Card information by entering updated Credit Card information.</span></p>
                        <p className={tncStyles.leftSpace}>v.<span className={tncStyles.tncSubpara}> Please note that additional terms may apply if you use Stripe payment, Apple Pay, Google Pay, or other similar services.</span></p>
                    </Text>
                    <Text className={tncStyles.tncPara}>(xiii)<span className={tncStyles.tncSubpara}>  Billing Errors. If you believe that you have been erroneously billed, please notify us immediately of such error. If we do not hear from you within 30 days after such a billing error first appears on any account statement, such fee will be deemed acceptable by you for all purposes, including resolution of inquiries made by your credit card issuer. You release us from all liabilities and claims of loss resulting from any error or discrepancy that is not reported to us within 30 days of its publication.</span></Text>
                    <Text className={tncStyles.tncPara}>(xiv)<span className={tncStyles.tncSubpara}>  REFUND POLICY. Currently, we don’t offer standard refunds. If there’s something wrong with your order, please let us know by contacting us at <Anchor href="mailto:allison@erx.ai" td="underline">allison@erx.ai.</Anchor> In limited situations, solely within our discretion, we may offer refunds for certain losses or disputes, otherwise, you assume there are no refunds, and all sales are final. Further, depending on third parties through whom you acquire the Services (e.g., Google Play Store), please note, that those third parties may also have restrictions on attaining refunds.</span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>13.<span className={tncStyles.tncSubpara}> User Conduct. </span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}>  You hereby expressly authorize us to monitor, record, and log your use of the Services.</span>
                        <p className={tncStyles.leftSpace}>i.<span className={tncStyles.tncSubpara}>  As a condition of your use of the Services, You agree: to abide by all applicable local, state, national and international laws and regulations and the laws and regulations of the United States and the state of Illinois; not to use the Services in any way that exposes Company to criminal or civil liability; that you are solely responsible for all acts and omissions that occur as a result of your use of the Services; not to use or attempt to use any other party’s Account on the Services without authorization; not to use any automated means, including robots, crawlers or data mining tools, to download, monitor or use data or Content from the Services; not to use the Services to collect usernames and/or e-mail addresses for sending unsolicited messages of any kind; not to take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately large load on our technology infrastructure; not to forge headers or otherwise manipulate identifiers in order to disguise the origin of any information you transmit; not to disable, circumvent, or otherwise interfere with security features of the Services; not to “frame” or “mirror” the Services; and not to reverse engineer any portion of the Services. You agree not to remove any proprietary notices from the Services or any of the Content.</span></p>
                    </Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}>  You agree not to use the Services to promote, facilitate, or engage in any form of human trafficking or prostitution.</span></Text>
                    <Text className={tncStyles.tncPara}>(iii)<span className={tncStyles.tncSubpara}>  We reserve the right to take appropriate action against any user for any unauthorized use of the Services, including civil, criminal, and injunctive redress and the termination of any user’s use of the Services.</span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>14.<span className={tncStyles.tncSubpara}>  Privacy.</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}>  PMHScribe maintains a separate Privacy Statement and your assent to these Terms also signifies your assent to the Privacy Statement. We reserve the right to amend the Privacy Statement at any time by posting such amendments to the Services. No other notification may be made to you about any amendments. Your continued use of the Services following such amendments will constitute your acceptance of such amendments, regardless of whether you have actually read them. </span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}> Our Services are directed to serve adults only. You must be at least eighteen (18) years of age to use the Services unless the age of majority in your jurisdiction is greater than eighteen (18) years of age, in which case you must be at least the age of majority in your jurisdiction (e.g., twenty-one (21)). If you are under the applicable age of majority, you shall not use and are not permitted to use the Services and you must immediately leave the Services. By using the Services, you represent and warrant that you have reached at least the age of eighteen (18) and the age of majority in your jurisdiction. We do not knowingly collect Personal Information from anyone under the age of 18. If we discover that a person below 18 years of age or younger has provided Personal Information to us, we automatically delete such information. If you are a parent or guardian of a minor under the age of eighteen (18) and believe he or she has disclosed Personal Information to us, please contact us at <Anchor href="mailto:allison@erx.ai">allison@erx.ai.</Anchor></span></Text>
                    <Text className={tncStyles.tncPara}>(iii)<span className={tncStyles.tncSubpara}>  If you are an individual located in another jurisdiction outside of the US with laws governing data processing that differ from US laws, please be aware that the data we collect will be processed and stored in the United States or in other countries where we or our service providers have operations. By submitting your data to the Company, you understand and acknowledge to have your data transferred to, the US or another jurisdiction which may not offer the same level of privacy protection as those in the country where you are located.</span></Text>
                    <Text className={tncStyles.tncPara}>(iv)<span className={tncStyles.tncSubpara}> Please note: the Services are intended for use by United States residents only. Countries outside the United States may have regulatory requirements that differ from the United States and, therefore, this information may be inappropriate for use outside the United States. If you are located outside the United States, you are advised to consult with your attorney prior to the use of the Services.</span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>15.<span className={tncStyles.tncSubpara}> Indemnification and Release.</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}> You alone are responsible for any violation of these Terms by you or under your Account. You agree to indemnify and hold the Company, its parents, subsidiaries, affiliates, officers, agents, employees, resellers or other partners, and licensors harmless from any claim, demand, loss, or damages, including reasonable attorneys’ fees (“Losses”), resulting from any third-party claim, suit, action, or proceeding (“Third-Party Claim”) arising from your use of the Services or from your breach of these Terms. This includes but is not limited to any damages and third-party claims and expenses, including attorney’s fees and costs, arising from unauthorized use or harm resulting from actions from your Account.  </span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}>  You agree to defend us against any claim, demand, suit, or proceeding made or brought against us by a third party arising from your Submissions, including, without limitation, allegations that your Submissions or that your use of the Services in violation of these Terms infringes or misappropriates the IP rights of any third party or violates applicable law and you shall indemnify us for any and all damages against us and for reasonable attorney’s fees and other costs incurred by us in connection with any such claim, demand, suit or proceeding.  </span></Text>
                    <Text className={tncStyles.tncPara}>(iii)<span className={tncStyles.tncSubpara}>  Further, You hereby release us, our officers, employees, agents, and successors-in-right from claims, demands, and damages (actual and consequential) of every kind or nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way related to such disputes and/or the Services. </span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>16.<span className={tncStyles.tncSubpara}> Disclaimer of Warranties and Limitations of Liabilities.</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}> READ THIS SECTION CAREFULLY AS IT LIMITS OUR LIABILITY TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.  </span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}> The Services may contain links to third-party websites which are independent of us. We assume no responsibility for the content or practices of and make no representation or warranty as to the accuracy, completeness, or authenticity of information contained in any third-party websites. We have no right or ability to edit the content of any third-party websites. You acknowledge that we shall not be liable for any liability arising from your use of any third-party websites.  </span></Text>
                    <Text className={tncStyles.tncPara}>(iii)<span className={tncStyles.tncSubpara}>  THE SERVICES ARE PROVIDED “AS-IS” AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED, OR STATUTORY. WE SPECIFICALLY DISCLAIM TO THE FULLEST EXTENT ANY IMPLIED WARRANTIES OF INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WE DO NOT WARRANT THAT YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. WE DISCLAIM ANY WARRANTIES FOR VIRUSES OR OTHER HARMFUL COMPONENTS IN CONNECTION WITH THE SERVICES.  </span></Text>
                    <Text className={tncStyles.tncPara}>(iv)<span className={tncStyles.tncSubpara}>   YOU ARE AWARE THAT YOU MAY SUBMIT CONTENT TO THE SERVICES PROTECTED HEALTH INFORMATION UNDER THE HEALTH INSURANCE PORTABILITY AND ACCOUNTABILITY ACT, 1996 OF THE USA (HIPAA), INCLUDING CONTENT RELATED TO CONDITIONS, TREATMENTS, AND MEDICATIONS. THIS IS ENTIRELY WITHIN YOUR CONTROL. BY USING THE SERVICES, YOU AGREE THAT DESPITE THESE RISKS, AND YOU RELEASE THE PMHSCRIBE FROM ANY LIABILITY ARISING FROM SUCH RISKS. </span></Text>
                    <Text className={tncStyles.tncPara}>(v)<span className={tncStyles.tncSubpara}> YOU WILL USE THE SERVICES ONLY AS A REFERENCE AID, AND THAT SUCH INFORMATION IS NOT INTENDED TO BE (NOR SHOULD IT BE USED AS) A SUBSTITUTE FOR THE EXERCISE OF PROFESSIONAL JUDGMENT. YOU AGREE AND ACKNOWLEDGE THAT YOU WILL, AT ALL TIMES, SEEK PROFESSIONAL DIAGNOSIS AND TREATMENT FOR ANY MEDICAL CONDITION. PMHSCRIBE GENERATED RECOMMENDATIONS ARE EDUCATIONAL PURPOSES ONLY, AND NOT TO BE USED FOR PATIENT CARE. INDIVIDUAL PHYSICIANS AND CLINICIANS MUST PERFORM THROUGH EXAMINATION OF THE PATIENT AND DELIVER APPROPRIATE TREATMENTS WITHOUT DEPENDING PMHSCRIBE. THIS SERVICE IS DESIGNED TO OFFER YOU GENERAL INFORMATION FOR EDUCATIONAL PURPOSES ONLY. YOU MUST ALWAYS SEEK THE ADVICE OF A PROFESSIONAL FOR QUESTIONS RELATED TO YOUR DISEASE, DISEASE SYMPTOMS, AND APPROPRIATE THERAPEUTIC TREATMENTS. IF YOU HAVE OR SUSPECT THAT YOU HAVE A MEDICAL PROBLEM OR CONDITION, PLEASE CONTACT A QUALIFIED HEALTHCARE PROVIDER IMMEDIATELY.  </span></Text>
                    <Text className={tncStyles.tncPara}>(vi)<span className={tncStyles.tncSubpara}>  PLEASE BE ADVISED THAT PMHSCRIBE IS NOT A LICENSED PROFESSIONAL, INCLUDING BUT NOT LIMITED TO, A DOCTOR, LAWYER, OR ANY OTHER CERTIFIED EXPERT. THE INFORMATION PROVIDED BY PMHSCRIBE IS FOR INFORMATIONAL PURPOSES ONLY AND SHOULD NOT BE CONSIDERED A SUBSTITUTE FOR PROFESSIONAL ADVICE, DIAGNOSIS, OR TREATMENT. PMHSCRIBE DOES NOT PROVIDE MEDICAL, LEGAL, OR PROFESSIONAL SERVICES. ANY OUTPUTS GENERATED BY PMHSCRIBE ARE BASED ON PUBLICLY AVAILABLE INFORMATION AND PATTERNS WITHIN THE DATA IT HAS BEEN TRAINED ON. IT IS IMPORTANT TO CONSULT WITH QUALIFIED PROFESSIONALS FOR SPECIFIC GUIDANCE TAILORED TO YOUR INDIVIDUAL CIRCUMSTANCES. WHILE PMHSCRIBE STRIVES TO PROVIDE ACCURATE AND UP-TO-DATE INFORMATION, IT DOES NOT GUARANTEE THE COMPLETENESS, ACCURACY, RELIABILITY, SUITABILITY, OR AVAILABILITY OF THE CONTENT. RELIANCE ON ANY INFORMATION PROVIDED BY PMHSCRIBE IS SOLELY AT YOUR OWN RISK. IF YOU HAVE SPECIFIC CONCERNS REGARDING YOUR HEALTH, LEGAL MATTERS, OR ANY OTHER PROFESSIONAL ADVICE, IT IS STRONGLY RECOMMENDED TO SEEK GUIDANCE FROM QUALIFIED PROFESSIONALS WHO CAN ASSESS YOUR UNIQUE SITUATION. </span></Text>
                    <Text className={tncStyles.tncPara}>(vii)<span className={tncStyles.tncSubpara}>   UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES RESULTING FROM ANY ASPECT OF YOUR USE OF THE SERVICES, WHETHER, WITHOUT LIMITATION, SUCH DAMAGES ARISE FROM (i) YOUR USE, MISUSE OR INABILITY TO USE THE SERVICES, (ii) YOUR RELIANCE ON ANY CONTENT ON THE SERVICES, (iii) THE INTERRUPTION, SUSPENSION, MODIFICATION, ALTERATION OR COMPLETE DISCONTINUANCE OF THE SERVICES OR (iv) THE TERMINATION OF SERVICE BY US. THESE LIMITATIONS ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF OTHER SERVICES OR PRODUCTS RECEIVED OR ADVERTISED IN CONNECTION WITH THE SERVICES. </span></Text>
                    <Text className={tncStyles.tncPara}>(viii)<span className={tncStyles.tncSubpara}>  Except as otherwise expressly permitted in writing by PMHScribe, You specifically agree not to use the Services as a medical record system. You acknowledge that, unless specifically represented to you otherwise in writing by PMHScribe, PMHScribe is not a medical record system and is not subject to any additional obligations such as applicable medical retention rules that may apply, and You shall be solely responsible for extracting and maintaining such records separately and outside of PMHScribe’s system. </span></Text>
                    <Text className={tncStyles.tncPara}>(ix)<span className={tncStyles.tncSubpara}>  WE DO NOT WARRANT THAT: </span>
                        <p className={tncStyles.leftSpace}>i.<span className={tncStyles.tncSubpara}>  THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS;</span></p>
                        <p className={tncStyles.leftSpace}>ii.<span className={tncStyles.tncSubpara}>  THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE;</span></p>
                        <p className={tncStyles.leftSpace}>iii.<span className={tncStyles.tncSubpara}> YOUR DEVICE MEETS THE REQUIREMENTS TO USE OUR SERVICES. YOU ARE RESPONSIBLE FOR ENSURING YOUR DEVICE SATISFIES CERTAIN SYSTEM REQUIREMENTS;</span></p>
                        <p className={tncStyles.leftSpace}>iv.<span className={tncStyles.tncSubpara}> THE RESULTS THAT MAY BE OBTAINED FROM YOUR USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE;</span></p>
                        <p className={tncStyles.leftSpace}>v.<span className={tncStyles.tncSubpara}> THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, CONTENT, OR OTHER MATERIAL OBTAINED THROUGH THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR;</span></p>
                        <p className={tncStyles.leftSpace}>vi.<span className={tncStyles.tncSubpara}>  ANY ERRORS IN CONTENT WILL BE CORRECTED.</span></p>
                    </Text>
                    <Text className={tncStyles.tncPara}>(x)<span className={tncStyles.tncSubpara}> ANY CONTENT OBTAINED THROUGH THE USE OF THE SERVICES IS OBTAINED AT YOUR OWN DISCRETION AND RISK. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH CONTENT.  </span></Text>
                    <Text className={tncStyles.tncPara}>(xi)<span className={tncStyles.tncSubpara}>  YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SERVICES OR ANY OTHER GRIEVANCE SHALL BE THE TERMINATION OF YOUR USE OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, IN NO CASE SHALL THE MAXIMUM LIABILITY OF US ARISING FROM OR RELATING TO YOUR USE OF THE SERVICES OR RELATING TO THESE TERMS EXCEED $100.  </span></Text>
                    <Text className={tncStyles.tncPara}>(xii)<span className={tncStyles.tncSubpara}>  You understand that due to the inherent complexities of language and speech recognition, errors and inaccuracies may occur in the transcription process. It is your responsibility to review, verify, and validate the accuracy of the output generated by our Services. We recommend cross-referencing the transcribed text with the original audio or seeking professional assistance for critical applications where accuracy is paramount. By using our Services, You accept and understand that the transcription output is provided "as is," and we disclaim any liability for errors, omissions, or discrepancies in the transcribed content.  </span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>17.<span className={tncStyles.tncSubpara}>  Legal Disputes.</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}> NO CLASS ACTIONS. Disputes must be brought on an individual basis only, and may not be brought as a plaintiff or class member in any purported class, consolidated, or representative proceeding. Class arbitrations, class actions, private attorney general actions, and consolidation with other arbitrations are not allowed. If for any reason a dispute proceeds in court rather than through arbitration, each party knowingly and irrevocably waives any right to trial by jury in any action, proceeding, or counterclaim. This does not prevent either party from participating in a class-wide settlement of claims.</span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}>  YOU AGREE TO THE FOLLOWING MANDATORY ARBITRATION AND CLASS ACTION WAIVER PROVISIONS:</span>
                        <p className={tncStyles.leftSpace}>i.<span className={tncStyles.tncSubpara}>  MANDATORY ARBITRATION. You and Company agree to resolve any claims relating to these Terms or our Services through final and binding arbitration.</span></p>
                        <p className={tncStyles.leftSpace}>ii.<span className={tncStyles.tncSubpara}>   Arbitration Forum. Either party may commence binding arbitration through ADR Services, an alternative dispute resolution provider. The parties will pay equal shares of the arbitration fees.</span></p>
                        <p className={tncStyles.leftSpace}>iii.<span className={tncStyles.tncSubpara}>  Arbitration Procedures. The arbitration will be conducted in Kane County, Illinois or at another mutually agreed location. All issues are for the arbitrator to decide, including but not limited to issues relating to the scope, enforceability, and arbitrability of this Section. The amount of any settlement offer will not be disclosed to the arbitrator by either party until after the arbitrator determines the final award, if any.</span></p>
                    </Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}> The obligation to arbitrate is not binding upon the Services with respect to claims relating to its trademarks, service marks, patents, copyrights, or other intellectual property rights, or requests for temporary restraining orders, preliminary injunctions or other procedures in a court of competent jurisdiction to obtain interim relief when deemed necessary by such court to preserve the status quo or prevent irreparable injury pending resolution by arbitration of the actual dispute between the parties. </span></Text>
                   
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>18.<span className={tncStyles.tncSubpara}> Injunctive Relief. Notwithstanding anything in these Terms, Company may apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction. </span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}> You acknowledge that if you violate or breach these Terms, it may cause irreparable harm to Us and our affiliates, and We shall have the right to seek injunctive relief against you in addition to any other legal remedies. </span></Text>
                    
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>19.<span className={tncStyles.tncSubpara}>  Feedback. We appreciate feedback, comments, ideas, proposals, and suggestions for improvements. If you provide us feedback, you hereby grant provide to us a perpetual, non-revocable, royalty-free worldwide license to use and/or incorporate such feedback into any product or service at any time at our sole discretion.</span></Text>
                    
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>20.<span className={tncStyles.tncSubpara}> General Terms.</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}> No Waiver. Failure by either party to enforce any right under these Terms will not waive that right. </span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}>   Severability. If any portion of these Terms is not enforceable, it will not affect any other terms.</span></Text>
                    <Text className={tncStyles.tncPara}>(iii)<span className={tncStyles.tncSubpara}>  These Terms are not assignable, transferable, or sub-licensable by you, but may be assigned or transferred by us without restriction. </span></Text>
                    <Text className={tncStyles.tncPara}>(iv)<span className={tncStyles.tncSubpara}>  Nothing herein is intended, nor will be deemed, to confer rights or remedies upon any third party. </span></Text>
                    <Text className={tncStyles.tncPara}>(v)<span className={tncStyles.tncSubpara}>   You acknowledge and understand that we are a provider of an interactive computer service. We hereby notify you that parental control protections (such as computer hardware, software, or filtering services) are commercially available that may assist you in limiting access to material that is harmful to minors. However, note that we do not take responsibility for any parental control protection you may find or use and that you are solely responsible for such use. </span></Text>
                    <Text className={tncStyles.tncPara}>(vi)<span className={tncStyles.tncSubpara}>   Assignment and Subcontracting. Except as set out below, You may not assign or novate these Terms in whole or in part without the Company’s express written consent. PMHScribe may: (1) by written notice to You, assign or novate these Terms in whole or in part to an affiliate of PMHScribe, or otherwise as part of a sale or transfer of any part of its business; or (2) subcontract any performance associated with the PMHScribe to third parties, provided that such subcontract does not relieve PMHScribe of any of its obligations under these Terms. </span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>21.<span className={tncStyles.tncSubpara}> Modification.</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}> The Company reserves the right, at any time, to modify, suspend, or discontinue the Services (in whole or in part) with or without notice to you. You agree that the Company will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Services or any part thereof.</span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}>  We reserve the right to amend these Terms at any time by posting such amended Terms to the Services with a new effective date. </span></Text>
                    <Text className={tncStyles.tncPara}>(iii)<span className={tncStyles.tncSubpara}>   YOUR CONTINUED USE OF THE SERVICES FOLLOWING SUCH AMENDMENTS WILL CONSTITUTE YOUR ACCEPTANCE OF SUCH AMENDMENTS, REGARDLESS OF WHETHER YOU HAVE ACTUALLY READ THEM.</span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>22.<span className={tncStyles.tncSubpara}> License. You hereby grant (and you represent and warrant that you have the right to grant) to Company an irrevocable, nonexclusive, royalty-free and fully-paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use and exploit your User Content, and to grant sublicenses of the foregoing rights, solely for the purposes of including your User Content in the Services. You hereby irrevocably waive (and agree to cause to be waived) any claims and assertions of moral rights or attribution with respect to your User Content.</span></Text>
               
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>23.<span className={tncStyles.tncSubpara}> Acceptable Use Policy. The following terms constitute our "Acceptable Use Policy":</span></Text>
                    <Text className={tncStyles.tncPara}>  (b) In addition, you agree not to: (i) upload, transmit, or distribute to or through the Service any computer viruses, worms, or any software intended to damage or alter a computer system or data; (ii) send through the Services unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (iii) use the Services to harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent; (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the Services, or violate the regulations, policies or procedures of such networks; (v) attempt to gain unauthorized access to the Services (or to other computer systems or networks connected to or used together with the Services), whether through password mining or any other means; (vi) harass or interfere with any other user's use and enjoyment of the Services; or (vi) use software or automated agents or scripts to produce multiple accounts on the Services, or to generate automated searches, requests, or queries to (or to strip, scrape, or mine data from) the Services (provided, however, that we conditionally grant to the operators of public search engines revocable permission to use spiders to copy materials from the Services for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials, subject to the parameters set forth in our robots.txt file)</Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}>  Enforcement. We reserve the right (but have no obligation) to review, refuse and/or remove any User Content in our sole discretion, and to investigate and/or take appropriate action against you in our sole discretion if you violate the Acceptable Use Policy or any other provision of these Terms or otherwise create liability for us or any other person. Such action may include removing or modifying your User Content, terminating your Account in accordance, and/or reporting you to law enforcement authorities. </span></Text>
                
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>24.<span className={tncStyles.tncSubpara}> Other Users. Each Services user is solely responsible for any and all of its own User Content. Since we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others. We make no guarantees regarding the accuracy, currency, suitability, appropriateness, or quality of any User Content. Your interactions with other Services users are solely between you and such users. You agree that the Company will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any Services user, we are under no obligation to become involved.</span></Text>

                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>25.<span className={tncStyles.tncSubpara}>  Disclaimers</span></Text>
                    <Text className={tncStyles.tncPara}>26.<span className={tncStyles.tncSubpara}> Disclosure and Other Communication.</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}>  We reserve the right to send you emailS for the purpose of informing you of changes or additions to the Services, or of any related products and services offered by the Services or its affiliated entities. We reserve the right to disclose information about you and your usage of the Service that does not reveal your personal identity. </span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}> Further, by agreeing to become a member you opt-in to receive occasional special offers, marketing, survey, and Services-based communication emails or messages. You can easily unsubscribe from our marketing emails by following the opt-out instructions in these emails. </span></Text>
                
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>27.<span className={tncStyles.tncSubpara}> Notice. Any notice delivered by the Company to You under these Terms will be delivered via email, regular mail or postings on our websites. Notices to the Company shall be sent to 412 S. Second Street, St. Charles, IL 60174 unless the Company specifically allows other means of notice. b. To resolve or report a complaint regarding the Services, users will use the methods stated in this Section. The party initiating a Dispute must give notice to the other party in writing of its intent to initiate an Informal Dispute Resolution Conference ("Notice"), which shall occur within 45 days after the other party receives such Notice unless an extension is mutually agreed upon by the parties. Notice to the Company that you intend to initiate an Informal Dispute Resolution Conference should be sent by email to: <Anchor href="mailto:allison@erx.ai" td="underline">allison@erx.ai</Anchor>, or by regular mail to 412 S. Second Street, St. Charles, IL 60174. The Notice must include: (1) your name, telephone number, mailing address, e‐mail address associated with your account (if you have one); (2) the name, telephone number, mailing address and e‐mail address of your counsel, if any; and (3) a description of your Dispute.</span></Text>
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>28.<span className={tncStyles.tncSubpara}> Export. The website may be subject to U.S. export control laws and may be subject to export or import regulations in other countries. You agree not to export, reexport, or transfer, directly or indirectly, any U.S. technical data acquired from the Company, or any products utilizing such data, in violation of the United States export laws or regulations.</span></Text>
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>29.<span className={tncStyles.tncSubpara}> Force Majeure. Neither party will be liable to the other for any delay or failure to perform any obligation under these Terms (except for a failure to pay ) if the delay or failure is due to unforeseen events that occur after the signing of these Terms and that are beyond the reasonable control of such party, including but not limited to a strike, war, act of terrorism, riot, natural disaster, or failure or diminishment of power or telecommunications or data networks or services.</span></Text>
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>30.<span className={tncStyles.tncSubpara}> Relationship of the parties as independent contractors. Company and You are independent contractors. There is no relationship of partnership, joint venture, employment, franchise or agency created hereby between the Company and You. Neither party will have the power to bind the other or incur obligations on the other party's behalf without the other party's prior written consent.</span></Text>
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>31.<span className={tncStyles.tncSubpara}> Governing Law and Venue. These Terms, and any disputes arising from it, will be governed exclusively by Illinois law, The courts and venues within Kane County, Illinois will have exclusive jurisdiction to adjudicate any dispute arising out of or relating to these Terms or its formation, interpretation or enforcement. Each party consents and submits to the exclusive jurisdiction of such courts.</span></Text>
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>32.<span className={tncStyles.tncSubpara}> Entire Terms. These Terms are the complete agreement between the parties regarding the subject matter of these Terms and supersedes all prior or contemporaneous communications, understandings or agreements (whether written or oral).</span></Text>
                    <Text className={`${tncStyles.tncPara} ${tncStyles.topSpace}`}>33.<span className={tncStyles.tncSubpara}> Changes and contacting us.</span></Text>
                    <Text className={tncStyles.tncPara}>(i)<span className={tncStyles.tncSubpara}>   These Terms are current as of the Effective Date set forth above and if any changes are made, we’ll post them on this page. If you continue to use the Services after those changes are in effect, you agree to the changes. </span></Text>
                    <Text className={tncStyles.tncPara}>(ii)<span className={tncStyles.tncSubpara}>  If you have any questions, comments, or concerns about this Statement, please contact us at <Anchor td="underline" href="mailto:allison@erx.ai">allison@erx.ai </Anchor> or 412 S. Second Street, St. Charles, IL 60174. </span></Text>
                </Grid.Col>
                <Grid.Col span={1} md={2}> </Grid.Col>
            </Grid>

            <Footer></Footer>
        </Box>
    )
}

export default TermsAndConditions
