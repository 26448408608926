import { Box, Center, Grid, Title, createStyles } from '@mantine/core';
import { Logo, SignInForm } from '@medplum/react';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getConfig, isRegisterEnabled } from './config';

const useStyles = createStyles((theme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  paddingMobile: {
    [theme.fn.smallerThan('sm')]: {
      padding: '25px !important',
    },
  },
}));

export function SignInPage(): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const config = getConfig();
  const { classes } = useStyles();

  return (
    <Grid>
      <Grid.Col
        md={6}
        lg={6}
        style={{ alignContent: 'center', justifyContent: 'center', maxHeight: '100vh', padding: '30px' }}
        className={classes.hiddenMobile}
      >
        <Box className="desktop-only-logo">
          <Logo size={150} />
        </Box>
        <Box display="flex" m="lg" style={{ flexDirection: 'column' }}>
          <Center
            p={{ xs: 'lg', md: 'xs' }}
            style={{
              flexDirection: 'column',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
            }}
          >
            <img src="../img/login-banner-1.png" alt="PMHScribe Banner 1" width="90%" />
            <img src="../img/login-banner-2.png" alt="PMHScribe Banner 2" width="90%" />
          </Center>
        </Box>
      </Grid.Col>
      <Grid.Col md={6} lg={6} className={`bg-main-dark ${classes.paddingMobile}`}>
        <Center
          p={{ xs: 'lg', md: 'xs' }}
          style={{ alignContent: 'center', justifyContent: 'center', minHeight: '100vh', width: '100%' }}
        >
          <SignInForm
            onSuccess={() => {
              // added code to check if user clicked on any site link
              // so on login user redirect to that link
              const returnTo = sessionStorage.getItem('returnTo');
              if (returnTo) {
                window.location.href = returnTo;
                sessionStorage.removeItem('returnTo');
              } else {
                navigate(searchParams.get('next') || '/session')
              }
            }
            }
            onForgotPassword={() => navigate('/resetpassword')}
            onRegister={isRegisterEnabled() ? () => navigate('/register') : undefined}
            googleClientId={config.googleClientId}
            login={searchParams.get('login') || undefined}
            projectId={searchParams.get('project') || undefined}
          >
            <Center
              className="login-wrapper"
              display="flex"
              m={{ xs: 'lg', md: 'xs' }}
              style={{ flexDirection: 'column' }}
            >
              <Title mt="md" className="login-title">
                Welcome!
              </Title>
              {/* <Text size="lg" color="#fff" className="login-description" mb="40px">
                Don't have an account yet?{' '}
              </Text> */}
              {searchParams.get('project') === 'new' && <div>Sign in again to create a new project</div>}
            </Center>
          </SignInForm>
        </Center>
      </Grid.Col>
    </Grid>
  );
}
