import { Accordion, AccordionControlProps, Anchor, Box, Center, Divider, Grid, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { KnowledgeFaqaccordionItems } from '../constants';
import { checkIsAuthorizedRequest } from '../utils/util';
import { useMedplum } from '@medplum/react';

interface ButtonLinkProps {
    href: string;
    className: string;
    title: string;
    description: string;
    additionalText?: string;
    additionalLink?: {
        href?: string;
        text: string;
    };
    icon?: string;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
    href,
    className,
    title,
    description,
    additionalText,
    additionalLink,
    icon,
}) => (
    <Anchor href={href} target='_blank'>
        <button className={className}>
            <div className='flex-div'>
                <Text size={16} fw={500} color='#000' pb={3} pr={7}>{title}</Text>
                {icon && <img src={icon} alt="icon" />}
            </div>
            <Text size={14} color='#666666' mr={20}>{description}</Text>
            {additionalText && (
                <Text size={14} color='#666666'>
                    {additionalText}
                    {additionalLink && (
                        <Anchor size={14} href={additionalLink.href} target='_blank'> {additionalLink.text}</Anchor>
                    )}
                </Text>
            )}
        </button>
    </Anchor>
);

function Resources() {
    const [expanded, setExpanded] = useState<string | null>(KnowledgeFaqaccordionItems[0].value);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const medplum = useMedplum();

    //check if request is authorized in case of request from mobile app
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const accessToken = urlParams.get('accessToken');
        if (medplum.getActiveLogin()) {
            setIsAuthenticated(true);
        } else {
            const userId = urlParams.get('userId');
            const payload = {
                accessToken: accessToken,
                userId: userId
            }
            checkIsAuthorizedRequest(payload).then((response) => {
                debugger
                response?.isAuthorized ? setIsAuthenticated(response?.isAuthorized)
                    : window.location.href = '/signin';
            }).catch((error) => {
                setIsAuthenticated(false);
                window.location.href = '/signin';
            });
        }
    }, []);

    const handleAccordionChange = (value: string | null) => {
        setExpanded(value);
    };

    function AccordionControl(props: AccordionControlProps) {
        const isExpanded = expanded === props.value;
        const chevronIcon = isExpanded
            ? <img src="../../../../img/icons/minus-circle.svg" alt="Collapse Icon" />
            : <img src="../../../../img/icons/plus-circle.svg" alt="Expand Icon" />;

        return (
            <Center>
                <Accordion.Control {...props} chevron={chevronIcon} />
            </Center>
        );
    }

    if (!isAuthenticated) {
        return <div></div>;
    }

    return (
        <Box pl={20} pr={20} className='knowledge-page'>
            <Grid>
                <Grid.Col span={12}>
                    <h2>Knowledge Center</h2>
                    <Divider />
                </Grid.Col>
                <Grid.Col span={12} mt={10}>
                    <Text fw={600} size={18}>Getting Started</Text>
                    <Text pt={5} size={14} color='#666666'>Begin your journey with our services through this easy-to-follow guide.</Text>
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6}>
                    <ButtonLink
                        href='https://app.pmhscribe.com/signin'
                        className='sign-in-btn'
                        title='Sign In'
                        description='Access your account securely to manage your services.'
                        additionalText='Sign in:'
                        additionalLink={{ href: 'https://app.pmhscribe.com/signin', text: ' https://app.pmhscribe.com/signin' }}
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6}>
                    <ButtonLink
                        href='https://app.pmhscribe.com/resetpassword'
                        className='forgot-pass-btn'
                        title='Forgot Password'
                        description='Forgot Password - Enter email address used to start the account.'
                        additionalText='Check spam for your password reset coming from'
                        additionalLink={{ text: ' support@pmhscribe.com' }}
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col span={12} mt={15}>
                    <Text fw={600} size={18}>HIPAA Compliance</Text>
                    <Text pt={5} size={14} color='#666666'>BAA - To obtain your BAA click on PMHScribe BAA, follow the prompts, and you will receive a pdf version: </Text>
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={4}>
                    <ButtonLink
                        href='https://form.jotform.com/erxai/PMHScribeBAA'
                        className='hippa-btn'
                        title='PMHScribe BAA'
                        description='Please click to complete this form.'
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={4}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/BAA+Explanation.pdf'
                        className='hippa-btn'
                        title='BAA Explanation'
                        description='BAA Explanation Document.'
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col span={12} mt={15}>
                    <Text fw={600} size={18}>Using PMHScribe</Text>
                    <Text pt={5} size={14} color='#666666'>Learn how to effectively utilize PMHScribe for your needs.</Text>
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://www.loom.com/embed/cad8526107df4a95b63244c8542d407b?sid=05f410d2-ae4a-4a7a-b2ca-4c48f97f4755'
                        className='pmh-btn'
                        title='Overview of the Psychiatry Dashboard'
                        description='Get a comprehensive look at the Psychiatry Dashboard features.'
                        icon='../../../../img/icons/video-play.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/Understanding+the+PMHScribe+Dashboard+for+Psychiatry.pdf'
                        className='pmh-btn'
                        title='Understanding the PMHScribe Dashboard for Psychiatry'
                        description='PMHScribe dashboard for psychiatry document.'
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://www.loom.com/embed/cad8526107df4a95b63244c8542d407b?sid=8bb4a4bd-1eb4-4311-8f4e-bb9a868baf4c'
                        className='pmh-btn'
                        title='Overview of the Counseling Dashboard'
                        description='Explore the key functionalities of the Counseling Dashboard.'
                        icon='../../../../img/icons/video-play.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/Using+PMHScribe+for+Counselors.pdf'
                        className='pmh-btn'
                        title='Using PMHScribe for Counselors'
                        description='PMHScribe dashboard for counselors document.'
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/Using+PMHScribe+with+your+Electronic+Health+Records.pdf'
                        className='pmh-btn'
                        title='Using PMHScribe with your Electronic Health Record'
                        description='Integrate PMHScribe with your EHR for streamlined documentation.'
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://www.loom.com/embed/f123183dffd2430881e5020ed44bd1de?sid=bea06cab-fa6b-442e-b257-fb8b8f2ba963'
                        className='pmh-btn'
                        title='PMHScribe Pause Feature'
                        description='Discover how to use the pause and record feature seamlessly.'
                        icon='../../../../img/icons/video-play.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/Pause+and+Resume+Recording+Features.pdf'
                        className='pmh-btn'
                        title='Pause and Resume Recording Features'
                        description='Pause and resume features document.'
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://www.loom.com/share/3c8a198348bf44b7b4928ab418864dcd'
                        className='pmh-btn'
                        title='Deleting Paused or Saved Sessions'
                        description='Demonstration of delete paused or completed sessions.'
                        icon='../../../../img/icons/video-play.svg'
                    />
                </Grid.Col>
                <Grid.Col span={12} mt={15}>
                    <Text fw={600} size={18}>Technical Settings: Browsers and microphones</Text>
                    <Text pt={5} size={14} color='#666666'>Adjust your technical settings for optimal performance.</Text>
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/Setting+the+Microphone+on+Your+Browser+if+Not+Automatic.pdf'
                        className='browser-btn'
                        title='Setting the Microphone on Your Browser'
                        description="Configure your browser's microphone settings for better audio quality."
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://www.loom.com/embed/a10ecbcccfda48829ddfef8b4a040a90?sid=f6d6f489-fcfb-40a5-88d6-124e037b8f0a'
                        className='browser-btn'
                        title='Choosing the Microphone in Chrome'
                        description="Optimize your experience with PMHScribe on Chrome."
                        icon='../../../../img/icons/video-play.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://www.loom.com/embed/73d3c0004769492d98fdd57c984b9c10?sid=b25427f8-44ec-48b8-9bd8-10632403cffe'
                        className='browser-btn'
                        title='Choosing the Microphone in Edge'
                        description="Enhance your PMHScribe usage with Microsoft Edge."
                        icon='../../../../img/icons/video-play.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/Choosing+your+microphone+on+Edge+if+it.pdf'
                        className='browser-btn'
                        title='Choosing the Microphone in Edge'
                        description="Choosing the microphone in edge document."
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://www.loom.com/share/f4900a49c28a454397d20683bf9bcf6b?sid=85761bd3-650b-4625-b3e5-fab5a46183b9'
                        className='browser-btn'
                        title='Troubleshooting Microphone Sharing in Telehealth Sessions'
                        description="Handle microphone issues in telehealth sessions."
                        icon='../../../../img/icons/video-play.svg'
                    />
                </Grid.Col>
                <Grid.Col span={12} mt={15}>
                    <Text fw={600} size={18}>About the Products</Text>
                    <Text pt={5} size={14} color='#666666'>Discover the features and benefits of our innovative products.</Text>
                </Grid.Col>
                <Grid.Col sm={12} md={6}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/What+is+Ai+Scribe.pdf'
                        className='hippa-btn'
                        title='What is AI Scribe?'
                        description="Learn how AI Scribe enhances your documentation process with AI technology."
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={6}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/How+to+tell+your+patients+about+PMHScribe.pdf'
                        className='hippa-btn'
                        title='How to tell your patients about PMHScribe'
                        description="Find effective ways to introduce PMHScribe to your patients."
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col span={12} mt={15}>
                    <Text fw={600} size={18}>Free Resources</Text>
                    <Text pt={5} size={14} color='#666666'>Free resources to support your psychiatric mental health practice.</Text>
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/2025+New+Outpatient+Evaluation+and+Management.pdf'
                        className='free-resources'
                        title='Psychiatric E/M CPT Codes Cheat Sheet (New)'
                        description="Outpatient office setting 2025 CPT Codes for Initial Psychiatric Evaluations – New Patients. Download this graph for a brief overview of choosing the Evaluation and Management CPT code based on medical decision making or time."
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/2025+Psychiatry+Evaluation+Management+Established+Outpatient+Office.pdf'
                        className='free-resources'
                        title='Psychiatric E/M CPT Codes Cheat Sheet (Established)'
                        description="Outpatient office setting 2025 CPT Codes for Medication Management Visits - Established Patients. Download this graph for a brief overview of choosing the Evaluation and Management CPT code based on medical decision making or time."
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/2025+New+TELEMEDICINE+Evaluation+and+Management.pdf'
                        className='free-resources'
                        title='Psychiatric E/M CPT 2025 TELEMEDICINE Cheat sheet (New)'
                        description="New Telemedicine CPT codes for 2025 for Initial Psychiatric Evaluations via live two-way videoconferencing. Download this graph for a brief overview of choosing the Evaluation and Management CPT code based on medical decision making or time. These codes by the AMA are set to go into effect January 2025."
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/2025+Psychiatry+TELEMEDICINE+Evaluation+Management+Establishedpdf.pdf'
                        className='free-resources'
                        title='Psychiatric E/M CPT 2025 TELEMEDICINE Cheat sheet (Established)'
                        description="New Telemedicine CPT codes for 2025 for Initial Psychiatric Evaluations via live two-way videoconferencing. Download this graph for a brief overview of choosing the Evaluation and Management CPT code based on medical decision making or time. These codes by the AMA are set to go into effect January 2025."
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>

                <Grid.Col sm={12} md={4}>
                    <ButtonLink
                        href='https://pmhscribe-public-links.s3.us-east-2.amazonaws.com/Initial+Psychiatric+Evaluation+Jottings+PMHScribe.pdf'
                        className='free-resources'
                        title='Initial Psychiatric Evaluation Example'
                        description="One-page sample initial psychiatric evaluation to structure an interview, take paper notes. Handy tool for initial evaluations as dictation."
                        icon='../../../../img/icons/share-03.svg'
                    />
                </Grid.Col>
            </Grid>
            <Grid>
                <Grid.Col span={12}>
                    <Divider mt={20} />
                    <Text fw={600} size={18} pt={20}>FAQ’s</Text>
                    <Text pt={5} size={14} color='#666666'>Everything you need to know about the product. Can’t find the answer you’re looking for? Please connect with our support team.</Text>
                </Grid.Col>
                <Grid.Col span={12} mb={70} className='knownledge-center-faqs'>
                    <Accordion chevronPosition="left" value={expanded} onChange={handleAccordionChange}>
                        {KnowledgeFaqaccordionItems.map((item) => (
                            <Accordion.Item key={item.value} value={item.value}>
                                <AccordionControl className='faq-title' value={item.value}>{item.title}</AccordionControl>
                                <Accordion.Panel className='faq-text'>
                                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                </Accordion.Panel>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Grid.Col>
            </Grid>
        </Box>
    );
}

export default Resources;