import { Grid, Paper } from '@mantine/core';
import React from 'react';
import ZoomVideo from '@zoom/videosdk';
import { Loading, useMedplum } from '@medplum/react';
import ZoomContext from './Zoom/context/zoom-context';
import ZoomMeeting from './Zoom/video/ZoomMeeting';


interface MeetingProps {
  sessionInfo: {
    accessToken?: string;
    sessionName?: string;
    patientSessionLink?: string;
  };
}



const Meeting = ({ sessionInfo }: MeetingProps): JSX.Element => {
  const zmClient = ZoomVideo.createClient();
  const medplum = useMedplum();
  const activeLoginName = medplum.getActiveLogin()?.profile?.display;

  console.log(sessionInfo)

  const renderZoomMeeting = () => {
    if (!sessionInfo || !zmClient) {return <Loading />;}
  
    return (
        <ZoomContext.Provider value={zmClient}>
            <ZoomMeeting
                meetingArgs={{
                    topic: sessionInfo?.sessionName || '',
                    signature: sessionInfo?.accessToken || '',
                    name: activeLoginName || '',
                    webEndpoint: 'zoom.us',
                }}
            />
        </ZoomContext.Provider>
    );
  };


  return (
    <div>
      <Paper className="resource-tab-panel patient-list">
        <Grid style={{justifyContent:'center'}} mt={20}>
          <Grid.Col xs={12} sm={7}>
            {renderZoomMeeting()}
          </Grid.Col>
        </Grid>
      </Paper>
    </div>
  );
};

export default Meeting;
