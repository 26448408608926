import React from 'react';

export interface LogoProps {
  size: number;
  fill?: string;
}

export function Logo(props: LogoProps): JSX.Element {
  return (
    <img src="../../img/logo.png" style={{width: props.size}} alt='PMHScribe Logo' />
  );
}
