/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { OperationOutcome, Reference, Resource } from '@medplum/fhirtypes';
import { CheckboxFormSection, ResourcePropertyInput, useMedplum, useResource } from '@medplum/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateResource } from '../resource/useCreateResource';
import { deepClone, getPropertyDisplayName, tryGetDataType } from '@medplum/core';
import { Button, Group, Modal, Stack } from '@mantine/core';
// import { BackboneElementInput } from '../../../react/src/BackboneElementInput/BackboneElementInput';
import { FormSection } from '../../../react/src/FormSection/FormSection';
import { DEFAULT_IGNORED_PROPERTIES } from '../../../react/src/constants';
import { getValueAndType } from '../../../react/src/ResourcePropertyDisplay/ResourcePropertyDisplay.utils';
import { setPropertyValue } from '../../../react/src/ResourceForm/ResourceForm.utils';

export function AddPatient(): JSX.Element {
  const { resourceType } = useParams();
  const [outcome, setOutcome] = useState<OperationOutcome | undefined>();
  const { defaultValue, handleSubmit } = useCreateResource(resourceType, setOutcome);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    setIsOpen(false);
    navigate('/Patient');
  };

  return (
    <Modal opened={isOpen} onClose={onClose} title="Add Patient" className="patient-form">
      <ResourceForm defaultValue={defaultValue} onSubmit={handleSubmit} outcome={outcome} />
    </Modal>
  );
}

export interface ResourceFormProps {
  defaultValue: Resource | Reference;
  outcome?: OperationOutcome;
  onSubmit: (resource: Resource) => void;
  onDelete?: (resource: Resource) => void;
}

export function ResourceForm(props: ResourceFormProps): JSX.Element {
  const medplum = useMedplum();
  const defaultValue = useResource(props.defaultValue);
  const [schemaLoaded, setSchemaLoaded] = useState(false);
  const [value, setValue] = useState<Resource | undefined>();

  useEffect(() => {
    if (defaultValue) {
      setValue(deepClone(defaultValue));
      medplum
        .requestSchema(defaultValue.resourceType)
        .then(() => setSchemaLoaded(true))
        .catch(console.log);
    }
  }, [medplum, defaultValue]);

  if (!schemaLoaded || !value) {
    return <div>Loading...</div>;
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        if (props.onSubmit) {
          props.onSubmit(value);
        }
      }}
    >
      <BackboneElementInput
        typeName={value.resourceType}
        defaultValue={value}
        outcome={props.outcome}
        onChange={setValue}
      />
      <Group position="right" mt="xl">
        <Button className="btn-success" type="submit">
          Create
        </Button>
        {props.onDelete && (
          <Button
            variant="outline"
            color="red"
            type="button"
            onClick={() => {
              (props.onDelete as (resource: Resource) => void)(value);
            }}
          >
            Delete
          </Button>
        )}
      </Group>
    </form>
  );
}

const DEFAULT_PROPERTIES = ['name', 'gender', 'birthDate'];

export interface BackboneElementInputProps {
  typeName: string;
  defaultValue?: any;
  outcome?: OperationOutcome;
  onChange?: (value: any) => void;
}

export function BackboneElementInput(props: BackboneElementInputProps): JSX.Element {
  const [value, setValue] = useState<any>(props.defaultValue ?? {});

  function setValueWrapper(newValue: any): void {
    setValue(newValue);
    if (props.onChange) {
      props.onChange(newValue);
    }
  }

  const typeName = props.typeName;
  const typeSchema = tryGetDataType(typeName);
  if (!typeSchema) {
    return <div>{typeName}&nbsp;not implemented</div>;
  }

  const typedValue = { type: typeName, value };

  return (
    <Stack>
      {Object.entries(typeSchema.elements).map(([key, property]) => {
        if (key === 'id' || DEFAULT_IGNORED_PROPERTIES.includes(key)) {
          return null;
        }

        if (!DEFAULT_PROPERTIES.includes(key)) {
          return null;
        }
        if (!property.type) {
          return null;
        }

        const [propertyValue, propertyType] = getValueAndType(typedValue, key);
        const required = property.min !== undefined && property.min > 0;

        let propValue = propertyValue
        if (key === 'name') {
          propValue = [
            {
              use: 'given',
              given: [''],
              family: '',
            },
          ];
        }

        if (property.type.length === 1 && property.type[0].code === 'boolean') {
          return (
            <CheckboxFormSection
              key={key}
              title={getPropertyDisplayName(key)}
              description={property.description}
              htmlFor={key}
            >
              <ResourcePropertyInput
                property={property}
                name={key}
                defaultValue={propValue}
                defaultPropertyType={propertyType}
                outcome={props.outcome}
                onChange={(newValue: any, propName?: string) => {
                  setValueWrapper(setPropertyValue(value, key, propName ?? key, property, newValue));
                }}
              />
            </CheckboxFormSection>
          );
        }

        return (
          <FormSection
            key={key}
            title={getPropertyDisplayName(key)}
            description={''}
            withAsterisk={required}
            htmlFor={key}
            outcome={props.outcome}
          >
            <ResourcePropertyInput
              property={property}
              name={key}
              defaultValue={propValue}
              defaultPropertyType={propertyType}
              onChange={(newValue: any, propName?: string) => {
                setValueWrapper(setPropertyValue(value, key, propName ?? key, property, newValue));
              }}
              simple={key === 'name'}
            />
          </FormSection>
        );
      })}
    </Stack>
  );
}
