import { Button } from '@mantine/core';
import { IconBadgeCc } from '@tabler/icons-react';
import classNames from 'classnames';
import React from 'react';

interface LiveTranscriptionButtonProps {
  isStartedLiveTranscription: boolean;
  isDisableCaptions: boolean;
  isHost: boolean;
  onLiveTranscriptionClick: () => void;
  onDisableCaptions: (disable: boolean) => void;
  className?: string;
}

const LiveTranscriptionButton = (props: LiveTranscriptionButtonProps) => {
  const {
    isStartedLiveTranscription,
    onLiveTranscriptionClick,
  } = props;

  return (
    <div>
      <Button
          className={classNames('vc-button', { active: isStartedLiveTranscription })}
          variant="filled"
          radius="xl"
          size="sm"
          onClick={onLiveTranscriptionClick}
        >
          <IconBadgeCc />
        </Button>
    </div>
  );
};

export { LiveTranscriptionButton };
