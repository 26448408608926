import { Box, Center, Pagination, Table, Text, createStyles } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import axios from '../utils/axios';
import { useMedplum } from '@medplum/react';
import { IconDownload } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { downloadSummaryDetails } from '../utils/util';

export const DocumentType = {
  ReferralLetter: 'Referral',
  ProgressReport: 'Progress Report',
};

const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: '100%',
    overflow: 'auto',
    textAlign: 'left',
    marginBottom: '20px',
  },

  tr: {
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    },
    cursor: 'pointer'
  },

  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

export function GeneratedDocuments(): JSX.Element | null {
  const medplum = useMedplum();
  const patientID = useParams().id;
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [dataList, setDataList] = useState<any>([]);
  const [loadData, setLoadData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;

  const getDocuments = async (): Promise<any> => {
    const token = await medplum.getAccessToken();
    const offset = (currentPage - 1) * perPage;
    const type = 'Outpatient Note';
    try {
      const response = await axios.get(
        `/intg/get-generated-notes?_count=20&_offset=${offset}&_sort=-_lastUpdated&_total=accurate&patient=${patientID}&type=${type}`,
        {
          headers: {
            Accept: 'application/fhir+json',
            Authorization: 'Bearer ' + token,
            'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }
      );
      return response.data.entry;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    getDocuments()
      .then((response: any) => {
        setDataList(response);
        setLoadData(false);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  }, [patientID, loadData, currentPage]);

  const paginate = (data: any): any => {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;

    return data.slice(startIndex, endIndex);
  };

  const downloadSummary = (documentReferenceId: string): any => {
    
    if (documentReferenceId) {
      downloadSummaryDetails(medplum, documentReferenceId)
        .then((response: any) => {
          if (response.data) {
            const filename = response?.headers['content-disposition']?.split('filename=')?.[1];
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${filename}`;
            a.click();
            window.URL.revokeObjectURL(url);
            toast.success('Notes Downloaded Successfully.');
          }
        })
        .catch((error: any) => {
          console.error('Error :', error);
          toast.error('Download failed.');
        });
    }
  };

  const getDocumentName = (data: any): any => {
    const compositionName = data.resource.content?.[0]?.attachment?.data?.compositionName ?? 'Clinical Notes';
    const patientName = data.resource?.subject?.display ?? '';
    const dateTime = data.resource.date ? data?.resource?.date : null;
    const documentName = patientName
      ? `${compositionName}-${patientName}-${dateTime}`
      : `${compositionName}-${dateTime}`;

    return documentName;
  };

  const getSessionName = (extension: any): any => {
    const sessionDetails: any = extension?.find(
      (ext: any) => ext.url === '/intg/structure/extensions/appointmentname'
    );
    return sessionDetails?.valueString || '-';
  };

  return (
    <Box className="resource-panel" mb="50px">
      <div className="resource-list">
        <div>
          <Table>
            <thead>
              <tr>
                <th className="table-heading font-dark">Name</th>
                <th className="table-heading font-dark">Session</th>
                <th className="table-heading font-dark">Date & Time</th>
                <th className="table-heading font-dark">Download</th>
              </tr>
            </thead>
            <tbody>
              {dataList.length > 0 ? (
                paginate(dataList)?.map((data: any, index: number) => (
                  <tr key={index} className={classes.tr} onClick={() => navigate(`/Appointment/${data?.resource?.appointmentId}/details`, { state: { compositionId: data.resource?.content?.[0].attachment.data?.compositionId, generatedNote:  data.resource?.content?.[0].attachment.data?.notes} })}>
                    <td>{getDocumentName(data)}</td>
                    <td>
                      {getSessionName(data.resource?.extension)}
                    </td>
                    <td>{data.resource.date ? data.resource.date : ''}</td>
                    <td>
                      <IconDownload
                        style={{ cursor: 'pointer' }}
                        onClick={(event) => {
                          event.stopPropagation();
                          downloadSummary(data.resource.id);
                        }}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr key={0} className={classes.tr}>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    <Text>No Data</Text>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <Center m="md" p="md" style={{ justifyContent: 'flex-end' }}>
            <Pagination
              className="pagination"
              value={currentPage}
              total={Math.ceil(dataList.length / perPage)}
              onChange={setCurrentPage}
            />
          </Center>
        </div>
      </div>
    </Box>
  );
}
