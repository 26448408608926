import React from 'react'
import TopHeader from '../header/TopHeader'
import Footer from './Footer'
import privacyStyle from './Privacy.module.css'
import { Anchor, Box, Grid, Text } from '@mantine/core'

export default function Privacy() {
    return (
        <Box>
            <TopHeader></TopHeader>

            <Grid className={privacyStyle.gridBox}>
                <Grid.Col span={1} md={2}>
                </Grid.Col>
                <Grid.Col span={10} md={8} lg={8}>
                    <h1 className={privacyStyle.topHeading} color='black'>PRIVACY POLICY</h1>
                    <em color='black'>Last updated: March 3, 2024</em>
                    <h5 className={privacyStyle.heading}>   1. &nbsp; &nbsp; Introduction. </h5>
                    <Text className={privacyStyle.paraText}>Your privacy is really important to us when using PMHScribe by eRx.ai (“PMHScribe”). You are trusting us with your data, and we want to make sure it’s safe, secure, and handled responsibly when visiting our websites or Services. Below is the Privacy Statement that PMHScribe (“PMHScribe”, "Company", " us", " our", and " we") commits to which outlines what data we collect and how it is used. We reserve the right to update this Statement and will post any changes here. If you have any questions, please reach out to us by emailing <Anchor td="underline" href="mailto:allison@erx.ai">allison@erx.ai.</Anchor> PMHScribe recognizes the importance of privacy, and this Privacy Statement (or “Statement”) is to help you understand and describe how we collect, use, and disclose your data:</Text>
                    <Text className={privacyStyle.paraText}>1.<span className={privacyStyle.spanPara}> Introduction </span></Text>
                    <Text className={privacyStyle.paraText}>2.<span className={privacyStyle.spanPara}> Collection and use of data </span></Text>
                    <Text className={privacyStyle.paraText}>3.<span className={privacyStyle.spanPara}> Sharing your data </span></Text>
                    <Text className={privacyStyle.paraText}>4.<span className={privacyStyle.spanPara}> Categories of data </span></Text>
                    <Text className={privacyStyle.paraText}>5.<span className={privacyStyle.spanPara}> How we use your data </span></Text>
                    <Text className={privacyStyle.paraText}>6.<span className={privacyStyle.spanPara}> Security of your data </span></Text>
                    <Text className={privacyStyle.paraText}>7.<span className={privacyStyle.spanPara}> Cookies </span></Text>
                    <Text className={privacyStyle.paraText}>8.<span className={privacyStyle.spanPara}> AI </span></Text>
                    <Text className={privacyStyle.paraText}>9.<span className={privacyStyle.spanPara}> Use of third-party services </span></Text>
                    <Text className={privacyStyle.paraText}>10.<span className={privacyStyle.spanPara}>Your Privacy Rights </span></Text>
                    <Text className={privacyStyle.paraText}>11.<span className={privacyStyle.spanPara}> Children’s data </span></Text>
                    <Text className={privacyStyle.paraText}>12.<span className={privacyStyle.spanPara}> Users outside the US </span></Text>
                    <Text className={privacyStyle.paraText}>13.<span className={privacyStyle.spanPara}> Changes and contacting us </span></Text>

                    <h5 className={privacyStyle.heading}>   2.<span className={privacyStyle.spanPara}> Collection and use of data. </span></h5>
                    <Text className={privacyStyle.paraText}>1.<span className={privacyStyle.spanPara}> We collect data about you directly from you and automatically through your use of our Services. We may combine data automatically collected with other data that we have collected about you including from publicly available sources. We encourage you to provide only the data that is necessary for using our Services. </span></Text>
                    <Text className={privacyStyle.paraText}>2.<span className={privacyStyle.spanPara}> Data collected automatically. Like many websites, technologies to receive and store certain types of data when you interact with us through your device. Using these technologies helps us customize your experience with our Services, and improve your experience.</span></Text>


                    <h5 className={privacyStyle.heading}>   3.<span className={privacyStyle.spanPara}>  Sharing your data. </span></h5>
                    <Text className={privacyStyle.paraText}>We do not sell your data to any third parties or disclose it in exchange for money or other valuable consideration. We do not share your data with others except as indicated within this Statement, or when we inform you at a subsequent time. We may share your data as follows: </Text>
                    <Text className={privacyStyle.paraText}>1.<span className={privacyStyle.spanPara}>   With third-party service providers, contractors, or government entities.</span></Text>
                    <Text className={privacyStyle.paraText}>2.<span className={privacyStyle.spanPara}>   We use third-party service providers’ services on our behalf or to assist us with providing services to you such as processing payment card transactions, sending, email hosting, IT services, or customer service;</span></Text>
                    <Text className={privacyStyle.paraText}>3.<span className={privacyStyle.spanPara}>  For search engine optimization (“SEO”) purposes and for serving you the most relevant advertising;</span></Text>
                    <Text className={privacyStyle.paraText}>4.<span className={privacyStyle.spanPara}>  With service providers and government entities for legal, security, and safety purposes. This includes sharing data to enforce policies or contracts, address security breaches, and assist in the investigation of fraud, security issues, or other concerns.</span></Text>
                    <Text className={privacyStyle.paraText}>5.<span className={privacyStyle.spanPara}>  If we believe that disclosure is reasonably necessary to comply with a law, regulation, legal, or governmental request; to enforce the applicable agreement you have with us or this Statement.</span></Text>
                    <Text className={privacyStyle.paraText}>6.<span className={privacyStyle.spanPara}>  For our own internal business purposes including but not limited to product improvement in aggregated, anonymized, and/or de-identified form that cannot reasonably be used to identify you.</span></Text>

                    <h5 className={privacyStyle.heading}>   4.<span className={privacyStyle.spanPara}> Categories of data. </span></h5>
                    <Text className={privacyStyle.paraText}>When using PMHScribe, we collect data including but not limited to the following potentially personal data:</Text>
                    <Text className={privacyStyle.paraText}>1.<span className={privacyStyle.spanPara}>  Name: This is used as your account login and creating your account for the Services.</span></Text>
                    <Text className={privacyStyle.paraText}>2.<span className={privacyStyle.spanPara}>  Email: This is used as your account login, and we may occasionally send emails communicating to you. We will not share this with any third party that's not necessary for the product to function (e.g. it would be shared with our authentication provider). We will not share your data or email to any third party that is not necessary. Additionally, this is used to provide the Services and to contact you.</span></Text>
                    <Text className={privacyStyle.paraText}>3.<span className={privacyStyle.spanPara}>  Phone number: This is used as part of your account login. We will not share this with any third party that's not necessary for the Services to function (e.g. it would be shared with our authentication provider). If you optionally set up text message reminders or other phone functionality, your phone number will be stored. Additionally, this is used to contact you, if applicable.</span></Text>
                    <Text className={privacyStyle.paraText}>4.<span className={privacyStyle.spanPara}>   Entries: this is data provided to the Services by you and stored and used in the Services. In using the Services, you will provide us with certain data, and we will use this data in accordance with our Terms of Services. This includes but is not limited to any data processed by Us in connection with the Services such as chats or messages, customer attribute data, data such as information about participants; protocols such as details of objectives, design, and methodology.</span></Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>i.<span className={privacyStyle.spanPara}>Except as otherwise expressly permitted in writing by PMHScribe, You specifically agree not to use the Services as a medical record system. You acknowledge that, unless specifically represented to you otherwise in writing by PMHScribe, PMHScribe is not a medical record system and is not subject to any additional obligations such as applicable medical retention rules that may apply, and You shall be solely responsible for extracting and maintaining such records separately and outside of PMHScribe’s systems.</span></Text>
                    <Text className={privacyStyle.paraText}>5.<span className={privacyStyle.spanPara}> In addition to this data, we may collect data such as browser metadata and how often you log in, what features you use in the product, etc. to help us understand high-level analytics of how the Services are used.</span></Text>
                    <Text className={privacyStyle.paraText}>6.<span className={privacyStyle.spanPara}>   We may also collect data that is used to pre-populate the headers of the reports generated by the Services. Providing this data is optional; however please note that such data is typically publicly available healthcare provider’s name, credentials, National Provider Identifier (NPI), group practice NPI, practice address, email, phone, fax, EIN, logo, and provider’s photo.</span></Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>i.<span className={privacyStyle.spanPara}> For example: If you are seeking to order an EKG, the Services generate the order slip for the EKG with the provider’s practice information on it.</span></Text>
                    <Text className={privacyStyle.paraText}>7.<span className={privacyStyle.spanPara}>  If there a fee actions or transactions you make in our Services, we will collect data that allows us to collect and process your payment which may include your bank account information or payment card number.</span></Text>
                    <Text className={privacyStyle.paraText}>8.&nbsp;&nbsp;&nbsp;&nbsp;   Any other data you choose to submit to the Services.</Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>i.<span className={privacyStyle.spanPara}>This may include: your credentials that you choose to submit as well as any other relevant information.</span></Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>ii.<span className={privacyStyle.spanPara}> YOU ARE AWARE THAT YOU MAY SUBMIT CONTENT TO THE SERVICES PROTECTED HEALTH INFORMATION UNDER THE HEALTH INSURANCE PORTABILITY AND ACCOUNTABILITY ACT, 1996 OF THE USA (HIPAA), INCLUDING CONTENT RELATED TO CONDITIONS, TREATMENTS, AND MEDICATIONS. THIS IS ENTIRELY WITHIN YOUR CONTROL. BY USING THE SERVICES, YOU AGREE THAT DESPITE THESE RISKS, YOU RELEASE PMHSCRIBE FROM ANY LIABILITY ARISING FROM SUCH RISKS.</span></Text>

                    <h5 className={privacyStyle.heading}>   5.<span className={privacyStyle.spanPara}> How we use your data. </span></h5>
                    <Text className={privacyStyle.paraText}>We may use data we collect about you to:</Text>
                    <Text className={privacyStyle.paraText}>1.<span className={privacyStyle.spanPara}>  We will use your data to provide our Services to you; we use the payment data you provide to us in order to alert you of, to allow us to present the billing history, and to perform internal financial processes.</span></Text>
                    <Text className={privacyStyle.paraText}>2.<span className={privacyStyle.spanPara}>  Improve our products. Analysis about your use of, or interest in our products.</span></Text>
                    <Text className={privacyStyle.paraText}>3.<span className={privacyStyle.spanPara}>  We may send you service emails to ensure the service is working properly.</span></Text>
                    <Text className={privacyStyle.paraText}>4.<span className={privacyStyle.spanPara}>  Subject to your preference, we may send you emails about new product features or other news about the Company or on topics we think would be relevant to you.</span></Text>
                    <Text className={privacyStyle.paraText}>5.<span className={privacyStyle.spanPara}>  We may contact you to inform you about changes in your services, our service offering and other important service-related notices.</span></Text>
                    <Text className={privacyStyle.paraText}>6.<span className={privacyStyle.spanPara}>  We may use your data to comply with applicable legal obligations as well as to protect our rights and interests as well as the rights and interests of our users and any other person.</span></Text>

                    <h5 className={privacyStyle.heading}>   6.<span className={privacyStyle.spanPara}>  Security of your data. </span></h5>
                    <Text className={privacyStyle.paraText}>We take reasonable and appropriate steps to protect the data entrusted to us and treat it securely in accordance with this Statement. We implement safeguards designed to protect your data from accidental/unlawful destruction, loss, alteration, and unauthorized dissemination. We will retain your data as needed to fulfill the purposes for which it was collected or as needed to fulfill our business needs or legal requirements. In the event that a security issue arises with your data, we will disclose any relevant data to you.</Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>i.<span className={privacyStyle.spanPara}>  The Services may contain links to third-party websites which are independent of us. We assume no responsibility for the content or practices of and make no representation or warranty as to the accuracy, completeness or authenticity of information contained in any third-party websites. We have no right or ability to edit the content of any third-party websites. You acknowledge that we shall not be liable for any liability arising from your use of any third-party websites.</span></Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>ii.<span className={privacyStyle.spanPara}> Retention. PMHScribe will store personal data for as long as it serves the intended purpose or as mandated by legal requirements, which might extend beyond the conclusion of your engagement with PMHScribe. Certain data may be retained to prevent fraud, ensure future security, or for legitimate business purposes.</span></Text>

                    <h5 className={privacyStyle.heading}>   7.<span className={privacyStyle.spanPara}>   Cookies. </span></h5>
                    <Text className={privacyStyle.paraText}>PMHScribe uses cookies and related technologies such as pixels and tags, which collect certain information that your browser sends to us—such IP address and MAC address. These tools help make your visit to our websites and Services personalized. We also use the information to improve our Services and better understand your potential interest in our Services. We partner with third parties to display advertising on our website and to manage our advertising on other sites. Our third-party partners may use cookies or similar technologies to provide you with advertising based on your browsing activities and interests. For more information, or if you would like to opt out of such use cases, click “Cookies” in the footer of this website. Additionally, you may be able to use other tools to control cookies and similar technologies. For example, you may have controls in your internet browser to limit how the websites you visit are able to use cookies.</Text>

                    <h5 className={privacyStyle.heading}>   8.<span className={privacyStyle.spanPara}>  Ai. </span></h5>
                    <Text className={privacyStyle.paraText}>1.<span className={privacyStyle.spanPara}>  We use data you submit to the Services (primarily transcripts or recordings of your sessions) to train our proprietary artificial intelligence. This relies on de-identified and/or aggregated data. This is aimed at providing our Services as well as improving them as well as your experience with them.</span></Text>
                    <Text className={privacyStyle.paraText}>2.<span className={privacyStyle.spanPara}> You understand that due to the inherent complexities of language and speech recognition, errors and inaccuracies may occur in the transcription process. It is your responsibility to review, verify, and validate the accuracy of the output generated by our Services. We recommend cross-referencing the transcribed text with the original audio or seeking professional assistance for critical applications where accuracy is paramount. By using our Services, You accept and understand that the transcription output is provided "as is," and we disclaim any liability for errors, omissions, or discrepancies in the transcribed content.</span></Text>

                    <h5 className={privacyStyle.heading}>   9.<span className={privacyStyle.spanPara}>  Use of third-party services. </span></h5>
                    <Text className={privacyStyle.paraText}>The use of third-party services is necessary to help power the delivery of PMHScribe. By using PMHScribe, you are effectively agreeing to this Privacy Statement as well as of our third-party service providers. The purposes of such sharing include but are not limited to: operating our business; delivering, analyzing, improving, securing, and customizing our websites and Services; sending marketing and other communications related to our business; and for other legitimate purposes permitted by applicable laws; or otherwise with your consent.</Text>
                    <Text className={privacyStyle.paraText}>1.<span className={privacyStyle.spanPara}>   Such service providers include but are not limited to: hosting services, cybersecurity services, note generation, payment processing providers, live transcription, analytics, activity recording, and content transcription and analysis services.</span></Text>

                    <h5 className={privacyStyle.heading}>   10.<span className={privacyStyle.spanPara}> Privacy Rights. </span></h5>
                    <Text className={privacyStyle.paraText}>1.<span className={privacyStyle.spanPara}> Depending on the jurisdiction where you are located, you may have certain privacy rights. This includes jurisdictions such as California and other US states with privacy laws such as Virginia and Colorado, as well as outside the US such as the GDPR which governs the European Union.  These rights may include the right to: (1) request access, correction, and deletion of their Personal Data, (2) opt out of the sale or sharing of their Personal Data, and (3) not be discriminated against for exercising one of their privacy rights.</span></Text>
                    <Text className={privacyStyle.paraText}>2.<span className={privacyStyle.spanPara}> The following disclosures are intended to provide additional data about (1) the categories of Personal Data we collect (as defined above), (2) the source of the Personal Data, (3) how we use each category of Personal Data, and (4) how we disclose Personal Data. These disclosures do not limit our ability to use or disclose data as described above.</span></Text>
                    <Text className={privacyStyle.paraText}>3.<span className={privacyStyle.spanPara}> Additional information on privacy:</span></Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>i.<span className={privacyStyle.spanPara}> Category of Personal Data: Name, e-mail or user ID, profile password, gender, phone number, payment details, date of birth, geolocation, and any other conversation content that might be generated by you on the Services or your use of the Services.</span></Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>ii.<span className={privacyStyle.spanPara}> Sources of Personal Data: directly from you, third-party sites, or sources such as our trusted business partners, analytics tools, and social networks.</span></Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>iii.<span className={privacyStyle.spanPara}> Use of Personal Data: deliver the Services to you, provide you data, and communicate with you about your account and usage such as about your payments or transaction history, provide you promotions, and enhance and personalize your user experience. Lastly, to improve our Services as well as build new services in the future.</span></Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>iv.<span className={privacyStyle.spanPara}> Disclosure of Personal Data: our affiliates, our services providers such as for hosting the Service, processing payments, and communicating with you.</span></Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>v.<span className={privacyStyle.spanPara}> We do not and will not sell or share (as defined by CPRA) your Personal Data.</span></Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>vi.<span className={privacyStyle.spanPara}> Exercising Your Rights: You can exercise the above privacy rights by emailing us at <Anchor href="mailto:allison@erx.ai" td="underline">allison@erx.ai.</Anchor></span></Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>vii.<span className={privacyStyle.spanPara}>  Verification: in order to protect your Personal Data from unauthorized access or deletion, we may require you to verify your identity before you can submit a privacy request to know or delete Personal Data. In order to keep your data accurate and complete, you can log in to review and update your Account data. You may also contact us to: request the personal data we have collected from you or request the rectification or deletion of such data. We will do our best to honor your requests subject to any legal and contractual obligations. If you do not wish to receive promotional emails from us, you may opt-out at any time by using the link in the email itself. Please note that it may take up to ten (10) days to process your request.</span></Text>
                    <Text className={`${privacyStyle.paraText} ${privacyStyle.subPara}`}>viii.<span className={privacyStyle.spanPara}> Authorized Agent. You may submit a request to know or a request to delete your Personal Data through an authorized agent. If you do so, the agent must present signed written permission to act on your behalf and you may also be required to independently verify your identity and submit proof of your residency.</span></Text>

                    <h5 className={privacyStyle.heading}>11.<span className={privacyStyle.spanPara}>  Children’s data. </span></h5>
                    <Text className={privacyStyle.paraText}>The Services are directed to serve adults only. PMHScribe does not knowingly collect Personal Data from children without appropriate parental or guardian consent. If you believe that we may have collected Personal Data from someone under the applicable age of consent in your country without proper consent, please contact us at allison@erx.ai, and we will take appropriate measures to investigate and address the issue promptly. You must be at least eighteen (18) years of age to use the Services unless the age of majority in your jurisdiction is greater than eighteen (18) years of age, in which case you must be at least the age of majority in your jurisdiction (e.g., twenty-one (21)). If you are under the applicable age of majority, you shall not use and are not permitted to use the Services and you must immediately leave the Services. By using the Services, you represent and warrant that you have reached at least the age of eighteen (18) and the age of majority in your jurisdiction. We maintain a zero-tolerance policy for any minors on the Services. If you are a parent or guardian of a minor under the age of eighteen (18) and believe he or she has disclosed personal data to us, please contact us at <Anchor href="mailto:allison@erx.ai" td="underline">allison@erx.ai.</Anchor></Text>

                    <h5 className={privacyStyle.heading}>12.<span className={privacyStyle.spanPara}>  Users outside the United States. </span></h5>
                    <Text className={privacyStyle.paraText}>If you are an individual located in another jurisdiction outside of the US with laws governing data processing that differ from US laws, please be aware that the data we collect will be processed and stored in the US or in other countries where we or our service providers have operations. Your use of the Services is voluntary, and you are doing so with a full understanding that by submitting your data to PMHScribe, you understand and acknowledge to have your data transferred to, the US or another jurisdiction which may not offer the same level of privacy protection as those in the country where you are located.</Text>

                    <h5 className={privacyStyle.heading}>13.<span className={privacyStyle.spanPara}>  Changes and contacting us.</span></h5>
                    <Text className={privacyStyle.paraText}>This Statement is current as of the Effective Date set forth above and if any changes are made, we’ll post them on this page. If you continue to use PMHScribe after those changes are in effect, you agree to the changes. This Statement may change if there is a material change to the way data is processed by PMHScribe. If you have any questions or comments about this Statement, please contact us at <Anchor href="mailto:allison@erx.ai" td="underline">allison@erx.ai</Anchor> or 412 S. Second Street, St. Charles, IL 60174.</Text>


                </Grid.Col>
                <Grid.Col span={1} md={2}>
                </Grid.Col>
            </Grid>

            <Footer></Footer>
        </Box>
    )
}
