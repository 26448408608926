export const DEFAULT_IGNORED_PROPERTIES = [
  'meta',
  'implicitRules',
  'language',
  'text',
  'contained',
  'extension',
  'modifierExtension',
];

export const PATIENT_DEFAULT_PROPERTIES = [
  'name',
  'gender',
  'birthDate',
  'telecom',
  'email',
  'address',
];

export const PropertyDisplayMapping: any = {
  telecom: 'Contact',
  contact: 'Emergency Contact',
  birthDate: 'Date of Birth',
  lastSession: 'Last Session Date & Time',
};
