/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { deepClone, normalizeErrorString } from '@medplum/core';
import { ResourceType } from '@medplum/fhirtypes';
import { getPractitionerRole, getQuestionnaire, getCurrentSubscriptionPlan, addPaymentMethod } from '../utils/util';
import { useMedplum, useResource } from '@medplum/react-hooks';
import { Box, Button, Group, Title } from '@mantine/core';
import { DescriptionList, DescriptionListEntry, QuestionnaireItemType } from '@medplum/react';

export const practitionerMap: Record<string, string> = {
  "prescriber": "Psychiatry",
  "therapist": "Counselor",
  "psychological testing": "Psychological Testing"
};

const PractitionerDetailsPage = () => {
  const medplum = useMedplum();
  const { resourceType, id } = useParams() as { resourceType: ResourceType; id: string };
  const [value, setValue] = useState<any>();
  const [questionnaireResponse, setQuestionnaireResponse] = useState<any>();
  const resource: any = useResource({ reference: resourceType + '/' + id });
  const [currentRole, setCurrentRole] = useState<string | null>(null);
  const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState<any | null>(null);

  useEffect(() => {
    medplum
      .readResource(resourceType, id)
      .then((resource) => setValue(deepClone(resource)))
      .catch((err) => {
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      });
  }, [medplum, resourceType, id]);

  const getQuestionnaireResponse = async (questionnaireResponseId: any) => {
    const data = await medplum.readResource('QuestionnaireResponse', questionnaireResponseId);
    return data;
  };

  useEffect(() => {
    if (resource?.resourceType === 'Practitioner' && resource.id) {
      const practitionerId = resource.id;
      getPractitionerRole(medplum, practitionerId)
        .then((response: any) => {
          const role = response?.entry?.[0]?.resource?.code?.[0]?.coding?.[0]?.display || '';
          const mappedRole = practitionerMap[role.toLowerCase()] || role;
          setCurrentRole(mappedRole);
        })
        .catch((error: any) => {
          console.error('Failed to get practitioner role:', error);
        });
    }
  }, [resource, medplum]);

  useEffect(() => {
    if (resource?.resourceType === 'Practitioner') {
      const identifier = resource.identifier?.find((id: { system: string; }) => id.system === 'http://user-id');
      const userId = identifier?.value;
      if (userId) {
        getCurrentSubscriptionPlan(medplum, userId)
          .then((response: any) => {
            const mappedPlan = practitionerMap[response.plan.toLowerCase()] || response.plan;
            setCurrentSubscriptionPlan({ ...response, plan: mappedPlan });
          })
          .catch((error: any) => {
            console.error('Failed to get subscription plan', error);
          });
      }
    }
  }, [resource, medplum]);

  useEffect(() => {
    if (value) {
      const extension: any = value?.meta?.extension;
      const registrationExtension: any = extension?.find(
        (ext: any) => ext.url === '/pmhs/StructureDefinition/RegistrationQuestionnaireResponse'
      );

      if (registrationExtension) {
        const valueUrlParts = registrationExtension.valueUrl.split('/');
        const questionnaireResponseId = valueUrlParts[valueUrlParts.length - 1];

        getQuestionnaireResponse(questionnaireResponseId)
          .then((fetchedQuestionnaireResp: any) => {
            getQuestionnaire(medplum)
              .then((fetchedQuestionnaire: any) => {
                const itemInfoMapping: any = {};

                const extractItemInfo = (item: any): any => {
                  if (item.linkId) {
                    itemInfoMapping[item.linkId] = {
                      type: item.type,
                      definition: item.definition,
                    };
                  }

                  if (item.item) {
                    item.item.forEach((subItem: any) => {
                      extractItemInfo(subItem);
                    });
                  }
                };

                fetchedQuestionnaire.item.forEach((item: any) => {
                  extractItemInfo(item);
                });

                const enhanceResponseItem = (responseItem: any): any => {
                  const linkId = responseItem.linkId;
                  const itemInfo = itemInfoMapping[linkId];

                  if (itemInfo) {
                    responseItem.definition = itemInfo.type;
                  }

                  if (responseItem.item) {
                    responseItem.item.forEach((subItem: any) => {
                      enhanceResponseItem(subItem);
                    });
                  }
                };

                // update with each item type from questionnaire
                fetchedQuestionnaireResp.item.forEach((responseItem: any) => {
                  enhanceResponseItem(responseItem);
                });
                setQuestionnaireResponse(fetchedQuestionnaireResp);
              })
              .catch((error: any) => {
                showNotification({ color: 'red', message: normalizeErrorString(error) });
              });
          })
          .catch((error: any) => {
            showNotification({ color: 'red', message: normalizeErrorString(error) });
          });
      }
    }
  }, [medplum, value]);

  const addUpdatePaymentMethod = () => {
    const payload = { practitionerId: resource.id };
    addPaymentMethod(payload, medplum).then((response: any) => {
      const redirectUrl = response?.addPaymentMethodLink;
      window.open(redirectUrl, "_blank", "noopener,noreferrer");
    }).catch((error: any) => {
      showNotification({ color: 'red', message: 'Failed to redirect Add/Update payment method page, try again later or contact to administrator' });
    });
  }

  const findAnswer = (items: any[], text: string) =>
    items.find((item: { text: string }) => item.text === text)?.answer?.[0]?.valueString || '';

  const renderQuestion = (question: (typeof questionnaireResponse)['item'][0]): JSX.Element | null => {
    if (question.definition === QuestionnaireItemType.group) {
      let term = '';
      let value = '';
      switch (question.text) {
        // case 'Your Name':
        case 'Your Information: Your preferred full formal title if filling out forms or letters for patients. For example: Board Certified Psychiatrist or Psychiatric Physician Associate.':
          term = 'Name';
          value = `${findAnswer(question.item, 'First Name')} ${findAnswer(question.item, 'Last Name')}`;
          break;
        // case 'Practice Address':
        case 'Practice Address: To have customized test orders, letters to patients, and letterhead, please enter your practice’s corporate or main mailing address.':
          term = 'Address';
          const streetAddress = findAnswer(question.item, 'Street Address');
          const streetAddressLine2 = findAnswer(question.item, 'Street Address Line 2');
          const city = findAnswer(question.item, 'City');
          const state = findAnswer(question.item, 'State/Province');
          const postalCode = findAnswer(question.item, 'Postal/Zip Code');

          const addressComponents = [
            streetAddress,
            streetAddressLine2,
            city,
            state,
            postalCode
          ].filter(component => component && component.trim() !== '');

          value = addressComponents.join(', ');
          break;
        case 'Create Your Login':
          term = 'Username';
          value = `${findAnswer(question.item, 'E-mail address to be used for Login')}`;
          break;
        default:
          return (
            <Box key={question.id} mb="lg">
              {question.item.map((subQuestion: any) => renderQuestion(subQuestion))}
            </Box>
          );
      }

      return (
        <Box key={question.id} mb="lg">
          <DescriptionList>
            <DescriptionListEntry term={term}>{value}</DescriptionListEntry>
          </DescriptionList>
        </Box>
      );
    } else if (question.definition === QuestionnaireItemType.string) {
      const answer: any = question.answer?.[0];
      return (
        <DescriptionList>
          <DescriptionListEntry term={question.text}>{answer?.valueString || ''}</DescriptionListEntry>
        </DescriptionList>
      );
    }
    return null;
  };

  return (
    <Box className="practitioner-details">
      {questionnaireResponse?.item.map((question: any) => renderQuestion(question))}
      <DescriptionList>
        <DescriptionListEntry term="Account Status">
          {value?.active ? 'Active' : 'Inactive'}
        </DescriptionListEntry>
        <DescriptionListEntry term="Role">
          {currentRole}
        </DescriptionListEntry>
      </DescriptionList>

      {currentSubscriptionPlan && (
        <Box className="practitioner-details">
          <Group sx={{ display: 'block' }}>
            <Title className='subscription-details-style'>SUBSCRIPTION DETAILS</Title>
          </Group>

          <DescriptionList>
            {[
              { term: "Plan", value: `${currentSubscriptionPlan.plan} Plan` },
              { term: "Amount", value: `$${currentSubscriptionPlan.amount}.00` },
              { term: "Payment Date", value: currentSubscriptionPlan.paymentDate },
              { term: "End date", value: currentSubscriptionPlan.endDate },
              { term: "Subscription Id", value: currentSubscriptionPlan.subscriptionId },
              { term: "Payment Status", value: currentSubscriptionPlan.paymentStatus },
            ].map(({ term, value }) => (
              <DescriptionListEntry key={term} term={term}>
                {value}
              </DescriptionListEntry>
            ))}
          </DescriptionList>

          <Button
            type="submit"
            sx={{ marginTop: '20px', marginLeft: '10px' }}
            onClick={addUpdatePaymentMethod}>
            Manage Subscription
          </Button>
          <Title size="14px" fs="12px" style={{ lineHeight: '30px' }} fw={60}
            sx={{ marginLeft: '10px' }}>
            (Add/update payment method, cancel subscription)
          </Title>
        </Box>
      )}
    </Box>
  );
};

export default PractitionerDetailsPage;
