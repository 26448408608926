import { RecordingStatus } from '@zoom/videosdk';
import classNames from 'classnames';
import { Button, Tooltip } from '@mantine/core';
import { IconPlayerPauseFilled, IconPlayerPlayFilled, IconPlayerRecordFilled, IconPlayerStopFilled } from '@tabler/icons-react';
import React from 'react';

export interface RecordButtonProps {
  text: string;
  tipText: string;
  icon: JSX.Element;
  hoverIcon: string | JSX.Element;
  onClick?: () => void;
}

export const recordStatusIcon = {
  text: 'Status',
  tipText: 'Record Status',
  icon: <IconPlayerRecordFilled color='red' />,
  hoverIcon: <IconPlayerRecordFilled color='red' size={26} />
};

export const getRecordingButtons = (status: RecordingStatus | '', isHost: boolean) => {
  // Stopped = recording
  // Recording = pause recording/ stop recording
  // Paused = resume recording/ stop recording
  let buttons: RecordButtonProps[] = [];

  if (status === RecordingStatus.Stopped || status === '') {
    buttons = [
      {
        text: 'Record',
        tipText: 'Start Recording',
        icon: <IconPlayerRecordFilled />,
        hoverIcon: 'icon-recording-hover'
      }
    ];
  } else if (status === RecordingStatus.Recording) {
    if (!isHost) return [recordStatusIcon];
    buttons = [
      recordStatusIcon,
      {
        text: 'Pause',
        tipText: 'Pause Recording',
        icon: <IconPlayerPauseFilled />,
        hoverIcon: 'icon-recording-pause-hover'
      },
      {
        text: 'Stop',
        tipText: 'Stop Recording',
        icon: <IconPlayerStopFilled />,
        hoverIcon: 'icon-recording-stop-hover'
      }
    ];
  } else if (status === RecordingStatus.Paused) {
    if (!isHost) return [recordStatusIcon];
    buttons = [
      recordStatusIcon,
      {
        text: 'Resume',
        tipText: 'Resume Recording',
        icon: <IconPlayerPlayFilled />,
        hoverIcon: 'icon-recording-resume-hover'
      },
      {
        text: 'Stop',
        tipText: 'Stop Recording ?',
        icon: <IconPlayerStopFilled />,
        hoverIcon: 'icon-recording-stop-hover'
      }
    ];
  }
  return buttons;
};

const RecordingButton = (props: RecordButtonProps) => {
  const { tipText, icon, onClick } = props;
  return (
    <Tooltip label={tipText}>
      <Button
        className={classNames('vc-button')}
        // eslint-disable-next-line react/jsx-boolean-value
        size="sm"
        radius="xl"
        onClick={onClick}
      > { icon } </Button>
    </Tooltip>
  );
};

export { RecordingButton };
