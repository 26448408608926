/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Modal, Textarea, Button, Group, Text } from '@mantine/core';
import { IconStar, IconStarFilled } from '@tabler/icons-react';
import { toast } from 'react-toastify';
import { useMedplum } from '@medplum/react';

interface FeedbackRatingProps {
    opened: boolean;
    onClose: () => void;
    onSubmit: (feedback: string[], comment: string,  rating: string) => void;
    noteId: string;
}

const FeedbackRatingPopup: React.FC<FeedbackRatingProps> = ({ opened, onClose, onSubmit, noteId  }) => {
    const medplum = useMedplum();
    const [selectedFeedback, setSelectedFeedback] = useState<string[]>([]);
    const [comment, setComment] = useState<string>('');
    const [rating, setRating] = useState<number>(0);
    
    const handleFeedbackToggle = (feedback: string) => {
        setSelectedFeedback((prev) =>
            prev.includes(feedback)
                ? prev.filter((item: string) => item !== feedback)
                : [...prev, feedback]
        );
    };

    const handleSubmit = () => {
        if (!rating) {
            toast.error('Please select a rating before submitting');
            return;
        }
        onSubmit(selectedFeedback, comment, rating.toString());
        onClose();
    };
    useEffect(() => {
        medplum
        .search('CommunicationRequest' as 'CommunicationRequest',
          {
            _sort: '-_lastUpdated',
            _total: 'accurate',
            identifier: `DocumentReference/${noteId}`,
          }
        ).then((response) => {
            response.entry?.forEach((entry) => {
                setRating(Number(entry?.resource?.category?.[0].coding?.[0].display) || 0);
                const note = entry?.resource?.note?.[0]?.text?.split(':');
                setSelectedFeedback(note?.[0]?.split(',') || []);
                setComment(note?.[1] || '');
            });
        }).catch((error) => {
            console.log(error);
        });
    }, [opened]);

    return (
        <Modal className="feedBackPopup" opened={opened} onClose={onClose} title={<div>Please rate the quality of your note</div>} centered>
            <Group spacing="md" pl={15}>
                <Group className="rating-group" mb="6px" ml="5rem" spacing="xs" mt="md">
                    {Array.from({ length: 5 }).map((_, index) => {
                        const starValue = index + 1;
                        return starValue <= rating ? (
                            <IconStarFilled
                                key={index}
                                onClick={() => setRating(starValue)}
                                style={{ cursor: 'pointer', color: '#374c5f' }}
                                size={35}
                            />
                        ) : (
                            <IconStar
                                key={index}
                                onClick={() => setRating(starValue)}
                                style={{ cursor: 'pointer', color: '#374c5f' }}
                                size={35}
                            />
                        );
                    })}
                </Group>
                <Group spacing="xs" className='feedbackBtnGroup'>
                    <Button
                        variant={selectedFeedback.includes('Too Many Details') ? 'filled' : 'outline'}
                        onClick={() => handleFeedbackToggle('Too Many Details')}
                        ml={15}
                    >
                        Too Many Details
                    </Button>
                    <Button
                        variant={selectedFeedback.includes('Not Enough Details') ? 'filled' : 'outline'}
                        onClick={() => handleFeedbackToggle('Not Enough Details')}
                    >
                        Not Enough Details
                    </Button>
                    <Button
                        variant={selectedFeedback.includes('Recording Trouble') ? 'filled' : 'outline'}
                        onClick={() => handleFeedbackToggle('Recording Trouble')}
                    >
                        Recording Trouble
                    </Button>
                    <Button
                        variant={selectedFeedback.includes('Inaccurate') ? 'filled' : 'outline'}
                        onClick={() => handleFeedbackToggle('Inaccurate')}
                    >
                        Inaccurate
                    </Button>
                    <Button
                        variant={selectedFeedback.includes('Other') ? 'filled' : 'outline'}
                        onClick={() => handleFeedbackToggle('Other')}
                    >
                        Other
                    </Button>
                </Group>
                <Group className="group">
                    <Text className="commentText">Comment (Optional)</Text>
                    <Textarea
                        placeholder="(Optional) Feel free to add specific details"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        mt={10}
                        styles={{ input: { height: '100px' } }}
                    />
                </Group>
                <Group mt={10} spacing={4} className='btn-group'>
                    <Button mr={10} className='cancel' onClick={onClose}>Cancel</Button>
                    <Button className='submit' onClick={handleSubmit}>Submit</Button>
                </Group>
            </Group>
        </Modal>
    );
};

export default FeedbackRatingPopup;
