import React from 'react';
import { Button, Center, Modal, Title } from '@mantine/core';

interface ConfirmBoxProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  content: string;
}

const ConfirmBox: React.FC<ConfirmBoxProps> = ({ isOpen, onClose, onConfirm, content }) => {
  return (
    <Modal opened={isOpen} onClose={onClose} withCloseButton={false}>
      <Center style={{ textAlign: 'center' }}>
        <Title style={{ fontSize: '20px', lineHeight: '30px' }} fw={500} my={20}>
          {content}
        </Title>
      </Center>
      <Center mt="lg">
        <Button className="btn-success" mr={10} px={'lg'} style={{ borderRadius: '25px' }} onClick={onConfirm}>
          Yes
        </Button>
        <Button className="btn-danger" px={'lg'} style={{ borderRadius: '25px' }} onClick={onClose}>
          No
        </Button>
      </Center>
    </Modal>
  );
};

export default ConfirmBox;
