import { Grid, RingProgress, Tooltip, Text, Skeleton, Box, Button } from '@mantine/core'
import { useMedplum } from '@medplum/react';
import React, { useCallback, useEffect, useState } from 'react'
import { getCostRevenueOverviewMetrics } from '../utils/util';
import { useLocation } from 'react-router-dom';

interface CostRevenueOverviewData {
    activePatients: number;
    activePractitioners: number;
    activeSubscriptions: number;
    expiredSubscriptions: number;
    inactivePatients: number;
    inactivePractitioners: number;
    totalAppointments: number;
    totalPatients: number;
    totalPractitioners: number;
    totalRevenue: number;
    totalSubscriptions: number;
}

export default function Overview() {
    const medplum = useMedplum();
    const location = useLocation();
    const [loadingOverview, setLoadingOverview] = useState<boolean>(true);
    const [costRevenueOverviewData, setCostRevenueOverviewData] = useState<CostRevenueOverviewData | null>(null);

    const fetchCostRevenueOverviewMetrics = useCallback(async () => {
        try {
            const response = await getCostRevenueOverviewMetrics(medplum);
            setCostRevenueOverviewData(response);
        } catch (error) {
            console.error('Error fetching cost & revenue overview metrics:', error);
        } finally {
            setLoadingOverview(false);
        }
    }, [medplum]);

    useEffect(() => {
        fetchCostRevenueOverviewMetrics();
    }, []);

    const isCostRevenuePage = location.pathname === '/cost-revenue';
    const isUserActivityPage = location.pathname === '/user-activity';

    const renderSkeletons = () => (
        <Grid>
            <Grid.Col span={4}>
                <Skeleton className='cost-revenue-skeleton' />
            </Grid.Col>
            <Grid.Col span={4}>
                <Skeleton className='cost-revenue-skeleton' />
            </Grid.Col>
            {isUserActivityPage && (
                <Grid.Col span={4}>
                    <Skeleton className='cost-revenue-skeleton' />
                </Grid.Col>
            )}
        </Grid>
    );

    return (
        <Box className='overview-div'>
            <Grid className='overview-grid'>
                <Grid.Col span={6}>
                    <Text align="left" size={18} fw={600} ml={10}>
                        {isUserActivityPage ? 'User Activity Overview' : 'Cost & Revenue Overview'}
                    </Text>
                </Grid.Col>
                <Grid.Col span={6} sx={{ textAlign: 'end' }}>
                    <Button className='download-btn'>
                        <img src="../../../../img/dashboard/download.svg" alt="Download" />
                        <Text pl={5} size={14} color='#344054' fw={600}>Download Report</Text>
                    </Button>
                </Grid.Col>
            </Grid>

            <div className='overview-sect'>
                {loadingOverview ? (
                    renderSkeletons()
                ) :
                    (costRevenueOverviewData && (
                        <Grid>
                            <Grid.Col span={4}>
                                <div className='total-practitioner'>
                                    <Grid pr={20}>
                                        <Grid.Col span={6} >
                                            <Text align="left" size={14} fw={500} ml={10} color='#475467' pt={5}>Total Registrations</Text>
                                            <Text fw={600} size={30} pl={10}>{costRevenueOverviewData.totalPractitioners}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={6} className='ring-progress'>
                                            <Tooltip
                                                label={
                                                    <>
                                                        Inactive: {costRevenueOverviewData.inactivePractitioners} <br />
                                                        Active: {costRevenueOverviewData.activePractitioners}
                                                    </>
                                                }
                                                withArrow
                                            >
                                                <RingProgress
                                                    size={90}
                                                    thickness={12}
                                                    sections={[{ value: (costRevenueOverviewData.inactivePractitioners / costRevenueOverviewData.totalPractitioners) * 100, color: '#DFF6C9' }]}
                                                    rootColor="#C2E99B"
                                                />
                                            </Tooltip>
                                        </Grid.Col>
                                    </Grid>
                                </div>
                            </Grid.Col>
                            {isUserActivityPage && (
                                <>
                                    <Grid.Col span={4}>
                                        <div className='total-patient'>
                                            <Grid>
                                                <Grid.Col span={6} >
                                                    <Text align="left" size={14} fw={500} ml={10} color='#475467' pt={5}>Total Patients</Text>
                                                    <Text fw={600} size={30} pl={10}>{costRevenueOverviewData.totalPatients}</Text>
                                                </Grid.Col>
                                                <Grid.Col span={6} className='ring-progress'>
                                                    <Tooltip
                                                        label={
                                                            <>
                                                                Inactive: {costRevenueOverviewData.inactivePatients} <br />
                                                                Active: {costRevenueOverviewData.activePatients}
                                                            </>
                                                        }
                                                        withArrow
                                                    >
                                                        <RingProgress
                                                            size={90}
                                                            thickness={12}
                                                            sections={[{ value: (costRevenueOverviewData.inactivePatients / costRevenueOverviewData.totalPatients) * 100, color: '#E9DDFB' }]}
                                                            rootColor="#BF9DF1"
                                                        />
                                                    </Tooltip>
                                                </Grid.Col>
                                            </Grid>
                                        </div>
                                    </Grid.Col>
                                </>
                            )}
                            {isUserActivityPage && (
                                <>
                                    <Grid.Col span={4}>
                                        <div className='total-session'>
                                            <Grid>
                                                <Grid.Col span={6} >
                                                    <Text align="left" size={14} fw={500} ml={10} color='#475467' pt={5}>Total Sessions</Text>
                                                    <Text fw={600} size={30} pl={10}>{costRevenueOverviewData.totalAppointments}</Text>
                                                </Grid.Col>
                                            </Grid>
                                        </div>
                                    </Grid.Col>
                                </>
                            )}
                            {isCostRevenuePage && (
                                <>
                                    <Grid.Col span={4} >
                                        <div className='total-subscriptions'>
                                            <Grid pr={20}>
                                                <Grid.Col span={6} >
                                                    <Text align="left" size={14} fw={500} ml={10} color='#475467' pt={5}>Total Subscriptions</Text>
                                                    <Text fw={600} size={30} pl={10}>{costRevenueOverviewData.totalSubscriptions}</Text>
                                                </Grid.Col>
                                                <Grid.Col span={6} className='ring-progress'>
                                                    <Tooltip
                                                        label={
                                                            <>
                                                                Expired: {costRevenueOverviewData.expiredSubscriptions} <br />
                                                                Active: {costRevenueOverviewData.activeSubscriptions}
                                                            </>
                                                        }
                                                        withArrow
                                                    >
                                                        <RingProgress
                                                            size={90}
                                                            thickness={12}
                                                            sections={[{ value: (costRevenueOverviewData.expiredSubscriptions / costRevenueOverviewData.totalSubscriptions) * 100, color: '#D3FAF2' }]}
                                                            rootColor="#8AD8C7"
                                                        />
                                                    </Tooltip>
                                                </Grid.Col>
                                            </Grid>
                                        </div>
                                    </Grid.Col>
                                </>
                            )}
                        </Grid>
                    ))}
            </div>
        </Box>
    )
}
