import { Button, Center, Text, Anchor } from '@mantine/core';
import { Attachment } from '@medplum/fhirtypes';
import React, { useRef, useCallback } from 'react';
import { killEvent } from '../../../react/src/utils/dom';

export interface AttachmentInputProps {
  name: string;
  defaultValue?: Attachment;
  arrayElement?: boolean;
  onChange?: (value: Attachment | undefined) => void;
}

export function AttachmentInput(props: AttachmentInputProps): JSX.Element {
  const { defaultValue, onChange } = props;

  const [value, setValue] = React.useState<Attachment | undefined>(defaultValue);
  const [fileError, setFileError] = React.useState<string>('');

  const setValueWrapper = useCallback(
    (newValue: Attachment | undefined) => {
      setValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange]
  );

  const handleRemove = (e: React.MouseEvent) => {
    killEvent(e);
    setValueWrapper(undefined);
  };

  if (value) {
    return (
      <Center w="100%" style={{ justifyContent: 'flex-start', gap: 5 }}>
        <AttachmentDisplay value={value} maxWidth={60} />
        <Button onClick={handleRemove}>Remove</Button>
      </Center>
    );
  }

  const clearFileError = () => {
    setFileError('');
  };

  return (
    <Center display="flex" style={{ justifyContent: 'flex-start' }}>
      <AttachmentButton
        onUpload={(attachment, error) => {
          if (error) {
            setFileError(error);
          } else {
            setValueWrapper(attachment);
          }
        }}
        onClick={clearFileError}
      >
        {(props) => <Button {...props}>Browse Files</Button>}
      </AttachmentButton>
      {fileError && (
        <Text size="xs" ml="xs" color="red">
          {' '}
          {fileError}{' '}
        </Text>
      )}
    </Center>
  );
}

export interface AttachmentButtonProps {
  onUpload: (attachment: Attachment, error?: string) => void;
  onClick?: () => void;
  children(props: { onClick(e: React.MouseEvent): void }): React.ReactNode;
}

export function AttachmentButton(props: AttachmentButtonProps): JSX.Element {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      killEvent(e);
      const files = e.target.files;

      if (files) {
        Array.from(files).forEach((file) => {
          if (file.size <= 5242880) { // 5MB
            const reader = new FileReader();

            reader.onload = (event) => {
              const base64String = event.target?.result as string;
              const attachment: Attachment = {
                contentType: file.type || 'application/octet-stream',
                data: base64String.split(',')[1],
                size: file.size,
                title: file.name,
              };

              props.onUpload(attachment);
            };

            reader.readAsDataURL(file);
          } else {
            const error = 'File size exceeds 20kb limit.';
            props.onUpload({}, error);
          }
        });
      }
    },
    [props]
  );

  const handleButtonClick = (e: React.MouseEvent) => {
    killEvent(e);
    props.onClick?.();
    fileInputRef.current?.click();
  };

  return (
    <div>
      <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={onFileChange} />
      {props.children({ onClick: handleButtonClick })}
    </div>
  );
}

export interface AttachmentDisplayProps {
  value?: Attachment;
  maxWidth?: number;
}

export function AttachmentDisplay(props: AttachmentDisplayProps): JSX.Element | null {
  let { contentType, url, title } = props.value ?? {};

  if (!url) {
    if (props.value?.data) {
      url = `data:${contentType};base64,${props.value?.data}`;
    } else {
      return null;
    }
  }

  return (
    <Center display="block" data-testid="attachment-display" style={{ flex: 4 }}>
      {contentType?.startsWith('image/') && (
        <img data-testid="attachment-image" style={{ maxWidth: props.maxWidth }} src={url} alt={title} />
      )}
      {contentType?.startsWith('video/') && (
        <video data-testid="attachment-video" style={{ maxWidth: props.maxWidth }} controls={true}>
          <source type={contentType} src={url} />
        </video>
      )}
      {contentType === 'application/pdf' && (
        <div data-testid="attachment-pdf" style={{ maxWidth: props.maxWidth, minHeight: 400 }}>
          <iframe
            width="100%"
            height="400"
            src={url + '#navpanes=0'}
            allowFullScreen={true}
            frameBorder={0}
            seamless={true}
          />
        </div>
      )}
      <div data-testid="download-link" style={{ padding: '10px 0' }}>
        <Anchor
          href={url}
          data-testid="attachment-details"
          target="_blank"
          rel="noopener noreferrer"
          download={getDownloadName(title)}
        >
          {title || 'Download'}
        </Anchor>
      </div>
    </Center>
  );
}

function getDownloadName(title: string | undefined): string | undefined {
  // Title often contains the filename by convention
  return title?.includes('.') ? title : undefined;
}
