import React from 'react'
import { Paper, Grid, Text, Button, Table, Box, Loader, Group } from '@mantine/core';
import { useMedplum, AsyncAutocomplete } from '@medplum/react';
import { IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import { buildGraphQLQuery, getResourcesFromResponse, HeaderSearchTypes, SearchGraphQLResponse, toKey, toOption } from '../../../react/src/AppShell/HeaderSearchInput';
import { useCallback, useEffect, useState, useRef } from 'react';
import { getPractitionerActivityMetrics, getLoginAttemptsMetrics } from '../utils/util';
import Overview from './Overview';

interface PractitionerActivityData {
  practitioner: string;
  activity: string;
  description: string;
  date: string;
}

interface LoginDetail {
  authTime: string;
  browserUsed: string;
  address: string;
}

interface LoginAttemptData {
  practitioner: string;
  email: string;
  recentLogin: string;
  loginDetails: LoginDetail[];
}

const practitionerActivityTableFields = ['practitioner', 'recentActivity', 'description', 'date'];
const loginAttemptTableFields = ['practitioner', 'email', 'browserUsed', 'recentLogin'];

const PractitionerActivityTableRows = ({ data }: { data: PractitionerActivityData[] }) => (
  <tbody className='payment-status-table-body'>
    {data.length > 0 ? data.map((resource, index) => (
      <tr key={index} style={{ border: '1px solid #ebeae9' }}>
        {practitionerActivityTableFields.map((field, i) => (
          <td key={`cell-${index}-${i}`}
          >
            {renderPractitionerActivityValue(resource, field)}</td>
        ))}
      </tr>
    )) : <tr><td colSpan={practitionerActivityTableFields.length + 1}>No data available</td></tr>}
  </tbody>
);

//login attempts table expand collapse row
const LoginTableRows = ({ data }: { data: LoginAttemptData[] }) => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const handleRowClick = (index: number) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <tbody className='login-attempts-table-body'>
      {data.length > 0 ? data.map((resource, index) => (
        <React.Fragment key={index}>
          <tr key={`row-${index}`} style={{ border: '1px solid #ebeae9' }} onClick={() => handleRowClick(index)}
            className={expandedRow === index ? 'expanded-row' : ''}
          >
            {loginAttemptTableFields.map((field, i) => (
              <td
                key={`cell-${index}-${i}`}
              >
                {renderLoginAttemptValue(resource, field)}
              </td>
            ))}
            <td key={`expand-icon-${index}`}>
              {resource.loginDetails.length > 0 ? (
                expandedRow === index ? <IconChevronUp height='20' /> : <IconChevronDown height='20' />
              ) : (
                <div className="h-5 w-5" />
              )}
            </td>
          </tr>
          {expandedRow === index && resource.loginDetails.length > 0 && (
            <ExpandedRowContent key={`expanded-${index}`} resource={resource} />
          )}
        </React.Fragment>
      )) : <tr><td colSpan={loginAttemptTableFields.length + 1}>No data available</td></tr>}
    </tbody>
  );
};

const ExpandedRowContent = ({ resource }: { resource: LoginAttemptData }) => (
  <tr>
    <td colSpan={loginAttemptTableFields.length + 1} style={{ padding: '5px 1rem 1rem' }}>
      <Table className='ex-col-div'>
        <thead>
          <tr>
            <th>IP Address</th>
            <th>Date & Time</th>
            <th>Browser</th>
          </tr>
        </thead>
        <tbody>
          {resource.loginDetails.map((detail, i) => (
            <tr key={i}>
              <td>{detail.address}</td>
              <td>{detail.authTime}</td>
              <td>{detail.browserUsed}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </td>
  </tr>
);

const renderPractitionerActivityValue = (data: PractitionerActivityData, field: string) => {
  if (!data) return '-';

  const date = new Date(data.date);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  switch (field) {
    case 'practitioner':
      return data.practitioner || '-';
    case 'recentActivity':
      return data.activity || '-';
    case 'description':
      return data.description || '-';
    case 'date':
      return data.date ? (
        <>
          {formattedDate} &nbsp;
          {formattedTime}
        </>
      ) : '-';
    default:
      return '-';
  }
};

const renderLoginAttemptValue = (data: LoginAttemptData, field: string) => {
  if (!data) return '-';
  switch (field) {
    case 'practitioner':
      return data.practitioner || '-';
    case 'email':
      return data.email || '-';
    case 'browserUsed':
      return data.loginDetails.length > 0 ? data.loginDetails[0].browserUsed || '-' : '-';
    case 'recentLogin':
      return data.recentLogin || '-';
    default:
      return '-';
  }
};

const PractitionerActivityTable = () => (
  <thead>
    <tr>
      {practitionerActivityTableFields.map((field, index) => (
        <th key={index}
          style={(field === 'date' || field === 'practitioner') ? { width: '180px' } : field === 'recentActivity' ? { width: '210px' } : {}}
        >
          <Group position="apart" noWrap>
            <Text weight={500} size="sm">
              {field === 'practitioner' ? 'Practitioner' :
                field === 'recentActivity' ? 'Recent Activity' :
                  field === 'description' ? 'Description' :
                    field === 'date' ? 'Date & Time' :
                      ''}
            </Text>
          </Group>
        </th>
      ))}
      <th></th>
    </tr>
  </thead>
);

const LoginTableHeaders = () => (
  <thead>
    <tr>
      {loginAttemptTableFields.map((field, index) => (
        <th key={index}>
          <Group position="apart" noWrap>
            <Text weight={500} size="sm">
              {field === 'practitioner' ? 'Practitioner' :
                field === 'email' ? 'Email' :
                  field === 'browserUsed' ? 'Browser' :
                    field === 'recentLogin' ? 'Last Login' :
                      ''}
            </Text>
          </Group>
        </th>
      ))}
      <th></th>
    </tr>
  </thead>
);

function UserActivity() {
  const medplum = useMedplum();
  const [loadingNextPractitionerActivity, setLoadingNextPractitionerActivity] = useState<boolean>(false);
  const [loadingPrevPractitionerActivity, setLoadingPrevPractitionerActivity] = useState<boolean>(false);
  const [activityTableLoading, setActivityTableLoading] = useState<boolean>(true);
  const [selectedPractitionerSearchId, setselectedPractitionerSearchId] = useState<string | null>(null);
  const [selectedLoginPractitionerSearchId, setselectedLoginPractitionerSearchId] = useState<string | null>(null);
  const [practitionerActivityData, setPractitionerActivityData] = useState<PractitionerActivityData[]>([]);
  const resourceID = medplum.getActiveLogin()?.profile?.reference;
  const parts: any = resourceID?.split('/');
  const adminId = parts?.[1];
  const skipEffect = useRef(false);
  const skipLoginEffect = useRef(false);
  const practitionerActivityPerPage = 10;
  const [practitionerActivityOffset, setPractitionerActivityOffset] = useState<number>(0);
  const [loginTableLoader, setLoginTableLoader] = useState<boolean>(true);
  const loginAttemptsPerPage = 10;
  const [loginAttemptsOffset, setLoginAttemptsOffset] = useState<number>(0);
  const [loginLoadingNext, setLoginLoadingNext] = useState<boolean>(false);
  const [loginLoadingPrev, setLoginLoadingPrev] = useState<boolean>(false);
  const [loginAttemptsData, setLoginAttemptsData] = useState<LoginAttemptData[]>([]);

  // Add this function for practitioner loading search data
  const practitionerSearchloadData = useCallback(
    async (input: string, signal: AbortSignal): Promise<HeaderSearchTypes[]> => {
      const query = buildGraphQLQuery(input, 'practitioner', 20);
      const options = { signal };
      const response = (await medplum.graphql(query, undefined, undefined, options)) as SearchGraphQLResponse;
      return getResourcesFromResponse(response, input, 20);
    },
    [medplum]
  );

  const fetchPractitionerActivityData = useCallback(async (count: number, offset: number, adminId: string, selectedPractitionerSearchId?: string) => {
    try {
      const data = await getPractitionerActivityMetrics(medplum, count, offset, adminId, selectedPractitionerSearchId || '');
      setPractitionerActivityData(data || []);
    } catch (error) {
      console.error('Error fetching practitioner activity data:', error);
      setPractitionerActivityData([]);
    } finally {
      setActivityTableLoading(false);
      setLoadingNextPractitionerActivity(false);
      setLoadingPrevPractitionerActivity(false);
    }
  }, [medplum]);

  const handlePractitionerActivityPageChange = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      setLoadingNextPractitionerActivity(true);
      setPractitionerActivityOffset(practitionerActivityOffset + practitionerActivityPerPage);
    } else {
      setLoadingPrevPractitionerActivity(true);
      setPractitionerActivityOffset(Math.max(practitionerActivityOffset - practitionerActivityPerPage, 0));
    }
  };

  useEffect(() => {
    if (skipEffect.current) {
      skipEffect.current = false;
      return;
    }
    setActivityTableLoading(true);
    fetchPractitionerActivityData(practitionerActivityPerPage, practitionerActivityOffset, adminId, selectedPractitionerSearchId?.toString());
  }, [fetchPractitionerActivityData, practitionerActivityOffset, adminId, selectedPractitionerSearchId]);

  const handlePractitionerSearch = useCallback(
    async (item: HeaderSearchTypes[]): Promise<void> => {
      const id = item.length > 0 ? item[0].id ?? null : null;
      setselectedPractitionerSearchId(id);
      setPractitionerActivityOffset(0);
      skipEffect.current = true;
      fetchPractitionerActivityData(practitionerActivityPerPage, 0, adminId, id?.toString());
    },
    [fetchPractitionerActivityData, practitionerActivityPerPage, practitionerActivityOffset, adminId, selectedPractitionerSearchId]
  );

  const fetchLoginAttemptsData = useCallback(async (count: number, offset: number, selectedLoginPractitionerSearchId?: string) => {
    setLoginTableLoader(true);
    try {
      const data = await getLoginAttemptsMetrics(medplum, count, offset, selectedLoginPractitionerSearchId || '');
      setLoginAttemptsData(data || []);
    } catch (error) {
      console.error('Error fetching login attempts data:', error);
      setLoginAttemptsData([]);
    } finally {
      setLoginTableLoader(false);
      setLoginLoadingNext(false);
      setLoginLoadingPrev(false);
    }
  }, [medplum]);

  //graphQL query for practitioner search for login attempts table
  const handleLoginPractitionerSearch = useCallback(
    async (item: HeaderSearchTypes[]): Promise<void> => {
      const id = item.length > 0 ? item[0].id ?? null : null;
      setselectedLoginPractitionerSearchId(id);
      skipLoginEffect.current = true;
      setLoginAttemptsOffset(0);
      fetchLoginAttemptsData(loginAttemptsPerPage, 0, id?.toString());
    },
    [fetchLoginAttemptsData, loginAttemptsPerPage, loginAttemptsOffset, selectedLoginPractitionerSearchId]
  );

  const loginPractitionerSearchloadData = useCallback(
    async (input: string, signal: AbortSignal): Promise<HeaderSearchTypes[]> => {
      const query = buildGraphQLQuery(input, 'practitioner', 20);
      const options = { signal };
      const response = (await medplum.graphql(query, undefined, undefined, options)) as SearchGraphQLResponse;
      return getResourcesFromResponse(response, input, 20);
    },
    [medplum]
  );

  const handleLoginAttemptsPageChange = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      setLoginLoadingNext(true);
      setLoginAttemptsOffset(loginAttemptsOffset + loginAttemptsPerPage);
    } else {
      setLoginLoadingPrev(true);
      setLoginAttemptsOffset(Math.max(loginAttemptsOffset - loginAttemptsPerPage, 0));
    }
  };

  useEffect(() => {
    if (skipLoginEffect.current) {
      skipLoginEffect.current = false;
      return;
    }
    fetchLoginAttemptsData(loginAttemptsPerPage, loginAttemptsOffset, selectedLoginPractitionerSearchId?.toString());
  }, [fetchLoginAttemptsData, loginAttemptsOffset, selectedLoginPractitionerSearchId]);


  return (
    <Box>
      {/* Overview section */}
      <Overview />

      {/* Login attempts by Practitioner section */}
      <Paper m="lg" p="xs" sx={{ border: '1px solid #EAECF0' }}>
        <Grid sx={{ justifyContent: 'space-between', margin: '8px' }}>
          <Grid.Col span={6}>
            <Text align="left" size={18} fw={600} ml={10} pb={10}>Practitioner Login History</Text>
          </Grid.Col>
          <Grid.Col span={6} sx={{ textAlign: 'end' }}>
            <Button className='download-btn'>
              <img src="../../../../img/dashboard/download.svg" alt="Download" />
              <Text pl={5} size={14} color='#344054' fw={600}>Download Report</Text>
            </Button>
          </Grid.Col>
        </Grid>
        <Grid className='sub-options-div'>
          <Grid.Col span={3} className='end-align'>
            <Group className='sub-search-icon'>
              <AsyncAutocomplete
                size="sm"
                radius="md"
                icon={<IconSearch size={20} />}
                placeholder={'Search Practitioner'}
                disabled={loginTableLoader}
                toKey={toKey}
                toOption={toOption}
                onChange={handleLoginPractitionerSearch}
                loadOptions={loginPractitionerSearchloadData}
                maxSelectedValues={0}
                clearSearchOnChange
                clearable={true}
                mt={5}
                sx={{
                  input: {
                    width: '200px',
                    paddingTop: '6px',
                    marginTop: '-3px',
                    transition: 'none',
                    '&:focus-within': {
                      width: '200px',
                    },
                  },
                }}
              />
            </Group>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col lg={12} sx={{ overflow: 'auto' }}>
            {loginTableLoader ? (
              <div className='loader-div'><Loader size={34} /></div>
            ) : (
              <Table className='table-structure'>
                <LoginTableHeaders />
                <LoginTableRows data={loginAttemptsData} />
              </Table>
            )}
          </Grid.Col>
        </Grid>
        <Grid justify="center" mt="md" mb={6}>
          <Button
            onClick={() => handleLoginAttemptsPageChange('prev')}
            disabled={loginLoadingPrev || loginAttemptsOffset === 0 || loginLoadingNext}
            className='pagination-btn'
          >
            {loginLoadingPrev ? <Loader size="xs" /> : 'Previous'}
          </Button>
          <Button
            onClick={() => handleLoginAttemptsPageChange('next')}
            disabled={loginLoadingPrev || loginLoadingNext || loginAttemptsData.length < loginAttemptsPerPage || loginTableLoader}
            className='pagination-btn'
          >
            {loginLoadingNext ? <Loader size="xs" /> : 'Next'}
          </Button>
        </Grid>
      </Paper>
      {/* Login attempts by Practitioner section end*/}

      {/* Activity by Practitioners section */}
      <Paper m="lg" p="xs" sx={{ border: '1px solid #EAECF0' }}>
        <Grid sx={{ justifyContent: 'space-between', margin: '8px' }}>
          <Grid.Col span={6}>
            <Text align="left" size={18} fw={600} ml={10} pb={10}>Activity by Practitioners</Text>
          </Grid.Col>
          <Grid.Col span={6} sx={{ textAlign: 'end' }}>
            <Button className='download-btn'>
              <img src="../../../../img/dashboard/download.svg" alt="Download" />
              <Text pl={5} size={14} color='#344054' fw={600}>Download Report</Text>
            </Button>
          </Grid.Col>
        </Grid>
        <Grid className='pract-actions'>
          <Grid.Col span={3}>
            <AsyncAutocomplete
              size="sm"
              radius="md"
              icon={<IconSearch size={20} />}
              placeholder={'Search Practitioner'}
              disabled={activityTableLoading}
              toKey={toKey}
              toOption={toOption}
              onChange={handlePractitionerSearch}
              loadOptions={practitionerSearchloadData}
              maxSelectedValues={0}
              clearSearchOnChange
              clearable={true}
              mt={5}
              sx={{
                input: {
                  width: '200px',
                  paddingTop: '6px',
                  marginTop: '-3px',
                  transition: 'none',
                  '&:focus-within': {
                    width: '200px',
                  },
                },
              }}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col lg={12} sx={{ overflow: 'auto' }}>
            {activityTableLoading ? (
              <div className='loader-div'><Loader size={34} /></div>
            ) : (
              <Table className='feedback_table'>
                <PractitionerActivityTable />
                <PractitionerActivityTableRows data={practitionerActivityData} />
              </Table>
            )}
          </Grid.Col>
        </Grid>
        <Grid justify="center" mt="md" mb={6}>
          <Button
            onClick={() => handlePractitionerActivityPageChange('prev')}
            disabled={loadingPrevPractitionerActivity || loadingNextPractitionerActivity || practitionerActivityOffset === 0}
            className='pagination-btn'
          >
            {loadingPrevPractitionerActivity ? <Loader size="xs" /> : 'Previous'}
          </Button>
          <Button
            onClick={() => handlePractitionerActivityPageChange('next')}
            disabled={loadingPrevPractitionerActivity || loadingNextPractitionerActivity || practitionerActivityData.length < practitionerActivityPerPage}
            className='pagination-btn'
          >
            {loadingNextPractitionerActivity ? <Loader size="xs" /> : 'Next'}
          </Button>
        </Grid>
      </Paper>
    </Box>
  );
}

export default UserActivity
