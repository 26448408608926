import { Box, Table, Text, createStyles } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import axios from '../utils/axios';
import { useMedplum } from '@medplum/react';
import { useParams } from 'react-router-dom';

export const DocumentType = {
  ReferralLetter: 'Referral',
  ProgressReport: 'Progress Report',
};

const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: '100%',
    overflow: 'auto',
    textAlign: 'left',
    marginBottom: '20px',
  },

  tr: {
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    },
  },

  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

export function SubscriptionDetails(): JSX.Element | null {
  const medplum = useMedplum();
  const practitionerID = useParams().id;
  const { classes } = useStyles();
  const [dataList, setDataList] = useState<any>([]);
  const [loadData, setLoadData] = useState(true);
  const [currentPage] = useState(1);

  const getPaymentHistory = async (): Promise<any> => {
    const token = medplum.getAccessToken();
    try {
      const response = await axios.get(
        `/intg/get-practitioner-payment-history`,
        {
          headers: {
            Accept: 'application/fhir+json',
            Authorization: 'Bearer ' + token,
          },
          params: {
            practitionerId: practitionerID,
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    getPaymentHistory()
      .then((response: any) => {
        setDataList(response);
        setLoadData(false);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  }, [practitionerID, loadData, currentPage]);

  return (
    <Box className="resource-panel" mb="50px">
      <div className="resource-list">
        <div>
          <Table>
            <thead>
              <tr>
                <th className="table-heading font-dark">Subcription Plan</th>
                <th className="table-heading font-dark">Amount Paid</th>
                <th className="table-heading font-dark">Payment Status</th>
                <th className="table-heading font-dark">Subscription Period</th>
              </tr>
            </thead>
            <tbody>
              {dataList.length > 0 ? (
                dataList.map((data: any) => (
                  <tr className={classes.tr}>
                    <td>{data.plan}</td>
                    <td>${data.amount}</td>
                    <td>{data.paymentStatus}</td>
                    <td>{data.startDate} - {data.endDate}</td>
                  </tr>
                ))
              ) : (
                <tr key={0} className={classes.tr}>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    <Text>No Data</Text>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

        </div>
      </div>
    </Box>
  );
}
