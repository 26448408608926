import React, { useState, useCallback, useContext } from 'react';
import classNames from 'classnames';
import AvatarActionContext from '../context/avatar-context';
import MediaContext from '../../context/media-context';
import { getAntdItem } from './video-footer-utils';
import { IconCheck, IconPlus } from '@tabler/icons-react';
import { Button, Menu, Slider } from '@mantine/core';
interface AvatarMoreProps {
  className?: string;
  userId: number;
  isHover: boolean;
}
const AvatarMore = (props: AvatarMoreProps) => {
  const { userId, isHover } = props;
  const { avatarActionState, dispatch } = useContext(AvatarActionContext);
  const { mediaStream } = useContext(MediaContext);
  const [isDropdownVisible, setIsDropdownVisbile] = useState(false);
  const [isControllingRemoteCamera, setIsControllingRemoteCamera] = useState(false);
  const actionItem = avatarActionState[`${userId}`];
  const menu = [];
  if (actionItem) {
    if (actionItem.localVolumeAdjust.enabled) {
      menu.push(
        getAntdItem('Adjust volume locally', 'volume', actionItem?.localVolumeAdjust.toggled && <IconCheck />)
      );
    }
    if (actionItem?.farEndCameraControl.enabled) {
      menu.push(getAntdItem(isControllingRemoteCamera ? 'Give up camera control' : 'Control far end camera', 'farend'));
    }
  }
  if (actionItem?.videoResolutionAdjust.enabled) {
    menu.push(
      getAntdItem(
        'Subscribe other video resolution',
        'subscribeVideoQuality',
        actionItem?.videoResolutionAdjust.toggled && <IconCheck />
      )
    );
  }
  const onSliderChange = useCallback(
    (value: any) => {
      mediaStream?.adjustUserAudioVolumeLocally(userId, value);
      dispatch({ type: 'update-local-volume', payload: { userId, volume: value } });
    },
    [userId, mediaStream, dispatch]
  );
  const onDropDownVisibleChange = useCallback((visible: boolean) => {
    setIsDropdownVisbile(visible);
  }, []);
  const onMenuItemClick = useCallback(
    ({ key }: { key: string }) => {
      if (key === 'volume') {
        dispatch({ type: 'toggle-local-volume', payload: { userId } });
      } else if (key === 'farend') {
        dispatch({ type: 'toggle-far-end-camera-control', payload: { userId } });
        if (isControllingRemoteCamera) {
          mediaStream?.giveUpFarEndCameraControl(userId);
          dispatch({ type: 'set-is-controlling-remote-camera', payload: false });
        } else {
          mediaStream?.requestFarEndCameraControl(userId);
        }
        setIsControllingRemoteCamera(!isControllingRemoteCamera);
      } else if (key === 'subscribeVideoQuality') {
        dispatch({ type: 'toggle-video-resolution-adjust', payload: { userId } });
      }
      setIsDropdownVisbile(false);
    },
    [mediaStream, userId, isControllingRemoteCamera, dispatch]
  );

  const menuItems = menu.map((item) => (
    <Menu.Item key={item.key} onClick={() => onMenuItemClick(item)}>
      {item.label}
    </Menu.Item>
  ));
  
  return (
    <>
      {menu.length > 0 && (
        <Menu
        onOpen={() => onDropDownVisibleChange(true)}
        onClose={() => onDropDownVisibleChange(false)}
        position="bottom-end"  // Similar to 'bottomRight' in antd
        withArrow
      >
        <Menu.Target>
          <Button
            leftIcon={<IconPlus />}
            className={classNames('more-button', {
              'more-button-active': isHover || isDropdownVisible
            })}
            size="small"
          />
        </Menu.Target>
  
        <Menu.Dropdown>
          {menuItems}
        </Menu.Dropdown>
      </Menu>
      )}
      {isHover && actionItem?.localVolumeAdjust?.enabled && actionItem?.localVolumeAdjust?.toggled && (
        <div className={classNames('avatar-volume')}>
          <label>Volume:</label>
          <Slider
            marks={[
              { value: 0, label: '0' },
              { value: 100, label: '100' }
            ]}
            defaultValue={100}
            value={actionItem.localVolumeAdjust.volume}
            onChange={onSliderChange}
          />
        </div>
      )}
    </>
  );
};

export default AvatarMore;
