import React from 'react';
import { Modal } from '@mantine/core';

type CustomModalProps = {
  title: string;
  content: string;
  okText?: string;
  cancelText?: string;
  onOk: () => void;
  onCancel: () => void;
};

export const CustomModal: React.FC<CustomModalProps> = ({ title, content, okText, cancelText, onOk, onCancel }) => {
  return (
    <Modal opened={true} onClose={onCancel} title={title}>
      <p>{content}</p>
      <div>
        <button onClick={onOk}>{okText || 'OK'}</button>
        <button onClick={onCancel}>{cancelText || 'Cancel'}</button>
      </div>
    </Modal>
  );
};
