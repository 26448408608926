/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useState, createElement } from "react";
import { getStripeDetails } from '../utils/util';

export const StripePricingTable = () => {
    const [stripeDetails, setStripeDetails] = useState({
      pricing_table_id: "",
      publishable_key: "",
      monthly_price: "",
      yearly_price: ""
    });

    useEffect(() => {
        getStripeDetails()
            .then((response) => {
                setStripeDetails(response);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      document.body.appendChild(script);
      
      return () => {
        document.body.removeChild(script);
      };
  
    }, []);
  
    return createElement("stripe-pricing-table", {
      "pricing-table-id": stripeDetails.pricing_table_id,
      "publishable-key": stripeDetails.publishable_key,
    });
  
  };