import { Alert, Center } from '@mantine/core';
import { Document } from '@medplum/react';
import { IconAlertCircle } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { isRegisterEnabled } from './config';
import { RegisterForm } from './questionnaire/RegisterForm';
import { useAppContext } from './AppProvider';

export function RegisterPage(): JSX.Element | null {
  const {registerPageHeight} = useAppContext();
  const [pageHeight, setPageHeight] = useState<string | number>('100vh');

  useEffect(() => {
    setPageHeight(registerPageHeight);
  }, [registerPageHeight, pageHeight]);

  if (!isRegisterEnabled()) {
    return (
      <Document width={450}>
        <Alert icon={<IconAlertCircle size={16} />} title="New projects disabled" color="red">
          New projects are disabled on this server.
        </Alert>
      </Document>
    );
  }

  return (
    <div>
      <Center className="register" style={{height: `${pageHeight}`}}>
        <RegisterForm />
      </Center>
    </div>
  );
}
