import React, { useState } from 'react';
import { Button, Modal, Group, Radio } from '@mantine/core';

interface RoleSelectionPopupProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (selectedRole: string) => void;
    submitButtonLabel: string;
    cancelButtonLabel: string;
}

export const practitionerMap: Record<string, string> = {
    "prescriber": "Psychiatry",//$99
    "therapist": "Counselor",//$79
    "psychological testing": "Psychological Testing"
};

const RoleSelectionPopup: React.FC<RoleSelectionPopupProps> = ({ isOpen, onClose, onSubmit, submitButtonLabel, cancelButtonLabel }) => {
    const [selectedRole, setSelectedRole] = useState('Prescriber');

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(selectedRole);
    };

    const getRoleLabel = (role: string) => {
        return practitionerMap[role.toLowerCase()] || role;
    };

    return (
        <Modal opened={isOpen} onClose={onClose} centered className='roleSelectionPopup'>
            <h2 className='inviteHeader'>Please choose your user role</h2>
            <form onSubmit={handleFormSubmit}>
                <div className="padded-container">
                    <Group>
                        <Radio
                            className='roleRadio'
                            value="Prescriber"
                            label={getRoleLabel('Prescriber')}
                            checked={selectedRole === 'Prescriber'}
                            onChange={() => setSelectedRole('Prescriber')}
                        />
                        <Radio
                            className='roleRadio'
                            value="Therapist"
                            label={getRoleLabel('Therapist')}
                            checked={selectedRole === 'Therapist'}
                            onChange={() => setSelectedRole('Therapist')}
                        />
                    </Group>
                </div>
                <Group position="right" mt="md">
                    <Button onClick={onClose} className='cancelButton'>
                        {cancelButtonLabel}
                    </Button>
                    <Button type="submit" className='defaultBg'>
                        {submitButtonLabel}
                    </Button>
                </Group>
            </form>
        </Modal>
    );
};

export default RoleSelectionPopup;
