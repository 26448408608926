import React, { useState } from 'react';
import { Button, Center, Group, Loader, Modal, TextInput, Textarea, Title } from '@mantine/core';
import { Form, useMedplum } from '@medplum/react';
import { toast } from 'react-toastify';
import { sendDetailsOnEmail } from '../utils/util';

interface EmailBoxProps {
  isOpen: boolean;
  onClose: () => void;
  documentReferenceId: string;
  defaultEmail?: string;
}

const EmailBox: React.FC<EmailBoxProps> = ({ isOpen, onClose, documentReferenceId, defaultEmail }) => {
  const medplum = useMedplum();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (formData: any) => {
    formData['documentReferenceId'] = documentReferenceId;
    setIsLoading(true);

    sendDetailsOnEmail(medplum, formData)
      .then(() => {
        toast.success('Email sent successfully');
        onClose();
        setIsLoading(false);
        close();
      })
      .catch((error: any) => {
        console.error('Error:', error);
        toast.error("Failed to send email");
        setIsLoading(false);
      });
  };

  return (
    <Modal opened={isOpen} onClose={onClose} className="context-modal" withCloseButton={false}>
      <Center style={{ textAlign: 'center' }}>
        <Title fs="24px" style={{ lineHeight: '40px' }} fw={700} mb={20}>
          Send Email
        </Title>
      </Center>
      <Form onSubmit={handleSubmit}>
        <Center display="block">
          <TextInput type="email" name="email" label="Email" defaultValue={defaultEmail} required width={100}/>
          <TextInput type="text" name="subject" label="Subject" required width={100} />
          <Textarea name="message" label="Message" required minRows={5} />
        </Center>
        <Group position="right" mt="lg">
          <Button className="btn-danger" px={'lg'} style={{ borderRadius: '25px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="btn-success"
            mr={10}
            px={'lg'}
            style={{ borderRadius: '25px' }}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <Loader className="loading-success" color="#fff" size={25} /> : 'Confirm'}
          </Button>
        </Group>
      </Form>
    </Modal>
  );
};

export default EmailBox;
