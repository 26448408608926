import React, { useEffect, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { Loading } from '@medplum/react';
import produce from 'immer';
import { showNotification } from '@mantine/notifications';
import ZoomMediaContext from '../context/media-context';
import ZoomContext from '../context/zoom-context';
import { Box, Group, TextInput, Button, Tooltip, Paper } from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import VideoSingle from './video-single';
import { MediaStream } from '../index-types';
import { useAppContext } from '../../../../AppProvider';
// Define your context

interface ZoomMeetingProps {
  meetingArgs: {
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
    enforceVB?: string;
    customerJoinId?: string;
    lang?: string;
    useVideoPlayer?: string;
  };
}

// Media shape and reducer for managing media state
const mediaShape = {
  audio: { encode: false, decode: false },
  video: { encode: false, decode: false },
  share: { encode: false, decode: false },
};

const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode':
      draft.audio.encode = action.payload;
      break;
    case 'audio-decode':
      draft.audio.decode = action.payload;
      break;
    case 'video-encode':
      draft.video.encode = action.payload;
      break;
    case 'video-decode':
      draft.video.decode = action.payload;
      break;
    case 'share-encode':
      draft.share.encode = action.payload;
      break;
    case 'share-decode':
      draft.share.decode = action.payload;
      break;
    case 'reset-media':
      Object.assign(draft, { ...mediaShape });
      break;
    default:
      break;
  }
}, mediaShape);

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
    logClient: any | undefined;
  }
}

function ZoomMeeting(props: ZoomMeetingProps): JSX.Element {
  const {
    meetingArgs: {
      topic,
      signature,
      name,
      password,
      webEndpoint: webEndpointArg,
      enforceGalleryView,
      enforceVB,
      // useVideoPlayer,
    },
  } = props;

  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const [isFailover, setIsFailover] = useState(false);
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const { patientZoomUrl } = useAppContext();
  const [copied, setCopied] = useState<string | null>(null);

  const zmClient = useContext(ZoomContext);
  const webEndpoint = webEndpointArg || 'zoom.us';

  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
  const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
  const vbWithoutSAB = Number(enforceVB) === 1 && !window.crossOriginIsolated;

  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        await zmClient.init('en-US', `Global`, {
          webEndpoint,
          enforceMultipleVideos: galleryViewWithoutSAB,
          enforceVirtualBackground: vbWithoutSAB,
          stayAwake: true,
          patchJsMedia: true,
          leaveOnPageUnload: false,
        });

        setLoadingText('Joining the session...');
        await zmClient.join(topic, signature, name);
        const stream: any = zmClient.getMediaStream();
        setMediaStream(stream);
      } catch (error: any) {
        showNotification({
          title: 'Error',
          message: `Failed to join meeting: ${error.reason || error}`,
          color: 'red',
        });
        console.error('Failed to join meeting:', error);
      } finally {
        setLoading(false);
      }
    };
    init().catch(err => console.error(err));

    return () => {
      ZoomVideo.destroyClient();
    };
  }, [zmClient, topic, signature, name, password, webEndpoint, galleryViewWithoutSAB]);

  const onConnectionChange = useCallback((payload: any) => {
    switch (payload.state) {
      case ConnectionState.Reconnecting:
        setLoading(true);
        setIsFailover(true);
        setLoadingText(payload.reason === ReconnectReason.Failover
          ? 'Session Disconnected, Try to reconnect'
          : `Joining ${payload.subsessionName}...`);
        break;
      case ConnectionState.Connected:
        setLoading(false);
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();
        console.log('getSessionInfo', zmClient.getSessionInfo());
        break;
      case ConnectionState.Closed:
        dispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          showNotification({
            title: 'Meeting ended',
            message: 'This meeting has been ended by the host',
            color: 'red',
          });
        }
        break;
      default:
        break;
    }
  }, [isFailover, zmClient]);

  const onMediaSDKChange = useCallback((payload: any) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);

  const copyToClipboard = async (text: string, type: string): Promise<void> => {
    await navigator.clipboard.writeText(text).then(() => {
      setCopied(type);
      setTimeout(() => setCopied(null), 600);
    });
  };

  useEffect(() => {
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);
    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange]);

  return (
    <Box className="ZoomMeeting">
      {loading && <Group> <Loading /> {loadingText}</Group>}
      {!loading && (
        <ZoomMediaContext.Provider value={mediaContext && mediaContext}>
          <VideoSingle />
        </ZoomMediaContext.Provider>
      )}
      <Paper p="10px">
        <TextInput
          label="Patient's Session URL"
          value={patientZoomUrl}
          readOnly
          rightSection={
            <Tooltip label={copied === 'url' ? 'Copied!' : 'Copy to clipboard'} position="top-end" withArrow>
              <Button
                variant="subtle"
                color={copied === 'url' ? 'teal' : 'blue'}
                onClick={() => copyToClipboard(patientZoomUrl || '', 'url')}
                size="xs"
              >
                {copied === 'url' ? <IconCheck size={16} /> : <IconCopy size={16} />}
              </Button>
            </Tooltip>
          }
          sx={{
            input: {
              backgroundColor: '#F4F4F4',
              border: 'none'

            },
          }}
        />
      </Paper>
    </Box>
  );
}

export default ZoomMeeting;
