import { ResourceType } from '@medplum/fhirtypes';
import { DescriptionList, DescriptionListEntry, ResourceTable, useMedplum, useMedplumProfile, useResource } from '@medplum/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import PractitionerDetailsPage from './PractitionerDetailsPage';

export function DetailsPage(): JSX.Element | null {
  const { resourceType, id } = useParams() as { resourceType: ResourceType; id: string };
  const resource: any = useResource({ reference: resourceType + '/' + id });
  const medplum = useMedplum();
  const profile = useMedplumProfile()
  
  if (!resource || !profile) {
    return null;
  }
  const showPractitionerDetails = (resourceType === 'Practitioner' && medplum.isSuperAdmin() && id !== profile.id) || (id === profile.id && !medplum.isSuperAdmin());
  
  return (
    <div className="resource-panel">
      <div className="resource-details">
        <div className="resource-header">
          <p>{`${resourceType?.toUpperCase()}`} DETAILS</p>
        </div>
        {showPractitionerDetails ? (
          <PractitionerDetailsPage />
        ) : (
          <>
            <ResourceTable value={resource} />
            {resourceType === 'Patient' && (
              <DescriptionList>
                <DescriptionListEntry term="Mental Health Diagnoses">
                  {resource?.extension?.find(
                    (ext: { url: string }) => ext.url === '/intg/structure/extensions/mentalHealthDiagnoses'
                  )?.valueString
                    ? JSON.parse(
                        resource.extension.find(
                          (ext: { url: string }) => ext.url === '/intg/structure/extensions/mentalHealthDiagnoses'
                        ).valueString
                      ).map((data: any, index: number) => (
                        <div key={index}>
                          <ul style={{ padding: '0 15px' }}>
                            <li>{data?.label}</li>
                          </ul>
                        </div>
                      ))
                    : '-'}
                </DescriptionListEntry>
                <DescriptionListEntry term="Other Diagnoses">
                  {resource?.extension?.find(
                    (ext: { url: string }) => ext.url === '/intg/structure/extensions/otherDiagnoses'
                  )?.valueString
                    ? JSON.parse(
                        resource.extension.find(
                          (ext: { url: string }) => ext.url === '/intg/structure/extensions/otherDiagnoses'
                        ).valueString
                      ).map((data: any, index: number) => (
                        <div key={index}>
                          <ul style={{ padding: '0 15px' }}>
                            <li>{data?.label}</li>
                          </ul>
                        </div>
                      ))
                    : '-'}
                </DescriptionListEntry>
                <DescriptionListEntry term="Race">
                  {resource?.extension?.find(
                    (ext: { url: string }) => ext.url === 'http://hl7.org/fhir/StructureDefinition/us-core-race'
                  )?.valueCodeableConcept?.coding?.[0]?.display || '-'}
                </DescriptionListEntry>
              </DescriptionList>
            )}
          </>
        )}
      </div>
    </div>
  );
}
