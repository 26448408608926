import { getDisplayString, getReferenceString } from '@medplum/core';
import { CodeableConcept, Identifier, Reference, Resource } from '@medplum/fhirtypes';
import { useResource } from '@medplum/react';
import React, { useEffect, useState } from 'react';
import { InfoBar } from './InfoBar';
import { Text } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { getPractitionerRole } from '../utils/util';

export interface ResourceHeaderProps {
  resource: Resource | Reference;
}

export const practitionerMap:  { [key: string]: string } = {
  "prescriber": "Psychiatry",
  "therapist": "Counselor",
  "psychological testing": "Psychological Testing"
};

export function ResourceHeader(props: ResourceHeaderProps): JSX.Element | null {
  const resource = useResource(props.resource);
  const medplum = useMedplum();
  const [specialtyCode, setSpecialtyCode] = useState<string | null>(null);

  useEffect(() => {
    if (resource?.resourceType === 'Practitioner' && resource.id) {
      const isSuperAdmin = resource.extension?.some(
        (ext) => ext.id === 'is-super-admin' && ext.valueString === 'true'
      );
  
      if (isSuperAdmin) {
        setSpecialtyCode('Admin');
      } else {
        const practitionerId = resource.id;
        getPractitionerRole(medplum, practitionerId)
          .then((response: any) => {
            let specialtyRole: string = response?.entry?.[0]?.resource?.code?.[0]?.coding?.[0]?.display?.toLowerCase() || '';

            if (specialtyRole && practitionerMap.hasOwnProperty(specialtyRole)) {
              specialtyRole = practitionerMap[specialtyRole];
            }

            setSpecialtyCode(specialtyRole || '');
          })
          .catch((error: any) => {
            console.error('Failed to get practitioner role:', error);
          });
      }
    }
  }, [resource, medplum]);
  

  if (!resource) {
    return null;
  }

  const entries: { key: string; value: string | React.ReactNode | undefined }[] = [
    { 
      key: 'Type', 
      value: <Text>{resource.resourceType === 'Practitioner' ? (specialtyCode || 'Practitioner') : resource.resourceType}</Text> 
    },
  ];

  function addEntry(key: string | undefined, value: string | undefined): void {
    if (key && value) {
      entries.push({ key, value });
    }
  }

  function addIdentifier(identifier: Identifier | undefined): void {
    if (identifier) {
      addEntry(identifier.system, identifier.value);
    }
  }

  function addConcept(key: string, concept: CodeableConcept[] | CodeableConcept | string[] | string | undefined): void {
    if (Array.isArray(concept)) {
      concept.forEach((c) => addConcept(key, c));
    } else if (typeof concept === 'string') {
      addEntry(key, concept);
    } else if (concept) {
      if (Array.isArray(concept.coding)) {
        addEntry(key, concept.coding.map((c) => c.display || c.code).join(', '));
      } else {
        addEntry(key, concept.text);
      }
    }
  }

  const name = getDisplayString(resource);
  if (name !== getReferenceString(resource)) {
    addEntry('Name', name);
  }

  if ('category' in resource) {
    addConcept('Category', resource.category);
  }

  if (resource.resourceType !== 'Bot' && 'code' in resource) {
    addConcept('Code', resource.code);
  }

  if ('identifier' in resource) {
    if (Array.isArray(resource.identifier)) {
      resource.identifier.forEach(addIdentifier);
    } else {
      addIdentifier(resource.identifier);
    }
  }

  if (entries.length === 1) {
    // If no other names or identifiers were found,
    // then at least show the resource ID
    entries.push({ key: 'ID', value: resource.id });
  }

  return (
    <InfoBar>
      {entries.map((entry) => (
        <InfoBar.Entry key={`${entry.key}-${entry.value}`}>
          <InfoBar.Key>{entry.key}</InfoBar.Key>
          <InfoBar.Value>
            {entry.key === 'Type'
              ? specialtyCode || entry.value
              : entry.value}
          </InfoBar.Value>
        </InfoBar.Entry>
      ))}
    </InfoBar>
  );
}
