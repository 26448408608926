/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Button, Box, Grid, AspectRatio, Accordion, Text } from '@mantine/core';
import classes from './Home.module.css';
import Footer from './footer/Footer';
import TopHeader from './header/TopHeader';
import { IconArrowLeft, IconArrowRight, IconPlus } from '@tabler/icons-react';
import Slider from 'react-slick';
import data from './Home.json';
import { VIDEO_URL, BANNER_VIDEO_URL } from './constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { StripePricingTable } from './StripePricingTable';
import { createStripeSsession, getStripeDetails } from '../utils/util';

function Home() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [monthlyPriceId, setMonthlyPriceId] = useState<string>('');
    // Unsed price id
    // const [yearlyPriceId, setYearlyPriceId] = useState<string>('');

    const CustomPrevArrow = (props: any) => (
        <button {...props} className="slick-arrow slick-prev">
            <IconArrowLeft style={{
                ...(window.innerWidth <= 600
                    ? {
                        marginLeft: '9rem',
                        width: '2rem',
                        height: '2rem',
                        backgroundColor: '#3077ca',
                        color: 'white',
                        borderRadius: '35px',
                        marginTop: '12rem'
                    }
                    : window.innerWidth > 600 && window.innerWidth <= 1023
                        ? {
                            marginTop: '17rem',
                            marginLeft: '20rem',
                            width: '3rem',
                            height: '3rem',
                            backgroundColor: '#3077ca',
                            color: 'white',
                            borderRadius: '35px',

                        }
                        : window.innerWidth >= 1024 && window.innerWidth <= 1200 ? {
                            marginLeft: '2rem',
                            width: '4rem',
                            height: '4rem',
                            backgroundColor: '#3077ca',
                            color: 'white',
                            borderRadius: '35px',
                        }
                            : {
                                backgroundColor: '#3077ca',
                                width: '4rem',
                                height: '4rem',
                                color: 'white',
                                borderRadius: '35px',
                                marginLeft: '13rem',
                                marginTop: '1rem'
                            })
            }} />
        </button>
    );

    const CustomNextArrow = (props: any) => (
        <button {...props} className="slick-arrow slick-next"
            style={{ right: 'unset' }}
        >
            <IconArrowRight style={{
                ...(window.innerWidth <= 600
                    ? {

                        width: '2rem',
                        height: '2rem',
                        backgroundColor: '#3077ca',
                        color: 'white',
                        borderRadius: '35px',
                        marginTop: '12rem',
                        marginLeft: '14rem'
                    }
                    : window.innerWidth > 600 && window.innerWidth <= 1023
                        ? {
                            marginRight: '-60rem',
                            marginTop: '18rem',
                            width: '3rem',
                            height: '3rem',
                            backgroundColor: '#3077ca',
                            color: 'white',
                            borderRadius: '35px',
                        }
                        : window.innerWidth >= 1024 && window.innerWidth <= 1200 ? {
                            backgroundColor: '#3077ca',
                            width: '4rem',
                            height: '4rem',
                            color: 'white',
                            borderRadius: '35px',
                            marginLeft: '58rem',
                            marginTop: '7px',

                        } :
                            {
                                backgroundColor: '#3077ca',
                                width: '4rem',
                                height: '4rem',
                                color: 'white',
                                borderRadius: '35px',
                                marginRight: '-138rem',
                                marginTop: '2rem'
                            })
            }} />
        </button>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };


    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {
        getStripeDetails()
            .then((response) => {
                setMonthlyPriceId(response.monthly_price);
                // setYearlyPriceId(response.yearly_price);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const items = data.accordionContent.map((item, index) => (
        <Accordion.Item key={index.toString()} value={item.value.toString()}>
            <Accordion.Control>
                <span className={classes.controlText}>{item.value}</span>
            </Accordion.Control>
            <Accordion.Panel>
                <div className={classes.description} dangerouslySetInnerHTML={{ __html: item.description }}></div>
            </Accordion.Panel>
        </Accordion.Item>
    ));

    const stripeSession = () => {
        const payload = {
            priceId: monthlyPriceId,
        }
        createStripeSsession(payload).then((res) => {
            console.log(res)
            window.location.href = res.sessionUrl;
        }).catch((err) => {
            console.log(err);
        }); 
    };

    return (
        <Box className={classes.boxStyle}>
            <TopHeader></TopHeader>
            <Grid className={classes.customGrid}>
                <Grid.Col span={12} md={6} style={{ paddingLeft: '5%' }}>
                    <h1 className={classes.headingOne}>PMHScribe</h1>
                    <p className={classes.paragraph}>by eRx.ai, Inc.</p>
                    <h2 className={classes.headingTwo}>Psychiatric Mental Health Artificial Intelligence Scribe</h2>
                    <h3 className={classes.headingThree}>Precision charting with PMHScribe: Where Psychiatric Mental Health expertise meets AI efficiency.</h3>
                </Grid.Col>

                <Grid.Col
                    span={12}
                    md={6}
                    className={classes.rightCol}
                >

                    <AspectRatio ratio={16 / 9}

                        className={classes.aspectRatio}
                    >
                        <video src={BANNER_VIDEO_URL} autoPlay loop muted playsInline className={classes.videoPlayer} controls />
                    </AspectRatio>

                </Grid.Col>
            </Grid>

            <Grid >
                <Grid.Col span={12} md={12} className={classes.parentClass}>
                    <Slider   {...settings} >
                        {data.sliderContent.map((content, index) => (
                            <div key={index} className={classes.sliderDiv}>
                                <h3 className={classes.sliderHeading}>{content.heading}</h3>
                                <p className={classes.sliderPara}>{content.paragraph}</p>
                            </div>
                        ))}
                    </Slider>

                    <style>
                        {`
                        .slick-next:before{
                            color: transparent;
                             opacity: 0;
                        }
                        .slick-next, .slick-prev{
                            z-index: 999 !important;
                        }
                          
                         `}
                    </style>

                </Grid.Col>

                <Grid.Col span={12} md={12} className={classes.preOrderGrid}>
                <Button className={classes.preOrderHereBtn} size="md" loaderPosition="center" sx={{ backgroundColor: '#3077ca !important' }} onClick={stripeSession}>PREORDER HERE</Button>
                </Grid.Col>

            </Grid>

            <Grid sx={{ justifyContent: 'center', marginBottom: '20px' }}>
                <Text fw={500} className={classes.stripePricing}>Our Pricing Plan</Text>
                <Grid.Col span={12} md={12} sx={{ paddingBottom: '5rem' }}>
                    <StripePricingTable />
                </Grid.Col>
            </Grid>

            <Grid className={classes.videoContainer}>
                <Grid.Col
                    span={12}
                    md={12}
                    className={!isMobile ? classes.videoArea : classes.videoAreaForMo}
                >
                    <AspectRatio ratio={16 / 9}

                        className={classes.aspectRatio}
                    >
                        <video src={VIDEO_URL} autoPlay loop muted playsInline className={classes.videoPlayer} controls />
                    </AspectRatio>
                </Grid.Col>
            </Grid>

            <Grid className={classes.faqsGrid}>
                <Grid.Col span={12} md={2}>
                    <h2 className={classes.customHeading}>FAQs</h2>
                </Grid.Col>
                <Grid.Col span={12} md={10} >
                    <Accordion
                        classNames={{
                            item: classes.customItem,
                            content: !isMobile ? classes.customAccordionContent : classes.customLeft,
                            control: classes.customAccordionControl,
                            chevron: classes.customAccordionChevron,
                        }}
                        defaultValue={"Is It Secure?"}
                        chevron={<IconPlus className={classes.icon} />}
                    >
                        {items}
                    </Accordion>
                </Grid.Col>

                <Grid.Col span={12} md={12} className={classes.gridCol}>
                <Button className={classes.startBtn} size="md" loaderPosition="center" sx={{ backgroundColor: '#8dc743 !important' }} onClick={stripeSession}>START NOW</Button>
                </Grid.Col>
            </Grid>

            <Footer></Footer>

        </Box>

    );
}

export default Home;
