/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react'
import { Anchor, Burger, Button, Divider, Drawer, Group, ScrollArea } from '@mantine/core';
import { LOGO_SRC, PMHS_URL } from '../constants';
import { useDisclosure } from '@mantine/hooks';
import classes from './TopHeader.module.css';
import { createStripeSsession, getStripeDetails } from '../../utils/util';

function Header() {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [monthlyPriceId, setMonthlyPriceId] = useState<string>('');

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {
        getStripeDetails()
            .then((response) => {
                setMonthlyPriceId(response.monthly_price);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const showNavigationLinks = () => {
        return window.innerWidth >= 768;
    };

    const renderPreOrderButton = () => (
        isMobile && (
            <Group h="100%" style={{ marginRight: 0 }}>
                <Burger color="white" opened={drawerOpened} onClick={toggleDrawer} />
            </Group>
        )
    );

    const stripeSession = () => {
        const payload = {
            priceId: monthlyPriceId,
        }
        createStripeSsession(payload).then((res) => {
            console.log(res)
            window.location.href = res.sessionUrl;
        }).catch((err) => {
            console.log(err);
        }); 
    }

    return (
        <div className={classes.stickyHeader}>
            <header className={classes.header}>
                <Anchor href="/" data-animation-role="header-element" className={`preFade fadeIn`}>
                    <img
                        src={LOGO_SRC}
                        alt="eRx.ai"
                        style={{ display: 'block', width: isMobile ? '80px' : 'auto' }}
                        loading="eager"
                        decoding="async"
                        data-loader="raw"
                        className={classes.logo}
                    />
                </Anchor>

                {showNavigationLinks() && (
                    <Group h="100%" fw={700} className={classes.centeredGroup}>
                        <Anchor href={PMHS_URL} className={`${classes.link} ${classes.whiteUnderline}`}> PMHScribe </Anchor>
                    </Group>
                )}

                <Group h="100%" className={classes.preOrderGroup}>
                    <Anchor href="/sign-up" className={classes.link}>
                        <Button color="orange" size="md" className={classes.btnStyle}>Start Now</Button>
                    </Anchor>

                </Group>

                {renderPreOrderButton()}
            </header>

            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size="100%"
                padding="md"
                zIndex={1000000}
                className={classes.drawer}
            >
                <ScrollArea mx="-md" >
                    <Divider my="sm" />
                    <Group align="center" className={classes.verticalCenter}>
                        <Anchor href="#" className={`${classes.responsiveLink} ${classes.homeLink}`}>
                            Home
                        </Anchor>
                        <Anchor href={PMHS_URL} className={classes.responsiveLink}>
                            PMHScribe
                        </Anchor>
                        <Button loaderPosition="center" color="orange" onClick={stripeSession}> Start Now</Button>
                    </Group>
                </ScrollArea>
            </Drawer>
        </div>
    )
}

export default Header
