import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createStripeSsession } from '../utils/util';
import { Box, Button, Checkbox, Grid, Group, Radio, TextInput } from '@mantine/core';
import { useMedplum } from '@medplum/react';

export const practitionerMap: Record<string, string> = {
    "prescriber": "Psychiatry",
    "therapist": "Counselor",
    "psychological testing": "Psychological Testing"
};

function NonNpiUserForm() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('Prescriber');
    const [startWithFreeTrial, setStartWithFreeTrial] = useState(true);
    const priceId = '';
    const medplum = useMedplum();

    const getRoleLabel = (role: string) => {
        return practitionerMap[role.toLowerCase()] || role; 
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const params = {
            firstName: queryParams.get('firstName'),
            lastName: queryParams.get('lastName'),
            email: queryParams.get('user'),
            role: queryParams.get('role')
        };

        if (params.firstName) setFirstName(params.firstName);
        if (params.lastName) setLastName(params.lastName);
        if (params.email) setEmail(params.email);
        if (params.role) setRole(params.role);
    }, []);


    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const payload = {
            priceId: priceId,
            isFreeTrial: startWithFreeTrial,
            role: role,
            firstName: firstName,
            lastName: lastName,
            email: email
        };
        sessionStorage.setItem('response_data', JSON.stringify(payload));
        try {
            const res = await createStripeSsession(payload);
            if (res && res.sessionUrl) {
                window.location.href = res.sessionUrl;
            } else {
                toast.error('Failed to create checkout session.', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while creating the checkout session.', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const handleFreeTrialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartWithFreeTrial(event.currentTarget.checked);
    };

    return (
        <Box className='invitePopup'>
            <Grid mb={20}>
                <Grid.Col span={12} pt={20} sx={{ textAlign: 'center' }}>
                    <div>
                        <img src="../../img/logo.png" alt="PMHScribe Logo" width="150" />
                    </div>
                </Grid.Col>
                <Grid.Col span={12} lg={4} className='gridDiv'>
                    <h2 style={{ textAlign: 'center' }}>Non-NPI User Registration</h2>
                    <form onSubmit={handleFormSubmit}>
                        <div className="name-inputs-row">
                            <TextInput
                                p={10}
                                label="First Name"
                                placeholder="Enter first name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.currentTarget.value)}
                                required
                            />
                            <TextInput
                                p={10}
                                label="Last Name"
                                placeholder="Enter last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.currentTarget.value)}
                                required
                            />
                        </div>
                        <TextInput
                            p={10}
                            label="Email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            type="email"
                            required
                        />
                        <div className="padded-container">
                            <label className='user-role-label'>
                                Set User Role <span className='mantine-103svbs'>*</span>
                            </label>
                            <Group>
                                <Radio
                                    className='InviteRadio'
                                    value="Prescriber"
                                    label={getRoleLabel('Prescriber')}
                                    checked={role === 'Prescriber'}
                                    onChange={() => setRole('Prescriber')}
                                />
                                <Radio
                                    className='InviteRadio'
                                    value="Therapist"
                                    label={getRoleLabel('Therapist')}
                                    checked={role === 'Therapist'}
                                    onChange={() => setRole('Therapist')}
                                />
                            </Group>
                        </div>
                        <div className="padded-container">
                            <Checkbox
                                label="Start with free trial"
                                checked={startWithFreeTrial}
                                onChange={handleFreeTrialChange}
                                mb={10}
                            />
                        </div>
                        <Group position="right" mt="md">
                            <Button
                                className='cancelButton'
                                onClick={async () => {
                                    window.location.href = '/signin';
                                    await medplum.signOut();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" className='defaultBg'>
                                Submit
                            </Button>
                        </Group>
                    </form>
                </Grid.Col>
            </Grid>
        </Box>
    );
}

export default NonNpiUserForm;