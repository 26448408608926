export const templateFormat = {
    "resourceType": "Composition",
    "status": "final",
    "author": [
      {
        "reference": "Practitioner/61987863-d9a2-40b1-813b-6806591e8f99",
        "display": "Medplum Admin"
      }
    ],
    "title": "",
    "section": [],
    "type": {
      "coding": [
        {
          "system": "",
          "code": "",
          "display": ""
          
        }
      ]
    },
    "category": [
    {
      "coding": [
        {
          "system": "",
          "code": "",
          "display": ""
        }
      ]
    }
  ],
    "extension" : [
      {
        "id": "system-prompt",
        "url": "https://pmhscribe.com/intg/structure/extensions/prompt-system",
        "valueString": ""
      },
      {
        "id": "user-prompt",
        "url": "https://pmhscribe.com/intg/structure/extensions/prompt-user",
        "valueString": ""
      }
    ],
  }
  