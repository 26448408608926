import { Box, Button, Grid, TextInput, Title, Text, Anchor, Checkbox } from '@mantine/core'
import React, { useState } from 'react'
import classes from './SignUp.module.css';
import { createStripeSsession, getStripeRenewSession, handleNonNpiReasonResponse, npiResponse } from '../utils/util';
import { useMedplum } from '@medplum/react';
import { Stepper } from './Stepper';
import { toast } from 'react-toastify';
import InvitePopup from '../patients/InvitePopup';
import RoleSelectionPopup from './RoleSelectionPopup';

function SignUp() {
  const medplum = useMedplum();
  const [npiNumber, setNpiNumber] = React.useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [startWithFreeTrial, setStartWithFreeTrial] = useState(true);
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const isRenew = new URLSearchParams(window.location.search).get('isRenew') === 'true' || isLoggedIn;
  const [isInvitePopupOpen, setInvitePopupOpen] = useState(false);
  const [isRoleSelectionPopup, setRoleSelectionPopup] = useState(false);

  interface PractitionerDetails {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    withNPI?: boolean;
    reason?: string;
  }

  const handleNpiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNpiNumber(event.target.value);
  };

  const handleFreeTrialChange = (event: { currentTarget: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setStartWithFreeTrial(event.currentTarget.checked);
  };

  const handleNextClick = async () => {
    if (npiNumber === '') {
      toast.error("Please enter a valid NPI number.");
      return;
    }

    const payload = { npi_number: npiNumber };
    try {
      const response = await npiResponse(medplum, payload);
      const priceId = response?.data?.priceId;
      const role = response?.data?.role;

      if (!priceId) {
        throw new Error("Invalid response data");
      }

      if (window.location.href.includes('isRenew=true')) {
        const renewPayload = { priceId: priceId };
        try {
          const res = await getStripeRenewSession(medplum, renewPayload);
          window.location.href = res.sessionUrl;
        } catch (err) {
          console.error('Error fetching Stripe renew session:', err);
        }
      } else {
        stripeSession(priceId, startWithFreeTrial, role);
        if (response?.data) {
          sessionStorage.setItem('response_data', JSON.stringify(response.data));
        }
      }
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        setRoleSelectionPopup(true);
      } else {
        toast.error("Error retrieving NPI details. Please enter a valid NPI number.");
      }
    }
  };

  const stripeSession = (stripePriceId: any, startWithFreeTrial: boolean, role: string) => {
    const payload = {
      priceId: stripePriceId,
      isFreeTrial: startWithFreeTrial,
      role: role,
      npiNumber: npiNumber
    }
    createStripeSsession(payload).then((res) => {
      console.log(res)
      window.location.href = res.sessionUrl;
    }).catch((err) => {
      console.log(err);
    });
  }

  const closePopup = () => {
    setInvitePopupOpen(false);
  };

  const handleInviteSubmit = async (medplum: any, details: PractitionerDetails) => {
    try {
      const response: any = await handleNonNpiReasonResponse(medplum, details);

      if (response) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000
        });
        setTimeout(() => {
          medplum.signOut();
          window.location.href = '/signin';
        }, 3000);
      } else {
        toast.error(`Failed to send invitation. Please try again later.`, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error: any) {
      console.error('Error:', error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    } finally {
      closePopup();
    }
  };

  const handleRoleSelectionSubmit = async (selectedRole: string) => {
    const payload = {
      role: selectedRole
    }
    sessionStorage.setItem('response_data', JSON.stringify(payload));
    stripeSession('', startWithFreeTrial, selectedRole);
  };

  const openInvitePopup = async () => {
    setInvitePopupOpen(true);
  };

  return (
    <Box >
      <Grid className={classes.signUpPage}>
        <Grid.Col span={12} className={classes.desktopOnly}>
          <div className={classes.pmhLogo}><img src="../../img/logo.png" alt="PMHScribe Logo" width="200" /></div>
        </Grid.Col>
        {!isRenew && (
          <Grid.Col span={12} pt={0} pb={0}>
            <Stepper totalSteps={4} activeStep={activeStep} setActiveStep={setActiveStep} />
          </Grid.Col>
        )}
        <Grid.Col
          span={12}
          className={` ${isRenew ? classes.marginTop10 : classes.logoAlign}`}
        >
          <div className={classes.centerAlign}>
            <Text>Welcome to PMHScribe.</Text>
            <Text>We are in Beta-Testing.</Text>
            <Text>We appreciate your feedback. </Text>
            <Text>So your first month is free!</Text>
          </div>
        </Grid.Col>
        <Grid.Col span={8} className={classes.para}>
          <Title order={1} className={classes.title}>To ensure our software is used securely by a qualified healthcare provider, <Text>please enter your NPI to get started:</Text></Title>
        </Grid.Col>
        <Grid.Col span={12} className={classes.npiSect}>
          {!isRenew && (<Checkbox
            label="Start with free trial"
            checked={startWithFreeTrial}
            onChange={handleFreeTrialChange}
            mb={10}
          />)}
          <div className={classes.flexContainer}>
            <TextInput
              label="NPI number"
              value={npiNumber}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleNpiChange(e);
                }
              }}
              maxLength={10}
              placeholder="Enter your NPI number"
              className={classes.inputWidth}
            />
            <Button mt={22} ml={20} onClick={handleNextClick} className={classes.nextBtn}>
              Next
            </Button>
          </div>
          <Anchor mt={22} href="https://npiregistry.cms.hhs.gov/search" target='_blank' sx={{ textDecoration: 'underline' }}>Find my NPI</Anchor>
          <div><Anchor mt={22} onClick={openInvitePopup} sx={{ textDecoration: 'underline' }}>I don't have NPI</Anchor></div>
        </Grid.Col>
        <Grid.Col span={12} mt={22} className={classes.colSect}>
          {!isRenew && (
            <Anchor href="/sign-in" className={classes.underlineText}>
              I already have an account. Sign in.
            </Anchor>
          )}
          <hr className={classes.borderTop} />
          <div className={classes.privacyText}>
            <div className={classes.flexDiv}>
              <img src="../../img/HIPAA.png" alt="HIPAA Logo" width="80" /></div>
            <Text >Your data is kept secure under HIPAA, HITECH, and Mental Health Privacy laws. You may keep patient health information and records in our system, sync them to your electronic health record, or choose not to enter patient details. By signing up for PMHScribe, you agree to our <Anchor href='https://pmhscribe.com/privacy-policy/' target='_blank'>Privacy Policy</Anchor> and <Anchor href='https://pmhscribe.com/terms-and-conditions/' target='_blank'>Terms of Use</Anchor>.</Text>
          </div>

        </Grid.Col>
      </Grid>
      <InvitePopup
        isOpen={isInvitePopupOpen}
        onClose={closePopup}
        onSubmit={(details) => handleInviteSubmit(medplum, details)}
        header="Non-NPI user details"
        submitButtonLabel="Submit"
        cancelButtonLabel="Cancel"
        isLoading={false}
      />
      <RoleSelectionPopup
        isOpen={isRoleSelectionPopup}
        onClose={() => setRoleSelectionPopup(false)}
        onSubmit={(selectedRole) => handleRoleSelectionSubmit(selectedRole)}
        submitButtonLabel="Submit"
        cancelButtonLabel="Cancel"
      />
    </Box>
  )
}

export default SignUp
