import React, { useEffect, useState } from 'react';
import { Box, Grid, Text, Tabs, Button } from '@mantine/core';
import pricingStyles from './Pricing.module.css';
import { createStripeSsession, restrictRepeatPayment, getStripeDetails } from '../utils/util';
import { toast } from 'react-toastify';

interface TabContentProps {
    buttonText: string;
    onClick: () => void;
    periodText: string;
    priceDiv: string;
    pricePeriod: string;
    className: string;
    isDisabled: boolean;
}

const TabContent: React.FC<TabContentProps> = ({ buttonText, onClick, periodText, className, priceDiv, pricePeriod, isDisabled }) => (
    <div className={`${pricingStyles.modeDiv} ${className}`}>
        <span className={pricingStyles.testMode}>Test Mode</span>
        <Text className={pricingStyles.periodDiv}>{periodText}</Text>
        <div className={pricingStyles.flexProperty}>
            <Text className={pricingStyles.price}>${priceDiv}</Text>
            <Text className={pricingStyles.period}>{pricePeriod}</Text>
        </div>
        <Text className={pricingStyles.textContent}>You know you'll love it. Sign up for the year and save.</Text>
        <Button onClick={onClick} disabled={isDisabled}>
            {buttonText}
        </Button>
    </div>
);

const handlePayment = async (priceId: string) => {
    try {
        const urlParams = new URLSearchParams(window.location.search);
        const firstName = urlParams.get('firstName') || '';
        const lastName = urlParams.get('lastName') || '';
        const email = urlParams.get('user') || '';

        const payload = {
            priceId,
            firstName,
            lastName,
            email
        };

        const response = await createStripeSsession(payload);

        if (response) {
            window.location.href = response.sessionUrl;
        } else {
            console.log('Error creating session, please try again later.');
        }
    } catch (error) {
        console.error(error);
    }
};

function Pricing() {
    const [monthlyPriceId, setMonthlyPriceId] = useState<string>('');
    const [yearlyPriceId, setYearlyPriceId] = useState<string>('');
    const [isPaymentDisabled, setIsPaymentDisabled] = useState(true);

    useEffect(() => {
        const checkRepeatPayment = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const email = urlParams.get('user') || '';

                if (email) {
                    try {
                        const response = await restrictRepeatPayment({ email });

                        const isPaymentDisabled = response.redirect !== 'payment';
                        setIsPaymentDisabled(isPaymentDisabled);

                        toast.success(response.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    } catch (error) {
                        console.error('Error restricting repeat payment:', error);
                        toast.error('An error occurred while restricting repeat payment.', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            } catch (error) {
                console.error('Error checking repeat payment:', error);
            }
        };

        checkRepeatPayment();

        getStripeDetails()
            .then((response) => {
                setMonthlyPriceId(response.monthly_price);
                setYearlyPriceId(response.yearly_price);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleMonthlyClick = () => handlePayment(monthlyPriceId);
    const handleYearlyClick = () => handlePayment(yearlyPriceId);
    
    return (
        <Box>
            <Grid>
                <Grid.Col span={12}>
                    <Grid sx={{ justifyContent: 'center', marginBottom: '20px', textAlign: 'center' }}>
                        <Text fw={500} size="xl" className={pricingStyles.heading}>Our Pricing Plan</Text>
                    </Grid>
                    <Grid.Col span={12} className={pricingStyles.gridLayout}>
                        <Tabs variant="pills" defaultValue="monthly" >
                            <div className={pricingStyles.tabList}>
                                <Tabs.List >
                                    <Tabs.Tab value="monthly" className={pricingStyles.tabButton}>
                                        Monthly
                                    </Tabs.Tab>
                                    <Tabs.Tab value="yearly" className={pricingStyles.tabButton}>
                                        Yearly
                                    </Tabs.Tab>
                                </Tabs.List>
                            </div>
                            <div>
                                <Tabs.Panel value="monthly">
                                    <TabContent buttonText="Proceed to Payment" onClick={handleMonthlyClick} periodText="Monthly PMHScribe" priceDiv="99" pricePeriod="per month" className={pricingStyles.tabContent}  isDisabled={isPaymentDisabled}/>
                                </Tabs.Panel>
                                <Tabs.Panel value="yearly">
                                    <TabContent buttonText="Proceed to Payment" onClick={handleYearlyClick} periodText="Yearly PMHScribe" priceDiv="999" pricePeriod="per year" className={pricingStyles.tabContent}  isDisabled={isPaymentDisabled}/>
                                </Tabs.Panel>
                            </div>

                        </Tabs>

                    </Grid.Col>

                </Grid.Col>
            </Grid>
        </Box>
    );
}


export default Pricing;
