import uitoolkit from "@zoom/videosdk-ui-toolkit";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";
import "./App.css";
import React, { useEffect } from "react";

function PatientSession() {
  const [token, setToken] = React.useState("");
  const [sessionName, setSessionName] = React.useState("");
  useEffect(() => {
    debugger;
    // get token from url
    const token = new URLSearchParams(window.location.search).get("token");
    setToken(token || "");
    const sessionName = new URLSearchParams(window.location.search).get("session");
    setSessionName(sessionName || "");
  }, []);

  useEffect(() => {
    if (token && sessionName) {
      getVideoSDKJWT();
    }
  }, [token, sessionName]);

  let sessionContainer: HTMLDivElement | null = null;
  function getVideoSDKJWT() {
    const config = {
      videoSDKJWT: token,
      sessionName: sessionName,
      userName: "React",
      sessionPasscode: "",
      features: ["video", "audio", "settings", "users", "chat", "share"],
      options: { init: {}, audio: {}, video: {}, share: {} },
      virtualBackground: {
        allowVirtualBackground: true,
        allowVirtualBackgroundUpload: true,
        virtualBackgrounds: ['https://images.unsplash.com/photo-1715490187538-30a365fa05bd?q=80&w=1945&auto=format&fit=crop']
      }
    };
    sessionContainer = document.getElementById("sessionContainer") as HTMLDivElement;
    joinSession(config);
  }

  function joinSession(config: any) {
    console.log(config);
    if (sessionContainer) {
      uitoolkit.joinSession(sessionContainer, config);
      sessionContainer && uitoolkit.onSessionClosed(sessionClosed);
    }
  }

  const sessionClosed = () => {
    console.log("session closed");
    sessionContainer && uitoolkit.closeSession(sessionContainer);
  };

  return (
    <div className="App">
      <main>
        <div id="sessionContainer"></div>
      </main>
    </div>
  );
}

export default PatientSession;
