/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Button, Card, Center, Group, Text } from '@mantine/core';
import { Logo } from '@medplum/react';
import { activateAccount } from './utils/util';
import { normalizeErrorString } from '@medplum/core';
import { useNavigate } from 'react-router-dom';
import { IconCircleCheck } from '@tabler/icons-react';

const ActivateAcount = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string>('');
  const [activationToken, setActivationToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');
  const [successIcon, setSuccessIcon] = useState<boolean>(false);

  useEffect(() => {
    const parseQueryString = (queryString: string) => {
      const params = new URLSearchParams(queryString);
      const userIdParam = params.get('userId') || '';
      const activationTokenParam = params.get('activationToken') || '';
      setUserId(userIdParam);
      setActivationToken(activationTokenParam);
    };
    parseQueryString(window.location.search);
    return () => {};
  }, []);

  useEffect(() => {
    if (userId && activationToken) {
      const payload = {
        userId,
        activationToken,
      };
      activateAccount(payload).then((response) => {
        setMessage(response.message);
        setLoading(false);
        setSuccessIcon(true);
      }).catch((err: any) => {
        setMessage(normalizeErrorString(err.response.data.message));
        setLoading(false);
      });
    }
  }, [userId, activationToken]);

  return (
    <div>
      <Center my="30px" w="100%">
        <Logo size={150} />
      </Center>
      <Center>
          <Card shadow="sm" padding="lg" radius="md" withBorder sx={{ padding: '3.25rem 5.25rem !important', textAlign: 'center'}}>
            {loading ? (
                <span className="loading">
                  <Text align="center" style={{ fontSize: '16px', fontWeight: '500', color: '#333'}}>Your account is being activated please wait</Text>
                  <span className="loading__dot"></span>
                  <span className="loading__dot"></span>
                  <span className="loading__dot"></span>
                </span>
            ) : (
              <>
                {successIcon && (
                  <IconCircleCheck size={80} color="green"/>
                )}
                <Group position="apart" mt="md" mb="xs">
                  <Text weight={500} sx={{fontSize: 'x-large'}}>{message}</Text>
                </Group>

                <Button variant="light" color="blue" mt="md" radius="md" onClick={() => {navigate('/signin')}}>
                  Click here to login
                </Button>
              </>
            )}
          </Card>
        </Center>
    </div>
  );
};

export default ActivateAcount;
