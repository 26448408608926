/* eslint-disable no-debugger */
import { Stack } from '@mantine/core';
import { getPropertyDisplayName, tryGetDataType } from '@medplum/core';
import { OperationOutcome } from '@medplum/fhirtypes';
import React, { useState } from 'react';
import { CheckboxFormSection } from '../CheckboxFormSection/CheckboxFormSection';
import { DEFAULT_IGNORED_PROPERTIES, PATIENT_DEFAULT_PROPERTIES, PropertyDisplayMapping } from '../constants';
import { FormSection } from '../FormSection/FormSection';
import { setPropertyValue } from '../ResourceForm/ResourceForm.utils';
import { getValueAndType } from '../ResourcePropertyDisplay/ResourcePropertyDisplay.utils';
import { ResourcePropertyInput } from '../ResourcePropertyInput/ResourcePropertyInput';
import { useMedplum } from '@medplum/react-hooks';


export interface BackboneElementInputProps {
  typeName: string;
  defaultValue?: any;
  outcome?: OperationOutcome;
  onChange?: (value: any) => void;
  simple?: boolean;
}

export function BackboneElementInput(props: BackboneElementInputProps): JSX.Element {
  const [value, setValue] = useState<any>(props.defaultValue ?? {});
  const medplum = useMedplum();

  function setValueWrapper(newValue: any): void {
    setValue(newValue);
    if (props.onChange) {
      props.onChange(newValue);
    }
  }

  const typeName = props.typeName;
  const typeSchema = tryGetDataType(typeName);
  if (!typeSchema) {
    return <div>{typeName}&nbsp;not implemented</div>;
  }

  const typedValue = { type: typeName, value };

  function getDisplayTitle(key: any): string {
    if (PropertyDisplayMapping.hasOwnProperty(key)) {
      return PropertyDisplayMapping[key];
    } else {
      return getPropertyDisplayName(key);
    }
  }

  return (
    <Stack>
      {Object.entries(typeSchema.elements).map(([key, property]) => {
        if (key === 'id' || DEFAULT_IGNORED_PROPERTIES.includes(key)) {
          return null;
        }
        if (typeName.startsWith('Patient') && !PATIENT_DEFAULT_PROPERTIES.includes(key)) {
          if (!(medplum.isSuperAdmin() && medplum.getProjectMembership()?.admin)) {
            return null;
          }
        }
        if (!property.type) {
          return null;
        }

        const [propertyValue, propertyType] = getValueAndType(typedValue, key);
        const required = property.min !== undefined && property.min > 0;

        if (property.type.length === 1 && property.type[0].code === 'boolean') {
          return (
            <CheckboxFormSection
              key={key}
              title={getDisplayTitle(key)}
              description={property.description}
              htmlFor={key}
            >
              <ResourcePropertyInput
                property={property}
                name={key}
                defaultValue={propertyValue}
                defaultPropertyType={propertyType}
                outcome={props.outcome}
                onChange={(newValue: any, propName?: string) => {
                  setValueWrapper(setPropertyValue(value, key, propName ?? key, property, newValue));
                }}
              />
            </CheckboxFormSection>
          );
        }

        return (
          <FormSection
            key={key}
            title={getDisplayTitle(key)}
            description={property.description}
            withAsterisk={required}
            htmlFor={key}
            outcome={props.outcome}
          >
            <ResourcePropertyInput
              property={property}
              name={key}
              defaultValue={propertyValue}
              defaultPropertyType={propertyType}
              onChange={(newValue: any, propName?: string) => {
                setValueWrapper(setPropertyValue(value, key, propName ?? key, property, newValue));
              }}
              simple={(typeName.startsWith('Patient')&& key === 'name') ? true : props.simple }
            />
          </FormSection>
        );
      })}
    </Stack>
  );
}
