import React from 'react';
import { Button, Center, Modal, Title, Text, Loader } from '@mantine/core';

interface ConfirmBoxProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirm: string;
  cancel: string;
  message: string;
  additionalText: string;
  patient:any;
  isLoader?: boolean;
}

const ConfirmBox: React.FC<ConfirmBoxProps> = ({ isOpen, onClose, onConfirm, confirm, cancel, message, additionalText, isLoader }) => {
  const handleCancelOrClose = () => {
    onClose();
  };

  const cancelClassName = confirm === "Delete Session" ? "btn-primary" : "btn-success";
  
  return (
    <Modal opened={isOpen} onClose={onClose} className='sessionConfirmationPop'>
      <Center>
        <Title order={4} className='titleStyle' fw={500} mb={10}>
          {message}
        </Title>
      </Center>
      {additionalText && <Text c="dimmed" className='additionalText'>{additionalText}</Text>}
      <Center mt="lg" className='btnBlock'>
        <Button className={cancelClassName} mr={10} px={'lg'} onClick={handleCancelOrClose}>
          {cancel}
        </Button>
        <Button className="btn-danger" px={'lg'} onClick={onConfirm}>
          {confirm} {isLoader && <Loader ml={10} size={20} color='white'/>}
        </Button>
      </Center>
    </Modal>
  );
};

export default ConfirmBox;
