import { Anchor, Grid } from '@mantine/core'
import React from 'react'
import style from './Footer.module.css';
import { LINKEDIN_URL, FACEBOOK_URL, TWITTER_URL } from '../constants';
import { IconBrandFacebook, IconBrandLinkedin, IconBrandTwitter } from '@tabler/icons-react';

function footer() {
    return (
        <Grid>
            <Grid.Col span={12} md={12} className={style.footerGrid}>

                <Anchor href={LINKEDIN_URL} target='blank'>
                    <IconBrandLinkedin width='2.4rem' height='2.4rem' color='white' className={style.fbLink} />
                </Anchor>

                <Anchor href={FACEBOOK_URL} target='blank' className={style.linkedinLink} >
                    <IconBrandFacebook width='2.4rem' height='2.4rem' color='white' />
                </Anchor>

                <Anchor href={TWITTER_URL} target='blank'>
                    <IconBrandTwitter width='2rem' height='2rem' color='white' />
                </Anchor>

                <p className={style.footerPara}>Contact: Allison@erx.ai </p>
                <p className={style.footerPara}>
                    <Anchor href="/privacy" td="underline" color='white'>Privacy Policy</Anchor> | <Anchor href="terms-conditions" td="underline" color='white'>Terms &amp; Conditions</Anchor>
                </p>
                <p className={`${style.footerPara} ${style.para}`}>© Copyright 2023- 2024 eRx.ai, Inc.</p>
            </Grid.Col>
        </Grid>
    )
}

export default footer
