import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { deepClone, normalizeErrorString } from '@medplum/core';
import { Resource, ResourceType } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import QuestionnaireForm from './QuestionnaireForm';
import { getQuestionnaire } from '../utils/util';
import axios from '../utils/axios';

export function EditPractitioner(): JSX.Element | null {
  const medplum = useMedplum();
  const { resourceType, id } = useParams() as { resourceType: ResourceType; id: string };
  const [value, setValue] = useState<Resource | undefined>();
  const [questionnaireResponse, setQuestionnaireResponse] = useState<any>();
  const [questionnaire, setQuestionnaire] = useState<any>();

  useEffect(() => {
    medplum
      .readResource(resourceType, id)
      .then((resource) => setValue(deepClone(resource)))
      .catch((err) => {
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      });
  },[medplum, resourceType, id]);

  const getQuestionnaireResponse = async (questionnaireResponseId: any) => {
    const token = await medplum.getAccessToken();
    try {
      const response = await axios.get(
        `/fhir/R4/QuestionnaireResponse/${questionnaireResponseId}`,
        {
          headers: {
            Accept: 'application/fhir+json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    if (value) {
      const extension: any = value?.meta?.extension;
      const registrationExtension: any = extension?.find(
        (ext: any) => ext.url === '/pmhs/StructureDefinition/RegistrationQuestionnaireResponse'
      );

      if (registrationExtension) {
        const valueUrlParts = registrationExtension.valueUrl.split('/');
        const questionnaireResponseId = valueUrlParts[valueUrlParts.length - 1];

        getQuestionnaireResponse(questionnaireResponseId)
          .then((fetchedQuestionnaireResp: any) => {
            getQuestionnaire(medplum)
              .then((fetchedQuestionnaire: any) => {
                const itemInfoMapping: any = {};

                const extractItemInfo = (item: any): any => {
                  if (item.linkId) {
                    itemInfoMapping[item.linkId] = {
                      type: item.type,
                      definition: item.definition,
                    };
                  }

                  if (item.item) {
                    item.item.forEach((subItem: any) => {
                      extractItemInfo(subItem);
                    });
                  }
                };

                fetchedQuestionnaire.item.forEach((item: any) => {
                  extractItemInfo(item);
                });

                const enhanceResponseItem = (responseItem: any): any => {
                  const linkId = responseItem.linkId;
                  const itemInfo = itemInfoMapping[linkId];

                  if (itemInfo) {
                    responseItem.definition = itemInfo.type;
                  }

                  if (responseItem.item) {
                    responseItem.item.forEach((subItem: any) => {
                      enhanceResponseItem(subItem);
                    });
                  }
                };

                // update with each item type from questionnaire
                fetchedQuestionnaireResp.item.forEach((responseItem: any) => {
                  enhanceResponseItem(responseItem);
                });

                setQuestionnaire(fetchedQuestionnaire);
                setQuestionnaireResponse(fetchedQuestionnaireResp);
              })
              .catch((error: any) => {
                showNotification({ color: 'red', message: normalizeErrorString(error) });
              });
          })
          .catch((error: any) => {
            showNotification({ color: 'red', message: normalizeErrorString(error) });
          });
      }
    }
  }, [medplum, value]);

  if (!value || !questionnaireResponse || !questionnaire) {
    return null;
  }

  return (
    <>
      <QuestionnaireForm data={questionnaireResponse} />
    </>
  );
}
