import { Button } from '@mantine/core';
import React from 'react';

interface LeaveButtonProps {
  onLeaveClick: () => void;
  onEndClick: () => void;
  isHost: boolean;
}

const LeaveButton = (props: LeaveButtonProps) => {
  const { onLeaveClick } = props;

  return  (
    <Button
      variant="filled"
      radius="md"
      onClick={onLeaveClick}
      title="Leave session"
      w="auto"
      ml="md"
      fs="14"
      fw={600}
      h={45}
      color='red'
    > 
      End call 
    </Button>
  );
};

export { LeaveButton };
