/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Button, Center, Text, Box, Checkbox, Tooltip, Group } from '@mantine/core';
import { IconMicrophone, IconPlayerPauseFilled, IconCircleCheck } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import { getConfig } from '../config';
import { useMedplum } from '@medplum/react';
import { recordTypes, roleInitialNoteMap } from '../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  checkIsZoomEnabled,
  checkSubscriptionExpired,
  createNewSession,
  generateSummary,
  getPatientDetails,
  getPractitionerDetails,
  getPractitionerRole,
  getSessionDetails,
  getTemplateListAccordingToRole,
  saveUnknownPatientSession,
} from '../utils/util';
import { useAppContext } from '../AppProvider';
import PatientStepper from './PatientStepper';
import axios from '../utils/axios';
import ZoomModal from '../components/ZoomModal';
import Meeting from './Telehealth/Meeting';
import { useMediaQuery } from '@mantine/hooks';

const Session = () => {
  const medplum = useMedplum();
  const patientId = useParams()?.id;
  const [audioState, setAudioState] = useState(recordTypes.NOTSTARTED);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const intervalRef = useRef<any>(null);
  const [socket, setSocket] = useState<Socket | null>(null);
  const baseUrl = getConfig()?.baseUrl;
  const transcribeUrl = `${baseUrl}transcribe`;
  const audioContextRef = useRef<AudioContext | null>(null);
  const micStreamRef = useRef<MediaStream | null>(null);
  const tabStreamRef = useRef<MediaStream | null>(null);
  const mixedStreamRef = useRef<MediaStream | null>(null);
  const [transcripts, setTranscripts] = useState<string[]>([]);
  const [isAddPatient, setIsAddPatient] = useState(false);
  const [patientData, setPatientData] = useState<any>(null);
  const {
    isSessionOn,
    setIsSessionOn,
    setIsConfirmOpen,
    redirectionUrl,
    setRedirectionUrl,
    setSessionState,
    setSessionTranscripts,
    setSessionStartDateTime,
    isSessionStop,
    appointMentIdValue,
    setAppointMentIdValue,
    docRefeId,
    setDocRefeId,
    setIsSessionStop,
    setUnknownSessionsRefresh,
    setHasSoapSummaryGenerationError,
    isPatientPopup,
    zoomTranscript,
    setIsisPatientPopup,
    setZoomPatientName,
    hideZoomVideo, setHideZoomVideo, defaultCompositionId,
  } = useAppContext();
  const [isExpiredLoginSession, setIsExpiredLoginSession] = useState(false);
  const isRecordingFinished = useRef(false);
  const resourceID = medplum.getActiveLogin()?.profile?.reference;
  const parts: any = resourceID?.split('/');
  const practitionerId = parts?.[1];
  const navigate = useNavigate();
  const [sessionStartTime, setSessionStartTime] = useState<Date | undefined>(undefined);
  const [isCheckNextBtnLoader, setIsCheckNextBtnLoader] = useState<boolean>(false);
  let compositionId: string = '';
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);
  //const [isCheckSubscriptionExpired, setIsCheckSubscriptionExpired] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isCheckCheckBox, setIsCheckCheckBox] = useState(false);
  const userAgent = navigator.userAgent;
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [sessionInfo, setSessionInfo] = useState<{
    accessToken?: string;
    sessionName?: string;
    patientSessionLink?: string;
  }>({});
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [isZoomEnabled, setIsZoomEnabled] = useState(false);
  const [isZoomSession, setIsZoomSession] = useState(false);
  const startStopTranscriptionTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    document.body.style.cursor = isWaiting ? 'wait' : 'default';
  }, [isWaiting]);

  useEffect(() => {
    checkIsZoomEnabled(medplum)
      .then((response) => {
        setIsZoomEnabled(response.isZoomEnabled);
      })
      .catch((err) => {
        console.error('Error: Failed to check if zoom is enabled', err);
        if (err.response?.status === 401) {
          toast.error('Your session has expired. Please sign-in again.');
          setTimeout(async () => {
            stopTranscription(recordTypes.FINISHED);
            await medplum.signOut();
            navigate('/signin');
            setIsSessionOn(false);
          }, 1500);
          return;
        }
      });
  }, []);

  useEffect(() => {
    if (redirectionUrl) {
      onConfirmExit();
    }
  }, [redirectionUrl]);

  const savePausePatientSession = async () => {
    setUnknownSessionsRefresh(false);
    if ((!transcripts?.length && !transcriptsRef.current) || transcripts.length === 0) {
      //toast.error('Transcripts are not available.');
      return;
    }

    try {
      const apiPayload = {
        patientName: 'unknown',
        sessionStartTime: sessionStartTime,
        sessionEndTime: new Date(),
        transcripts: transcriptsRef.current?.length > 0 ? transcriptsRef.current : transcripts,
        documentReferenceId: docRefeId?.length > 0 ? docRefeId : currentDocRefeId.current,
        appointmentId: appointMentIdValue?.length > 0 ? appointMentIdValue : currentAppointmentId.current,
      };
      const response = await saveUnknownPatientSession(medplum, apiPayload);

      if (response) {
        if (response.documentReferenceId) {
          setDocRefeId(response.documentReferenceId);
        }
        if (response.appointmentId) {
          setAppointMentIdValue(response.appointmentId);
        }
        toast.success(response.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setUnknownSessionsRefresh(true);
      } else {
        toast.error(`Failed to save session. Please try again later.`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(`Failed to save session. Please try again later.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const requestWakeLock = async () => {
    try {
      // Request a wake lock
      const wakeLock = await navigator.wakeLock.request('screen');
      setWakeLock(wakeLock);
    } catch (err) {
      console.error('Unable to acquire wake lock:', err);
    }
  };

  const releaseWakeLock = () => {
    // Release the wake lock when it's no longer needed
    if (wakeLock) {
      wakeLock.release();
      setWakeLock(null);
    }
  };

  useEffect(() => {
    // Check if the wakeLock API is supported
    if ('wakeLock' in navigator) {
      if (audioState === recordTypes.STARTED) {
        requestWakeLock();
      }

      // Cleanup the wake lock when the component unmounts
      return () => {
        releaseWakeLock();
      };
    }
    return releaseWakeLock();
  }, [audioState]);

  useEffect(() => {
    getPractitionerDetails(medplum)
      .then((response: any) => {
        if (response && response.providerName === 'New User') {
          navigate('/Practitioner/' + practitionerId + '/edit');
          //show after 1 second
          setTimeout(() => {
            toast.success('Please update your profile details to continue.');
          }, 1000);
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch practitioner details.');
      });
  }, []);

  useEffect(() => {
    if (patientId) {
      getPatientDetails(medplum, patientId)
        .then((response: any) => {
          setPatientData(response);
        })
        .catch((error: any) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [patientId]);

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    });
  }, []);

  const startTranscription = async (showFlag: boolean, retryCount = 3) => {
    try {
      await isLoginSessionExpired();
    } catch (error) {
      return;
    }
    console.log('Message - Starting recording...');
    setIsSessionOn(true);
    setIsWaiting(true);

    isRecordingFinished.current = false;

    const loggedInUser = `${medplum.getProfile()?.name?.[0]?.given?.[0] ?? ''} ${medplum.getProfile()?.name?.[0]?.family ?? ''
      }`;
    let socket: Socket;
    try {
      socket = io(transcribeUrl, {
        transports: ['polling'],
        reconnection: true,
        reconnectionAttempts: 3,
        reconnectionDelay: 1000,
        query: {
          username: loggedInUser,
        },
      });

      setSocket(socket);

      socket.on('connect', () => {
        console.log('Event - Connected to server: ' + socket.id);
        console.log('Message - Socket Id: ' + socket.id);
      });

      // Add an event listener for the 'error' event on the socket
      socket.on('error', (error) => {
        console.error('Event - Socket error: ' + socket.id, error);
        // Disconnect the current socket
        socket.disconnect();
        clearTranscriptTimerInterval();
        // Reinitialize the socket after a delay
        //console.log('Message - Reconnecting to transcription service...');
        //setTimeout(() => startTranscription(showFlag), 1000);
      });

      socket.on('dg-connection-error', (message) => {
        console.error('Event - transcription service connection error:' + socket.id, message);
        toast.error(message);
        setAudioState(recordTypes.NOTSTARTED);
        setIsWaiting(false);
        setIsButtonDisabled(false);
        socket.disconnect();
        startTranscription(showFlag);
      });

      socket.on('dg-reconnect-failed', (message) => {
        console.error('Event - transcription service re-connection error:' + socket.id, message);
        toast.error('Connection to transcription service failed: ' + message);
        setAudioState(recordTypes.NOTSTARTED);
        setIsWaiting(false);
        setIsButtonDisabled(false);
      });
    } catch (error) {
      setIsWaiting(false);
      setIsButtonDisabled(false);
      console.error('Message - Error creating socket connection:', error);
      if (retryCount > 0) {
        console.log(`Retrying connecting socket... (${retryCount} retries left)`);
        toast.error(`Failed to start transcription service. Retrying...`);
        //setTimeout(() => startTranscription(showFlag, retryCount - 1), 1500);
      } else {
        console.log('Failed to start transcription service after 3 retries. Please retry after sometime.');
        toast.error('Failed to start transcription service after 3 retries. Please retry after sometime.');
      }
      return;
    }

    setSessionStartTime(new Date());
    setSessionStartDateTime(new Date());

    const attemptGetUserMedia = async (retryCount = 0) => {
      try {
        let stream: any;
        if ((userAgent.match(/chrome|chromium|crios/i) || userAgent.match(/edg/i)) && checked) {
          audioContextRef.current = new window.AudioContext();

          // Capture microphone audio
          const micStream = await navigator.mediaDevices.getUserMedia({ audio: true });
          micStreamRef.current = micStream;
          const micSource = audioContextRef.current.createMediaStreamSource(micStream);
          const micGain = audioContextRef.current.createGain();
          micSource.connect(micGain);

          // Capture tab audio
          const tabStream = await navigator.mediaDevices.getDisplayMedia({ audio: true });
          tabStreamRef.current = tabStream;
          const tabSource = audioContextRef.current.createMediaStreamSource(tabStream);
          const tabGain = audioContextRef.current.createGain();
          tabSource.connect(tabGain);

          // Mix streams
          const mixedDestination = audioContextRef.current.createMediaStreamDestination();
          micGain.connect(mixedDestination);
          tabGain.connect(mixedDestination);
          mixedStreamRef.current = mixedDestination.stream;
        } else {
          stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        }

        const mediaRecorder = new MediaRecorder(
          userAgent.match(/chrome|chromium|crios/i) && checked ? mixedStreamRef.current : stream
        );
        mediaRecorderRef.current = mediaRecorder;
        setSessionState(mediaRecorder.state);
        console.log('Message - MediaRecorder created');

        socket.on('can-open-mic', () => {
          console.log('Event - Opening mic...: ' + socket.id);
          if (mediaRecorder.state === 'recording') {
            console.log('Message - Already recording');
            return;
          }

          if (mediaRecorder.state === 'inactive') {
            mediaRecorder.start(200);
          } else if (mediaRecorder.state === 'paused') {
            mediaRecorder.resume();
          } else {
            mediaRecorder.start(200);
          }
          socket.emit('can-open-mic-ack');
          if (showFlag) {
            setAudioState(recordTypes.STARTED);
            setIsWaiting(false);
            setIsButtonDisabled(false);
            setIsCheckCheckBox(true);
            toast.success('Recording now.');
          }
        });

        mediaRecorder.addEventListener('error', (event) => {
          console.error('Event - MediaRecorder error:', event);
          // Stop the current MediaRecorder
          mediaRecorder.stop();
          // Release the current media stream
          if (mediaRecorder.stream) {
            mediaRecorder.stream.getTracks().forEach((track) => track.stop());
          }
          // Restart the transcription after a delay
          // setTimeout(() => startTranscription(showFlag), 1000);
        });

        // Adding ondataavailable event
        mediaRecorder.ondataavailable = (e) => {
          if (!isRecordingFinished.current) {
            if (e.data.size > 0) {
              socket.emit('microphone-stream', e.data);
            } else {
              console.log('Message - Empty data received from media recorder');
            }
          }
        };

        socket.on('close-mic', () => {
          console.log('Event - Closing mic: ' + socket.id);
          if (mediaRecorder.state !== 'inactive') {
            mediaRecorder.stop();
          }
          socket.disconnect();
          clearTranscriptTimerInterval();
        });

        socket.on('transcript-result', (socketId, jsonFromServer) => {
          const result = jsonFromServer;
          console.log('Event - Transcript:', result);
          if (result) {
            updateTranscripts(result);
          }
          if (isRecordingFinished.current) {
            stopRecording(recordTypes.FINISHED);
          }
        });

        socket.on('disconnect', () => {
          console.log('Event - Disconnected from server');
          setIsWaiting(false);
          setIsButtonDisabled(false);
          if (mediaRecorder.state !== 'inactive') {
            mediaRecorder.stop();
          }
        });
      } catch (error) {
        console.error('Message - Error accessing microphone:', error);
        toast.error('Error accessing microphone. Retrying...');
        if (checked) {
          toast.error('Please enable microphone permissions.');
          setIsCheckCheckBox(false);
          setIsWaiting(false);
          setIsButtonDisabled(false);
          clearTranscriptTimerInterval();
          return;
        }
        // Retry up to 3 times
        if (retryCount < 2) {
          setTimeout(() => attemptGetUserMedia(retryCount + 1), 2000);
        } else {
          toast.error('Unable to access microphone after multiple attempts. Please check permission to microphone.');
          setIsCheckCheckBox(false);
          setIsWaiting(false);
          setIsButtonDisabled(false);
          clearTranscriptTimerInterval();
        }
      }
    };

    attemptGetUserMedia();

    return () => {
      // Unsubscribe from socket events when the component unmounts
      socket.off('can-open-mic');
      socket.off('close-mic');
      socket.off('transcript-result');
      socket.off('disconnect');
      clearTranscriptTimerInterval();
    };
  };

  const clearTranscriptTimerInterval = () => {
    if (startStopTranscriptionTimerRef.current) {
      clearInterval(startStopTranscriptionTimerRef.current);
    }
    startStopTranscriptionTimerRef.current = null;
  }

  const isLoginSessionExpired = async () => {
    try {
      await checkSubscriptionExpired(medplum);
    } catch (error) {
      setIsExpiredLoginSession(true);
      if (!transcripts?.length) {
        await savePausePatientSession();
      }
      toast.error('Your session has expired. Please sign-in again.');
      setTimeout(async () => {
        stopTranscription(recordTypes.FINISHED);
        await medplum.signOut();
        navigate('/signin');
        setIsSessionOn(false);
      }, 1500);
      return;
    }
  };

  const updateTranscripts = (transcript: string) => {
    setTranscripts((prevTranscripts) => [...prevTranscripts, transcript]);
    setSessionTranscripts((prevTranscripts: any) => [...prevTranscripts, transcript]);
  };

  //tab close event to save the session
  const transcriptsRef = useRef(transcripts);

  useEffect(() => {
    transcriptsRef.current = transcripts;
  }, [transcripts]);

  const currentAudioState = useRef(audioState);
  const currentAppointmentId = useRef(appointMentIdValue);
  const currentDocRefeId = useRef(docRefeId);

  useEffect(() => {
    currentAudioState.current = audioState;
    currentAppointmentId.current = appointMentIdValue;
    currentDocRefeId.current = docRefeId;
  }, [audioState, appointMentIdValue, docRefeId]);

  useEffect(() => {
    if (isExpiredLoginSession) return;
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if ((isSessionOn || isAddPatient) && audioState !== 'paused') {
        const message = 'Are you sure you want to leave? Your changes may not be saved.';
        event.preventDefault();
        event.returnValue = message;
        saveSessionData();
        return message; // For some older browsers
      }
      return undefined;
    };
    if ((isSessionOn || isAddPatient) && audioState !== 'paused') {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSessionOn, isAddPatient, isExpiredLoginSession]);

  const saveSessionData = async () => {
    const token = await medplum.getAccessToken();
    try {
      const payload = {
        patientName: 'unknown',
        sessionStartTime: sessionStartTime,
        sessionEndTime: new Date(),
        transcripts: transcripts.length > 0 ? transcripts : transcriptsRef.current,
        documentReferenceId: currentDocRefeId?.current || docRefeId,
        appointmentId: currentAppointmentId?.current || appointMentIdValue,
      };

      const response = await axios.post(`/intg/save-unknown-patient-session`, payload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });

      if (response) {
        if (response.data.documentReferenceId) {
          setDocRefeId(response.data.documentReferenceId);
        }
        if (response.data.appointmentId) {
          setAppointMentIdValue(response.data.appointmentId);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to save session. Please try again later.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    if (isSessionStop && isSessionOn) {
      stopTranscription(recordTypes.FINISHED, true);
      if (redirectionUrl) {
        if (redirectionUrl === '/signin') {
          signOut();
        }
      } else {
        navigate(redirectionUrl === '/session' ? '/' : '/session');
        setTranscripts([]);
      }
      setIsSessionStop(false);
      const response = savePausePatientSession();
      response.finally(() => {
        setAppointMentIdValue('');
        setDocRefeId('');
      });
      navigate(redirectionUrl || '/session');
    }
  }, [isSessionStop, isSessionOn]);

  const signOut = async () => {
    await medplum.signOut();
  };
  const stopTranscription = (state: string, fromEndRecording: boolean = false) => {
    setSessionState(state);
    startStopTranscriptionTimer(false);
    console.log('Message - In stopTranscription(), state: ' + state);
    if (!transcripts?.length && isSessionOn ) {
      toast.error('No voice detected. Please speak something before ending the recording.', {
        autoClose: 1000,
      });
      setIsButtonDisabled(false);
      return;
    }
    setAudioState(state);
    socket?.disconnect();
    if (state === 'finished') {
      console.log('Message - Stopped recording...');
      isRecordingFinished.current = true;
      if (mediaRecorderRef.current?.state !== 'inactive') {
        mediaRecorderRef.current?.stop();
      }
      mediaRecorderRef.current?.stream.getTracks().forEach((track) => track.stop());
      if (fromEndRecording) {
        setIsAddPatient(false);
      } else {
        setIsAddPatient(true);
      }
      setIsSessionOn(false);
      setIsCheckCheckBox(false);

      // Stop all tracks of the microphone stream
      if (micStreamRef.current) {
        micStreamRef.current.getTracks().forEach((track) => track.stop());
      }

      // Stop all tracks of the tab/screen stream
      if (tabStreamRef.current) {
        tabStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    } else if (state === 'paused') {
      savePausePatientSession();
      console.log('Message - Paused recording...');
      setIsSessionOn(false);
      // Stop all tracks of the microphone stream
      if (micStreamRef.current) {
        micStreamRef.current.getTracks().forEach((track) => track.stop());
      }

      // Stop all tracks of the tab/screen stream
      if (tabStreamRef.current) {
        tabStreamRef.current.getTracks().forEach((track) => track.stop());
      }
      if (mediaRecorderRef.current?.state === 'recording') {
        mediaRecorderRef.current?.pause();
      }
    }
    setIsButtonDisabled(false);
  };

  const stopRecording = (state: string) => {
    if (isRecordingFinished.current) {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.addEventListener('stop', () => {
          if (mediaRecorderRef.current && mediaRecorderRef.current.stream) {
            const tracks = mediaRecorderRef.current.stream.getTracks();
            tracks.forEach((track) => {
              track.stop();
            });
          }

          socket?.disconnect();
        });

        mediaRecorderRef.current.stop();
        clearInterval(intervalRef.current);
      }

      if (transcripts?.length && state === recordTypes.FINISHED) {
        setIsAddPatient(true);
      }
      // else if (!transcripts?.length) {
      //   toast.error('Transcripts are not available.');
      // }
      if (state === recordTypes.FINISHED || state === recordTypes.NOTSTARTED) {
        setIsSessionOn(false);
      }
    }
  };

  const onConfirm = async (patientData: any) => {
    compositionId = defaultCompositionId || patientData?.patientDetails?.compositionId || '';
    setUnknownSessionsRefresh(false);

    if (zoomTranscript?.length > 0) {
      setTranscripts(zoomTranscript);
    }
    else if (!transcripts?.length) {
      toast.error('Transcripts are not available.');
      return;
    }

    try {
      await getPractitionerRole(medplum, practitionerId).then(async (response: any) => {
        const role = response?.entry?.[0]?.resource?.code?.[0]?.coding?.[0]?.display || '';
        await getTemplateListAccordingToRole(medplum, role).then((response: any) => {
          if (response?.entry?.length >= 0) {
            const defaultTitle = 'Medication Management';
            const title = roleInitialNoteMap[role] || defaultTitle;

            const medicalManagementEntry = response.entry.find((entry: any) => entry.resource.title === title);
            //compositionId = medicalManagementEntry?.resource.id;
            if (compositionId === '') {
              compositionId = medicalManagementEntry?.resource.id;
            }
          } else {
            toast.error('Error generating SOAP notes from transcript.');
            console.error('Role is not assigned to any template: ', role);
          }
        });
      });
    } catch (error) {
      console.error('Error fetching compositionId:', error);
    }

    const transcriptData = transcripts.length > 0 ? transcripts : (zoomTranscript.length > 0 ? zoomTranscript : []);

    const payload = {
      patientId: patientData?.patientDetails?.patientId,
      patientName: patientData.patientDetails?.patientName,
      birthDate: patientData.patientDetails?.birthDate,
      mobile: patientData.patientDemographics?.mobile,
      email: patientData.patientDemographics?.email,
      gender: patientData.patientDemographics?.gender,
      race: patientData.patientDemographics?.race,
      address: patientData.patientDemographics?.address,
      transcript: transcriptData,
      practitionerId: practitionerId,
      noteType: 'psychiatric',
      sessionStartTime: sessionStartTime,
      sessionEndTime: new Date(),
      mentalHealthDiagnoses: patientData?.patientDetails?.mentalHealthDiagnoses,
      otherDiagnoses: patientData?.patientDetails?.otherDiagnoses,
      documentReferenceId: docRefeId,
      appointmentId: appointMentIdValue,
    };

    setIsCheckNextBtnLoader(true);
    if (patientData && (transcriptData)) {
      let navigateUrl: string | undefined;
      try {
        const response = await createNewSession(medplum, payload);
        if (response?.message) {
          setUnknownSessionsRefresh(true);
          setIsisPatientPopup(false);
          setZoomPatientName('');
          navigateUrl = `/Appointment/${response.sessionId}/details`;
          setIsCheckNextBtnLoader(false);
          toast.success('Notes will be generated in the background.');
          setDocRefeId('');
          setAppointMentIdValue('');
        }
        setIsSessionOn(false);
        getSession(response?.sessionId);
      } catch (error) {
        console.error('Error:', error);
        setIsCheckNextBtnLoader(false);
        toast.error('Failed to generate the notes.');
      }
      setTranscripts([]);
      setIsAddPatient(false);
      if (navigateUrl) {
        navigate(navigateUrl);
      } else {
        navigate('/session');
      }
      socket?.disconnect();
    }
  };

  const onCancel = () => {
    setIsAddPatient(false);
    setAudioState(recordTypes.NOTSTARTED);
    setTranscripts([]);
  };

  const onConfirmExit = async () => {
    stopTranscription(recordTypes.FINISHED, true);
    if (transcripts?.length === 0) {
      stopTranscription(recordTypes.PAUSED, true);
    }
    if (
      transcripts?.length &&
      audioState !== 'paused' &&
      !(redirectionUrl.includes('Practitioner/') || redirectionUrl === '/session' || redirectionUrl === '/')
    ) {
      const response = savePausePatientSession();
      response.finally(() => {
        setAppointMentIdValue('');
        setDocRefeId('');
      });
    }
    stopTranscription(recordTypes.NOTSTARTED);
    setTranscripts([]);

    if (redirectionUrl) {
      setIsConfirmOpen(false);
      if (redirectionUrl === '/signin') {
        await medplum.signOut();
      }
    } else {
      navigate('/session');
    }
    setIsSessionOn(false);
    setAppointMentIdValue('');
    setDocRefeId('');

    navigate(redirectionUrl || '/session');
    setRedirectionUrl(undefined);
    mediaRecorderRef?.current?.stream.getTracks().forEach((track) => track.stop());
  };

  const getSession = (appointmentId: string) => {
    getSessionDetails(medplum, appointmentId)
      .then((response: any) => {
        getSummary(response);
      })
      .catch((error: any) => {
        setIsAddPatient(false);
        navigate('/session');
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch session details.');
      });
  };

  const getSummary = (response?: any) => {
    const transcriptData = transcripts.length > 0 ? transcripts : (zoomTranscript.length > 0 ? zoomTranscript : []);
    const payload = {
      compositionId: [compositionId],
      trascriptDocReferenceId: response?.transcriptData?.transcriptDocId,
      patientId: response?.patientId,
      practitionerId: response?.practitionerId,
      appointmentId: response?.appointmentId,
      encounterId: response?.encounterId,
      transcript: transcriptData,
    };

    generateSummary(medplum, payload)
      .then(() => {
        setIsCheckNextBtnLoader(false);
      })
      .catch((error: any) => {
        setHasSoapSummaryGenerationError(true);
      });
  };

  // Set up a timer to save transcription periodically every 30 minutes
  function startStopTranscriptionTimer(isStart: boolean) {
    if (isStart) {
      if (startStopTranscriptionTimerRef.current) {
        clearInterval(startStopTranscriptionTimerRef.current);
        startStopTranscriptionTimerRef.current = null;
      }

      startStopTranscriptionTimerRef.current = setInterval(async () => {
        console.log('Saving transcription as unknown transcription...');
        console.log(transcripts);
        await savePausePatientSession();
        //added to check if the login session is expired
        await isLoginSessionExpired();
      }, 10 * 60 * 1000);
    } else {
      if (startStopTranscriptionTimerRef.current) {
        clearInterval(startStopTranscriptionTimerRef.current);
        startStopTranscriptionTimerRef.current = null;
      }
    }
  }

  return (
    <>
      {!isZoomSession ? (
        <div
          style={
            audioState === recordTypes.NOTSTARTED && isZoomEnabled
              ? { display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '100%' }
              : undefined
          }
        >
          <Center
            m="lg"
            p="xs"
            className="resource-list"
            style={{ minHeight: '55vh', flexDirection: 'column', textAlign: 'center' }}
          >
            {(audioState === recordTypes.NOTSTARTED ||
              audioState === recordTypes.FINISHED ||
              audioState === recordTypes.STARTED) &&
              (audioState !== recordTypes.STARTED ? (
                <Tooltip
                  label="Click to start recording audio for this session. Ensure your microphone is active. Click the mic again to pause the recording."
                  withArrow
                  position="right"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                    maxWidth: '200px',
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                    textAlign: 'left',
                  }}
                >
                  <Button
                    className={`btn-danger ${audioState === recordTypes.STARTED && 'active-recording'}`}
                    h={50}
                    w={50}
                    p="xs"
                    style={{ background: 'red', borderRadius: '50%', cursor: `${isWaiting ? 'wait' : 'pointer'}` }}
                    onClick={() => {
                      setIsButtonDisabled(true);
                      if (audioState === recordTypes.STARTED) {
                        stopTranscription(recordTypes.PAUSED);
                      } else {
                        startStopTranscriptionTimer(true);
                        startTranscription(true);
                      }
                    }}
                    disabled={isButtonDisabled}
                  >
                    <IconMicrophone size={34} color="white" />
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  className={`btn-danger ${audioState === recordTypes.STARTED && 'active-recording'}`}
                  h={50}
                  w={50}
                  p="xs"
                  style={{ background: 'red', borderRadius: '50%', cursor: `${isWaiting ? 'wait' : 'pointer'}` }}
                  onClick={() => {
                    setIsButtonDisabled(true);
                    if (audioState === recordTypes.STARTED) {
                      stopTranscription(recordTypes.PAUSED);
                    } else {
                      startTranscription(true);
                    }
                  }}
                  disabled={isButtonDisabled}
                >
                  <IconMicrophone size={34} color="white" />
                </Button>
              ))}

            {audioState === recordTypes.PAUSED && (
              <Tooltip
                label="Click to resume recording from where you left off."
                withArrow
                position="right"
                sx={{
                  fontSize: '12px',
                  fontWeight: 'normal',
                  maxWidth: '160px',
                  whiteSpace: 'normal',
                  overflowWrap: 'break-word',
                  textAlign: 'left',
                }}
              >
                <Button
                  className="btn-danger"
                  h={50}
                  w={50}
                  p="xs"
                  style={{ background: 'red', borderRadius: '50%', cursor: `${isWaiting ? 'wait' : 'pointer'}` }}
                  onClick={() => {
                    setIsButtonDisabled(true);
                    startTranscription(true);
                  }}
                  disabled={isButtonDisabled}
                >
                  <IconPlayerPauseFilled size={34} color="white" />
                </Button>
              </Tooltip>
            )}

            <Group sx={{ justifyContent: 'center' }}>
              {(audioState === recordTypes.STARTED || audioState === recordTypes.PAUSED) && (
                <>
                  <Button
                    mt="lg"
                    className="pause-button"
                    onClick={() => {
                      setIsButtonDisabled(true);
                      if (audioState === recordTypes.STARTED) {
                        stopTranscription(recordTypes.PAUSED);
                      } else {
                        startStopTranscriptionTimer(true);
                        startTranscription(true);
                      }
                    }}
                    disabled={isButtonDisabled}
                  >
                    {audioState === recordTypes.STARTED ? (
                      <>
                        <IconPlayerPauseFilled size={20} style={{ marginRight: '2px' }} color="white" /> Pause Session
                      </>
                    ) : (
                      <>
                        <IconMicrophone size={20} style={{ marginRight: '2px' }} /> Restart Session
                      </>
                    )}
                  </Button>
                </>
              )}


              {(audioState === recordTypes.STARTED || audioState === recordTypes.PAUSED) && (
                <Button
                  mt="lg"
                  className="end-session-button"
                  onClick={async () => {
                    try {
                      await isLoginSessionExpired();
                    } catch (error) {
                      return;
                    }
                    stopTranscription(recordTypes.FINISHED);
                  }}
                >
                  {audioState === recordTypes.STARTED ? (
                    <>
                      <IconCircleCheck size={18} style={{ marginRight: '2px' }} /> End & Complete session
                    </>
                  ) : (
                    <>
                      <IconCircleCheck size={18} style={{ marginRight: '2px' }} /> Stop & Save Session
                    </>
                  )}

                </Button>
              )}
            </Group>

            {!isMobile && (
              <div className="bottom-right-container">
                {(userAgent.match(/chrome|chromium|crios/i) || userAgent.match(/edg/i)) && (
                  <div>
                    {(isCheckCheckBox && checked) || isCheckCheckBox ? (
                      <Checkbox
                        checked={checked}
                        disabled={true}
                        onChange={(event) => setChecked(event.currentTarget.checked)}
                        label="Allow audio from other tab"
                        style={{ marginTop: '20px' }}
                      />
                    ) : (
                      <Tooltip
                        label="If you're having trouble, click this box to enable microphone access. A pop-up will ask you to choose which telehealth app can share the microphone with PMHScribe."
                        withArrow
                        position="right"
                        sx={{ fontSize: '12px', fontWeight: 'normal' }}
                      >
                        <div>
                          <Checkbox
                            checked={checked}
                            disabled={false}
                            onChange={(event) => setChecked(event.currentTarget.checked)}
                            label="Allow audio from other tab"
                            style={{ marginTop: '20px' }}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            )}

            <Text size={20} mt="lg">
              {!(audioState === recordTypes.STARTED || audioState === recordTypes.PAUSED) && (
                <>
                  <span style={{ fontWeight: '600' }}>Start a New Patient Session</span>
                  <br />
                  <br />
                </>
              )}
            </Text>

            {transcripts?.length > 0 && (
              <>
                <Box
                  style={{
                    marginTop: '20px',
                    padding: '10px 20px 10px',
                    border: '1px solid #e0e0e0',
                    borderRadius: '5px',
                    width: '70%',
                    textAlign: 'left',
                    height: '50vh',
                    overflow: 'auto',
                  }}
                  className="soap-field"
                  ref={(el) => {
                    if (el) {
                      el.scrollTop = el.scrollHeight;
                    }
                  }}
                >
                  {transcripts?.map((transcript, index) => (
                    <Text size={14} pb={5} key={index}>
                      {transcript}
                    </Text>
                  ))}
                </Box>
              </>
            )}
          </Center>

          {audioState === recordTypes.NOTSTARTED && isZoomEnabled && (
            <>
              <div style={{ height: '13vh', border: '1px solid #D7D7D7' }}></div>

              <Center
                m="lg"
                p="xs"
                className="resource-list"
                style={{ minHeight: '55vh', flexDirection: 'column', textAlign: 'center' }}
              >
                <Tooltip
                  label="Click to start zoom meeting."
                  withArrow
                  position="right"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                    maxWidth: '200px',
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                    textAlign: 'left',
                  }}
                >
                  <Button
                    h={50}
                    w={50}
                    p="xs"
                    style={{ background: '#2D8CFF', borderRadius: '50%' }}
                    onClick={() => {
                      setHideZoomVideo(false);
                      setIsZoomModalOpen(true);
                    }}
                  >
                    <img src="../../../../img/icons/zoom-meet.svg" alt="abc" />
                  </Button>
                </Tooltip>

                <Text size={20} mt="lg">
                  <span style={{ fontWeight: '600' }}>Start Zoom Meeting</span> <br /><br />{' '}
                  {/* <span style={{ fontWeight: '400', color: '#4A4A4A', fontSize: '14px' }}> Press to Launch</span> */}
                </Text>
              </Center>
            </>
          )}

          {(isAddPatient || isPatientPopup) && (
            <PatientStepper
              isOpen={isAddPatient || isPatientPopup}
              onClose={() => onCancel()}
              onConfirm={(data) => onConfirm(data)}
              patientData={patientData}
              checkLoader={isCheckNextBtnLoader}
            />
          )}
        </div>
      ) : (
        <>
          {!hideZoomVideo && <Meeting sessionInfo={sessionInfo} />}
        </>
      )}

      <ZoomModal
        isOpen={isZoomModalOpen}
        onClose={() => setIsZoomModalOpen(false)}
        setIsZoomSession={setIsZoomSession}
        sessionInfo={sessionInfo}
        setSessionInfo={setSessionInfo}
      />
    </>
  );
};

export default Session;
