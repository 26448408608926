import React from 'react';
import { Center } from '@mantine/core';
import { IconClipboardText, IconEdit, IconIceCream, IconShieldLock, IconUserFilled } from '@tabler/icons-react';

interface StepperProps {
    totalSteps: number;
    activeStep: number;
    setActiveStep: (step: number) => void;
}

const stepperStyles = {
    lines: [
        {
            border: '1px solid #dcefaf',
            width: '70%',
            position: 'relative' as 'relative',
            bottom: '12px',
            left: '67px',
        },
        {
            border: '1px solid #dcefaf',
            width: '70%',
            position: 'relative' as 'relative',
            bottom: '12px',
            left: '46px',
        },
        {
            border: '1px solid #dcefaf',
            width: '86%',
            position: 'relative' as 'relative',
            bottom: '12px',
            left: '40px',
        },
        {
            border: '1px solid #dcefaf',
            width: '84%',
            position: 'relative' as 'relative',
            bottom: '12px',
            left: '43px',
        },
    ] as React.CSSProperties[],
    wrapper: {
        display: 'flex' as React.CSSProperties['display'],
        flexDirection: 'column' as React.CSSProperties['flexDirection'],
        alignItems: 'center' as React.CSSProperties['alignItems'],
    },
    steps: {
        display: 'flex' as React.CSSProperties['display'],
        alignItems: 'center' as React.CSSProperties['alignItems'],
        height: '70px',
    },
    stepContainer: {
        display: 'flex' as React.CSSProperties['display'],
        flexDirection: 'column' as React.CSSProperties['flexDirection'],
        alignItems: 'center' as React.CSSProperties['alignItems'],
        margin: '0 5px',
    },

    stepLabel: (isActive: boolean) => ({
        marginBottom: '5px',
        color: isActive ? '#9dc83b' : '#e0e0e0',
        fontWeight: isActive ? 'bold' : 'normal',
        fontSize: '10px',
        textAlign: 'center' as React.CSSProperties['textAlign'],
    }),
    stepDot: (isActive: boolean) => ({
        backgroundColor: isActive ? '#9dc83b' : '#dcefaf',
        borderRadius: '50%',
        width: '13px',
        height: '13px',
        cursor: 'default',
        margin: '5px',
    }),
    statusText: {
        marginTop: '10px',
        fontWeight: 'bold',
    },
    dash: {
        marginBottom: '28px',
        color: 'rgb(224, 224, 224)',
    },
};

const stepLabels = [
    <><IconShieldLock size={12} /> HIPAA Security Verification</>,
    <><IconEdit size={12} /> Payment Details</>,
    <><IconUserFilled size={12} /> About Me</>,
    <><IconClipboardText size={12} /> My Practice</>,
    <><IconIceCream size={12} /> Get Started</>
  ];
export const Stepper: React.FC<StepperProps> = ({ totalSteps, activeStep, setActiveStep }) => {
    return (
        <Center style={stepperStyles.wrapper}>
            <div style={stepperStyles.steps}>
                {stepLabels.map((label, index) => (
                    <React.Fragment key={index}>
                        <div style={stepperStyles.stepContainer}>
                            <div style={stepperStyles.stepLabel(activeStep === index)}>
                                {label}
                            </div>
                            <div
                                style={stepperStyles.stepDot(activeStep === index)}
                            />
                            {index < stepLabels.length - 1 && (
                                <span style={stepperStyles.lines[index]}></span>
                            )}
                        </div>
                        {index < stepLabels.length - 1 && (
                            <span style={stepperStyles.dash}>/</span>
                        )}
                    </React.Fragment>
                ))}
            </div>
            <div style={stepperStyles.statusText}>
                {/* {activeStep + 1 + ' of ' + totalSteps} */}
            </div>
        </Center>
    );
};
