import { Paper, Grid, Text, Button, Table, Group, Tabs, Select, Box, Tooltip, Loader, TextInput } from '@mantine/core';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip as RechartsTooltip, Legend, Bar } from 'recharts';
import { IconSearch, IconInfoCircle, IconX } from '@tabler/icons-react';
import React, { useCallback, useEffect, useState } from 'react';
import { getSubscriptionMetrics, getTotalRevenueMetrics } from '../utils/util';
import { useMedplum } from '@medplum/react';
import Overview from './Overview';

interface PaymentStatusData {
    customer: string;
    email: string;
    subscriptionDateTime: string;
    subscriptionStatus: string;
    amount: number;
    canceledDate: string;
    paymentMode: string;
    plan: string;
    id: string;
}

interface RevenueData {
    month: string;
    revenue: number;
    refund: number;
}

interface SubscriptionDurationTabsProps {
    duration: string;
    handleTabChange: (value: string | null) => void;
}

const paymentStatusTableFields = ['name', 'email', 'dateTime', 'status', 'canceledDate', 'amount', 'paymentMode', 'plan'];

const statusDisplayMap: { [key: string]: string } = {
    trialing: 'Trialing',
    active: 'Active',
    past_due: 'Past Due',
    canceled: 'Canceled',
    unpaid: 'Unpaid',
};

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

//Render the value for the Subscription Status  table
const renderValue = (data: PaymentStatusData, field: string) => {
    switch (field) {
        case 'name':
            return data?.customer || '-';
        case 'email':
            return data?.email || '-';
        case 'dateTime':
            return data?.subscriptionDateTime ? data.subscriptionDateTime : '-';
        case 'status':
            return data?.subscriptionStatus ? statusDisplayMap[data.subscriptionStatus] || capitalizeFirstLetter(data.subscriptionStatus) : '-';
        case 'canceledDate':
            return data?.canceledDate ? (isNaN(new Date(data.canceledDate).getTime()) ? '-' : new Date(data.canceledDate).toLocaleDateString()) : '-';
        case 'amount':
            return data?.amount ? `$${data.amount}` : '0';
        case 'plan':
            return data?.plan || '-';
        case 'paymentMode':
            return data?.paymentMode ? capitalizeFirstLetter(data.paymentMode) : '-';
        default:
            return '-';
    }
};

//Subscription Status Table Headers
const TableHeaders = ({ paymentStatus }: { paymentStatus: string | null }) => (
    <thead>
        <tr>
            {paymentStatusTableFields.map((field, index) => (
                (field !== 'canceledDate' || paymentStatus === 'canceled') && (
                    (field !== 'amount' || paymentStatus !== 'canceled') && (
                        <th key={index}
                            style={{
                                paddingLeft: '8px',
                                ...((field === 'status') ? { width: '120px' } : {}),
                                ...((field === 'email') ? { width: '210px' } : {}),
                                ...((field === 'plan' || field === 'name') ? { width: '200px' } : {}),
                                ...((field === 'dateTime') ? { width: '225px', paddingRight: '0' } : {}),
                                ...((field === 'canceledDate') ? { width: '200px', paddingRight: '0' } : {}),
                                ...((field === 'paymentMode') ? { width: '170px' } : {}),
                            }}
                        >
                            <Group position="apart" noWrap>
                                <Text weight={500} size="sm">
                                    {field === 'name' ? 'Name' :
                                        field === 'email' ? 'Email' :
                                            field === 'dateTime' ? 'Subscription Date' :
                                                field === 'canceledDate' ? 'Canceled Date' :
                                                    field === 'status' ? 'Status' :
                                                        field === 'amount' ? 'Amount' :
                                                            field === 'paymentMode' ? 'Payment Mode' :
                                                                field === 'plan' ? 'Plan' :
                                                                    ''}
                                </Text>
                            </Group>
                        </th>
                    ))
            ))}
            <th></th>
        </tr>
    </thead>
);

const TableRows = ({ data, paymentStatus }: { data: PaymentStatusData[], paymentStatus: string | null }) => (
    <tbody className='payment-status-table-body'>
        {data.length > 0 ? data.map((resource, index) => (
            <tr key={index} style={{ border: '1px solid #ebeae9' }}>
                {paymentStatusTableFields.map((field, i) => (
                    (field !== 'canceledDate' || paymentStatus === 'canceled') && (
                        (field !== 'amount' || paymentStatus !== 'canceled') && (
                            <td key={`cell-${index}-${i}`}>
                                {renderValue(resource, field)}
                            </td>
                        ))
                ))}
            </tr>
        )) : <tr><td className='no-data-div' colSpan={paymentStatusTableFields.length + 1}>No data available</td></tr>}
    </tbody>
);

function DashBoard() {
    const medplum = useMedplum();
    const [paymentStatusData, setPaymentStatusData] = useState<PaymentStatusData[]>([]);
    const [duration, setDuration] = useState<string>('3months');
    const [revenueDuration, setRevenueDuration] = useState<string>('3months');
    const [revenueData, setRevenueData] = useState<RevenueData[]>([]);
    const [paymentStatus, setPaymentStatus] = useState<string>('active');
    const [hasMoreNext, setHasMoreNext] = useState<boolean>(true);
    const [hasMorePrevious, setHasMorePrevious] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loadingNext, setLoadingNext] = useState<boolean>(false);
    const [loadingPrev, setLoadingPrev] = useState<boolean>(false);
    const rowsPerPage = 10;
    const [revenueChartLoader, setRevenueChartLoader] = useState<boolean>(true);
    const [subscriptionTableLoader, setSubscriptionTableLoader] = useState<boolean>(true);
    const [totalRevenue, setTotalRevenue] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const fetchPaymentStatusData = useCallback(async (page: number, itemsPerPage: number, startingAfter?: string, endingBefore?: string, searchTerm?: string) => {
        setSubscriptionTableLoader(true);
        try {
            const data = await getSubscriptionMetrics(medplum, duration, paymentStatus, itemsPerPage.toString(), startingAfter, endingBefore, searchTerm);
            setPaymentStatusData(data.subscriptions || []);
            page === 1 ? setHasMorePrevious(false) : setHasMorePrevious(true);
            endingBefore ? setHasMoreNext(true) : setHasMoreNext(data?.hasMore || false);
        } catch (error) {
            console.error('Error fetching payment status data:', error);
            setPaymentStatusData([]);
            setHasMoreNext(false);
            setHasMorePrevious(false);
        } finally {
            setLoadingNext(false);
            setLoadingPrev(false);
            setSubscriptionTableLoader(false);
        }
    }, [medplum, duration, paymentStatus]);

    //search practitioner logic in Subscription Status table
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        if (event.target.value === '') {
            fetchPaymentStatusData(1, rowsPerPage);
        }
    };

    const handleSearch = () => {
        if (!isSearching) {
            setIsSearching(true);
            fetchPaymentStatusData(1, rowsPerPage, undefined, undefined, searchTerm).finally(() => {
                setIsSearching(false);
            });
        }
    };

    const clearSearch = () => {
        setSearchTerm('');
        fetchPaymentStatusData(1, rowsPerPage);
    };
    //search practitioner logic end here 

    const fetchTotalRevenueMetrics = useCallback(async () => {
        setRevenueChartLoader(true);
        try {
            const response = await getTotalRevenueMetrics(medplum, revenueDuration);
            const transformedData: RevenueData[] = Object.keys(response).map(month => ({
                month,
                revenue: response[month].revenue,
                refund: response[month].refund
            }));
            setRevenueData(transformedData);
            // Calculate total revenue
            const total = transformedData.reduce((acc, data) => acc + data.revenue, 0);
            setTotalRevenue(total);

        } catch (error) {
            console.error('Error fetching total revenue metrics:', error);
        } finally {
            setRevenueChartLoader(false);
        }
    }, [medplum, revenueDuration]);

    useEffect(() => {
        fetchTotalRevenueMetrics();
    }, [revenueDuration]);

    useEffect(() => {
        fetchPaymentStatusData(currentPage, rowsPerPage, undefined, undefined, searchTerm);
    }, [duration, paymentStatus, fetchPaymentStatusData]);

    const handleTabChange = (value: string | null) => {
        if (value !== null) {
            setDuration(value);
            setCurrentPage(1);
        }
    };

    const handleRevenueTabChange = (value: string | null) => {
        if (value !== null) {
            setRevenueDuration(value);
        }
    };

    const handlePaymentStatusChange = (value: string | null) => {
        if (value !== null) {
            setPaymentStatus(value);
            setCurrentPage(1);
        }
    }

    const handlePageChange = (page: number, direction: 'next' | 'prev') => {
        if (direction === 'next') {
            setLoadingNext(true);
            const startingAfter = paymentStatusData[paymentStatusData.length - 1]?.id;
            fetchPaymentStatusData(page, rowsPerPage, startingAfter);
        } else {
            setLoadingPrev(true);
            const endingBefore = paymentStatusData[0]?.id;
            fetchPaymentStatusData(page, rowsPerPage, undefined, endingBefore);
        }
        setCurrentPage(page);
    };

    //Add this to render the revenue chart
    const renderRevenueChart = () => {
        if (revenueData.length === 0) {
            return <Text align="center" size={16} ml={10} pb={10}>No data available</Text>;
        }

        return (
            <BarChart width={800} height={400} data={revenueData} barSize={32}>
                <CartesianGrid opacity={0.3} stroke="#e0e0e0" vertical={false} />
                <YAxis
                    tickFormatter={(value) => `${value}`}
                    label={{ value: 'Amount ($)', angle: -90, position: 'insideLeft', textAnchor: 'middle', dx: -5, dy: -10 }}
                />
                <XAxis dataKey="month" />
                <RechartsTooltip
                    contentStyle={{ backgroundColor: 'black', color: 'white', borderRadius: '8px' }}
                    itemStyle={{ color: 'white' }}
                    cursor={{ fill: 'transparent' }}
                    formatter={(value: number, name: string) => {
                        const formattedValue = value === 0 ? '0' : `$${value.toFixed(2)}`;
                        const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
                        return [formattedValue, capitalizedName];
                    }}
                    labelFormatter={(label) => {
                        return revenueDuration === 'month' ? `${label}` : '';
                    }}
                />
                <Legend formatter={(value: string) => value.charAt(0).toUpperCase() + value.slice(1)} />
                <Bar dataKey="revenue" stackId="a" fill="#3276C9" />
                <Bar dataKey="refund" stackId="a" fill="#fba610" />
            </BarChart>
        );
    };

    //Add this to render the revenue tabs
    const renderRevenueTabs = () => (
        <Tabs value={revenueDuration} onTabChange={handleRevenueTabChange} className='filter-duration'>
            <Tabs.List className="tabs-list">
                <Tabs.Tab value="1day" mb={0} className="tab" disabled={revenueChartLoader}>Day</Tabs.Tab>
                <Tabs.Tab value="week" mb={0} className="tab" disabled={revenueChartLoader}>Week</Tabs.Tab>
                <Tabs.Tab value="month" mb={0} className="tab" disabled={revenueChartLoader}>Month</Tabs.Tab>
                <Tabs.Tab value="3months" mb={0} className="tab" disabled={revenueChartLoader}>Quarter</Tabs.Tab>
                <Tabs.Tab value="6months" mb={0} className="tab" disabled={revenueChartLoader}>Half Year</Tabs.Tab>
            </Tabs.List>
        </Tabs>
    );

    const SubscriptionDurationTabs: React.FC<SubscriptionDurationTabsProps> = ({ duration, handleTabChange }) => (
        <Grid.Col span={12} pr={3} className='sub-tab-div'>
            <Tabs value={duration} onTabChange={handleTabChange} className='filter-duration'>
                <Tabs.List className="tabs-list">
                    <Tabs.Tab value="1day" mb={0} className="tab" disabled={subscriptionTableLoader}>Day</Tabs.Tab>
                    <Tabs.Tab value="week" mb={0} className="tab" disabled={subscriptionTableLoader}>Week</Tabs.Tab>
                    <Tabs.Tab value="month" mb={0} className="tab" disabled={subscriptionTableLoader}>Month</Tabs.Tab>
                    <Tabs.Tab value="3months" mb={0} className="tab" disabled={subscriptionTableLoader}>Quarter</Tabs.Tab>
                    <Tabs.Tab value="6months" mb={0} className="tab" disabled={subscriptionTableLoader}>Half Year</Tabs.Tab>
                </Tabs.List>
            </Tabs>
        </Grid.Col>
    );

    return (
        <Box>
            {/* Overview section */}
            <Overview />

            {/* Revenue Chart */}
            <Paper m="lg" p="xs" sx={{ border: '1px solid #EAECF0' }}>
                <Grid sx={{ justifyContent: 'space-between', margin: '8px' }}>
                    <Grid.Col span={6}>
                        <Text align="left" size={18} fw={600} ml={10} pb={10}>Revenue </Text>
                    </Grid.Col>
                    <Grid.Col span={6} sx={{ textAlign: 'end' }}>
                        <Button className='download-btn'>
                            <img src="../../../../img/dashboard/download.svg" alt="Download" />
                            <Text pl={5} size={14} color='#344054' fw={600}>Download Report</Text>
                        </Button>
                    </Grid.Col>
                </Grid>
                <Grid sx={{ justifyContent: 'space-between', borderTop: '1px solid #D0D5DD' }}>
                    <Grid.Col span={4} fw={500} className='revenue-total'>Total Revenue : <span>${totalRevenue.toFixed(2)}</span>
                    </Grid.Col>
                    <Grid.Col span={8} className='filter-div'>
                        <Grid align="center" ml={20}>
                            <Grid.Col span={12} pr={22}>
                                {renderRevenueTabs()}
                            </Grid.Col>
                        </Grid>
                    </Grid.Col>
                    <Grid.Col span={12} className='revenue-chart-div'>
                        {revenueChartLoader ? <div className='loader-div'><Loader size={34} /></div> : renderRevenueChart()}
                    </Grid.Col>
                </Grid>
            </Paper>

            {/* Subscription Status table*/}
            <Paper m="lg" p="xs" sx={{ border: '1px solid #EAECF0' }}>
                <Grid sx={{ justifyContent: 'space-between', margin: '8px' }}>
                    <Grid.Col span={6}>
                        <Text align="left" size={18} fw={600} ml={10} pb={10}>Subscription Status</Text>
                    </Grid.Col>
                    <Grid.Col span={6} className='end-align'>
                        <Button className='download-btn'>
                            <img src="../../../../img/dashboard/download.svg" alt="Download" />
                            <Text pl={5} size={14} color='#344054' fw={600}>Download Report</Text>
                        </Button>
                    </Grid.Col>
                </Grid>
                <Grid className='sub-options-div'>
                    <Grid.Col span={3} className='end-align'>
                        <Group className='sub-search-icon'>
                            <TextInput
                                placeholder="Search Practitioner"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                style={{ width: '14.60rem' }}
                                disabled={isSearching || subscriptionTableLoader}
                                rightSection={
                                    <Group spacing={0}>
                                        {searchTerm && (
                                            <IconX
                                                stroke={2}
                                                size={16}
                                                onClick={clearSearch}
                                                style={{ cursor: 'pointer', marginRight: '8px' }}
                                            />
                                        )}
                                        <Button onClick={handleSearch} disabled={isSearching} className='sub-search-btn' compact>
                                            {isSearching ? <IconSearch stroke={2} size={16} color='#adb5bd' /> : <IconSearch stroke={2} color='#adb5bd' size={16} />}
                                        </Button>
                                    </Group>
                                }
                                styles={() => ({
                                    input: {
                                        borderColor: '#ced4da',
                                        '&:focus': {
                                            borderColor: '#228be6',
                                        },
                                    },
                                })}
                            />
                        </Group>
                    </Grid.Col>
                    <Grid.Col span={3} className='filter-div' p={0}>
                        <Grid.Col span={12} sx={{ display: 'Flex', paddingTop: '10px' }}>
                            <Text size={14} fw={500} color='#344054' p={6}>Status</Text>
                            <Select
                                value={paymentStatus}
                                onChange={handlePaymentStatusChange}
                                disabled={subscriptionTableLoader}
                                data={[
                                    { value: 'trialing', label: 'Trialing' },
                                    { value: 'active', label: 'Active' },
                                    { value: 'canceled', label: 'Canceled' },
                                    { value: 'past_due', label: 'Past Due' },
                                    { value: 'unpaid', label: 'Unpaid' },
                                ]}
                                placeholder="Select status"
                                sx={{ width: 'auto' }}
                            />
                            {paymentStatus === 'canceled' && (
                                <Tooltip label='Displays "Cancelled" transactions, shown according to the Subscription Date from Stripe.' position="top" withArrow>
                                    <IconInfoCircle color='gray' size={18} style={{ margin: '10px' }} />
                                </Tooltip>
                            )}
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={6} className='filter-div'>
                        <Grid align="center">
                            <SubscriptionDurationTabs
                                duration={duration}
                                handleTabChange={handleTabChange}
                            />
                        </Grid>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col lg={12} sx={{ overflow: 'auto' }}>
                        {subscriptionTableLoader ? (
                            <div className='loader-div'>
                                <Loader size={34} />
                            </div>
                        ) : (
                            <Table className='feedback_table'>
                                <TableHeaders paymentStatus={paymentStatus} />
                                <TableRows data={paymentStatusData} paymentStatus={paymentStatus} />
                            </Table>
                        )}
                    </Grid.Col>
                </Grid>
                <Grid justify="center" mt="md" mb={6}>
                    <Button
                        onClick={() => handlePageChange(Math.max(currentPage - 1, 1), 'prev')}
                        disabled={loadingPrev || loadingNext || !hasMorePrevious}
                        className='pagination-btn'
                    >
                        {loadingPrev ? (
                            <>
                                Previous...
                                <Loader size="xs" />
                            </>
                        ) : (
                            'Previous'
                        )}
                    </Button>
                    <Button
                        onClick={() => handlePageChange(currentPage + 1, 'next')}
                        disabled={!hasMoreNext || loadingNext || loadingPrev || subscriptionTableLoader}
                        className='pagination-btn'
                    >
                        {loadingNext ? (
                            <>
                                Next...
                                <Loader size="xs" />
                            </>
                        ) : (
                            'Next'
                        )}
                    </Button>
                </Grid>
            </Paper>
        </Box>
    );
}
export default DashBoard;
