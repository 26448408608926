/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Button, Center, Loader, Modal, Title } from '@mantine/core';
import { useMedplumContext } from '@medplum/react-hooks';
import { getPractitionerRole, getStripeRenewSession } from './utils/util';
import { toast } from 'react-toastify';

interface SubscriptionExpiredProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  buttonLabel: string;
  practitionerId: string;
}


const SubscriptionExpiredModel: React.FC<SubscriptionExpiredProps> = ({ isOpen, onClose, title, buttonLabel, practitionerId }) => {
  const [isCheckLoader, setIsCheckLoader] = React.useState<boolean>(false);
  const [isLogoutLoader, setIsLogoutLoader] = React.useState<boolean>(false);
  const context = useMedplumContext();
  const { medplum } = context;

  const handleNavigate = () => {
    setIsLogoutLoader(false);
    setIsCheckLoader(true);
    startStripeSession();
  }

  const handleLogout = async () => {
    setIsLogoutLoader(true);
    setIsCheckLoader(false);
    await medplum.signOut();
    window.location.href = '/signin';
  };

  const startStripeSession = async () => {
    await getPractitionerRole(medplum, practitionerId)
      .then(async (response: any) => {
        const role = response?.entry?.[0]?.resource?.code?.[0]?.coding?.[0]?.display || '';
        await openStripeSession(role);
      }).catch(async (err) => {
        await openStripeSession('');
      });
  };

  const openStripeSession = async (role: string) => {
    const payload = {
      role: role,
      practitionerId: practitionerId
    }
    getStripeRenewSession(medplum, payload).then((res) => {
      console.log(res)
      window.location.href = res.sessionUrl;
    }).catch((err) => {
      setIsCheckLoader(false);
      toast.error('Failed to proceed with payment. Please try again.');
    });
  };

  return (
    <Modal opened={isOpen} onClose={onClose} withCloseButton={false}>
      <Center style={{ textAlign: 'center' }}>
        <Title style={{ fontSize: '20px', lineHeight: '40px' }} fw={500} mb={20}>
          {title}
        </Title>
      </Center>
      <Center mt="lg">
        <Button className="btn-success" mr="lg" w={150} px={'xl'} style={{ borderRadius: '25px' }}
          onClick={handleNavigate} disabled={isCheckLoader}
        >
          {buttonLabel} {isCheckLoader && <Loader color="white" size={30} style={{ paddingLeft: '10px' }} />}
        </Button>
        <Button className="btn-danger" mr="lg" w={150} px={'xl'} style={{ borderRadius: '25px' }}
          onClick={handleLogout} disabled={isLogoutLoader}
        >
          No, Thank you {isLogoutLoader && <Loader color="white" size={30} style={{ paddingLeft: '10px' }} />}
        </Button>
      </Center>
    </Modal>
  );
};

export default SubscriptionExpiredModel;
