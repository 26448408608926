export const recordTypes = {
  STARTED: 'started',
  NOTSTARTED: 'not_started',
  PAUSED: 'paused',
  STOPPED: 'stopped',
  FINISHED: 'finished',
};

export const visitTypes = {
  medicationManagement: "Medication Management",
  initialPsychiatricEvaluation: "Initial Evaluation",
  psychotherapyOnly: "Psychotherapy",
}

const templateUrl = 'https://pmhscribe.com/intg/coding/extensions/template-type';

export const defaultTemplateStatuses = [
  { display: 'Active', code: 'final' },
  { display: 'Inactive', code: 'preliminary' },
];

export const defaultTemplateTypes = [
  { display: 'Visit Type', code: 'visit-type', system: templateUrl, },
  { display: 'Additonal Note', code: 'additional-note', system: templateUrl, },
  { display: 'Custom Note', code: 'custom-note', system: templateUrl, }
]

export const defaultPractitionerRoles = [
  {
    system: 'https://pmhscribe.com/intg/coding/practitioner-roles',
    code: 'prescriber',
    display: 'Prescriber',
  },
  {
    system: 'https://pmhscribe.com/intg/coding/practitioner-roles',
    code: 'therapist',
    display: 'Therapist',
  },
  {
    system: 'https://pmhscribe.com/intg/coding/practitioner-roles',
    code: 'psychological-testing',
    display: 'Psychological Testing',
  },
]

export const olddefaultTemplateTypes = [
  {
    display: 'Initial Psychiatric Eval',
    code: "initial-psychiatric-eval",
    system: templateUrl,
  },
  {
    display: 'Medication Management',
    code: 'medication-management',
    system: templateUrl,
  },
  {
    display: 'Psychotherapy Add-On',
    code: 'psychotherapy-add-on',
    system: templateUrl,
  },
  {
    display: 'Psychotherapy',
    code: 'psychotherapy-only',
    system: templateUrl,
  },
  {
    display: 'Orders',
    code: 'orders',
    system: templateUrl,
  },
  {
    display: 'Referrals',
    code: 'referrals',
    system: templateUrl,
  },
  {
    display: 'Patient Instructions',
    code: 'patient-instructions',
    system: templateUrl,
  },
  {
    display: 'Statement of Service',
    code: 'statement-of-service',
    system: templateUrl,
  },
]

export interface ICDCodeProps {
  code: string;
  label: string;
  value?: string;
}

export const defaultRaceOptions = [
  { value: '2106-3', label: 'White' },
  { value: '2054-5', label: 'Black or African American' },
  { value: '2186-5', label: 'Hispanic or Latino' },
  { value: '2028-9', label: 'Asian' },
  { value: '1002-5', label: 'American Indian or Alaska Native' },
  // { value: '21112-8', label: 'Middle Eastern or North African' },
  { value: '2131-1', label: 'Other' },
  // { value: '2148-5', label: 'Prefer not to answer' },
]

export const roleInitialNoteMap: { [key: string]: string } = {
  'Prescriber': 'Medication Management',
  'Therapist': 'Psychotherapy',
  'Psychological Testing': 'Initial Evaluation'
};

export const KnowledgeFaqaccordionItems = [
  {
    value: 'item-1',
    title: 'Is It Secure?',
    content: '<b>Absolutely.</b> Our AI scribe adheres to HIPAA and HITECH standards to ensure security and confidentiality. It does not contribute to training a main AI model and meticulously removes any personally identifiable information from transcripts to maintain patient privacy.',
  },
  {
    value: 'item-2',
    title: 'Will you sign a BAA?',
    content: 'Yes. We value mental healthcare security and will furnish a Business Associate Agreement for your HIPAA/HITECH manual and records.',
  },
  {
    value: 'item-3',
    title: 'What is Ambient Ai Scribes? Are you recording?',
    content: '<b>Note-taking applications</b> are picking up the conversation data points and putting them into a written rough transcript. <br><br>In our application, NO recordings are stored at rest. <br><br>This means all verbal inputs are de-identified, filtered, and immediately deleted after transit, ensuring no retention of sensitive data.',
  },
  {
    value: 'item-4',
    title: 'Can this work with Telehealth?',
    content: '<b>Yes,</b> We work best when you run your telehealth on a platform outside of your shared browser — like through Zoom or Doxy. However, if your EHR requires the telehealth to run on the same browser, we have a setting inside the PMHScribe dashboard to share the microphones. This feature works on Chrome, Firefox, and Edge browsers.<br><br>Currently, PMHScribe requires the conversation to be spoken out loud to create the transcriptions. Therefore, we do not yet support the use of a provider wearing headsets.',
  },
  {
    value: 'item-5',
    title: 'How is PSYCHIATRIC MENTAL HEALTH charting different than medical SOAPs?',
    content: 'Our system is <b>tailored</b> specifically for psychiatric charting, which has different requirements compared to SOAP note medical charting. Charting in psychiatry, guided by the American Psychiatric Association\'s guidelines and closely scrutinized by insurance auditors, is more nuanced compared to the standard medical SOAP notes used in general healthcare.',
  },
  {
    value: 'item-6',
    title: 'Can this be used by therapists and counselors?',
    content: '<b>Yes,</b> we also offer specialized features for psychotherapy notes, catering to the unique needs of counselors, therapists, and other mental health professionals. This focus on mental health documentation sets us apart in the field. $79/month for counselors.',
  },
  {
    value: 'item-7',
    title: 'What makes you special as a psychiatric mental health ai scribe?',
    content: 'Our AI scribe is exceptional because it was <b>designed by a psychiatric mental health nurse practitioner</b> with a 30-year experience in healthcare leadership. This background includes a robust history in managing psychiatry group practices and a track record of excellence in compliance with <b>insurance company audits</b>, thanks to our resilient documentation templates.<br><br> Our platform offers customized medication education tailored for patients with anxiety and reads empathetically while ensuring clarity on complex topics like boxed warnings and off-label uses.<br><br>Additionally, our system includes bespoke <b>practice management features</b> to streamline the administrative side of mental health care.',
  },
  {
    value: 'item-8',
    title: 'How much does it cost?',
    content: 'Psychiatrists and other Psychiatric clinicians are $99/month <br><br>Counselors, Therapists, Mental Health clinicians are $79/month',
  },
  {
    value: 'item-9',
    title: 'I am not locked into a contract?',
    content: 'No, we are month to month. We also offer a free trial when you click Get Started.',
  },
  {
    value: 'item-10',
    title: 'Is there a group practice discount?',
    content: 'Yes. Contact us for custom pricing for <b>group practices</b> over three providers and <b>enterprise</b> pricing for over fifty providers.',
  },
  {
    value: 'item-11',
    title: 'Will this AI take over my role?',
    content: 'No. AI is designed to support and enhance your role, not replace it. It allows you to focus more on your patient by reducing the time spent on administrative tasks. You might find that you require fewer administrative assistant hours, but your expertise and personal touch as a clinician remain irreplaceable.',
  },
  {
    value: 'item-12',
    title: 'I\'m a patient, will my chart notes be accurate?',
    content: 'Absolutely! Our software operates much like traditional dictation services that have been in use for decades. Your provider still reads the note to ensure its accuracy before putting it in your chart. This service ensures a swift turnaround for letters essential for your work or studies, referrals, and treatment orders. Freeing up your provider from the keyboard allows them to focus on you directly, enhancing your quality of care.<br><br><b>Upcoming Feature:</b><br><br>Our development roadmap prioritizes a patient-entered data application, empowering you to actively participate in creating your own notes. Stay tuned for updates!',
  },
  {
    value: 'item-13',
    title: 'What browser can this work on?',
    content: 'Like most software, we work best on Chrome. We also work on Firefox and Edge. While PMHScribe can run on Safari, if another application runs a microphone, Safari will not allow the sharing of the microphone as the other browsers do.',
  },
];
